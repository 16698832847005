import React from "react";
import "./Home.css";
import Header from "../../Components/Header/Header";
import Hero from "./Hero/Hero";
import Advertisement from "./Advertisement/Advertisement";
import Distubutorsworks from "./Distubutorswork/Distubutorsworks";
import Image__crousel from "./Image__Crousel/Image__Crousel";
import Exclusive from "./Exclusive/Exclusive";
import Categories from "./Categories/Catergories";
import Askyourexperts from "./Askyourexpert/Askyourexpert";
import Features from "./Feature/Feature";
import Discover from "./Discover/Discover";
import Stories from "./Stories/Stories";
import Web from "./WebStories/Web";
import Footer from "../../Components/Footer/Footer";
import Cards from "./LastestBlog/Cards";
import Whatsapp from "../../Components/Whatsapp/Whatsapp";
import Franciese from "../ProfilePages/FrancisesPage/Franciese";
import FranchieseProfile from "../FrancisesDetails/FrancisesDetailspage/FrancisesDetails";
import UserProfile from "../ProfilePages/UserDetailsPage/UserProfile";
import BanerScrol from "./BannerScrol/BanerScrol";
import FrachiseIndia from "./FrachiseIndia/FrachiseIndia";
import CareselALL from "./CarouselALL/CareselALL";

const Home = () => {
  return (
    <div>
      <BanerScrol />
      <Header />
      <Hero />
      <Distubutorsworks/>
      <Advertisement />
      <Image__crousel />
      <Exclusive />
      <Categories />
      <Askyourexperts />
      <Features />
      <Discover />
      <Stories />
      <Web />
      <Cards />
      <Footer />
      <Whatsapp />     
    </div>
  );
};

export default Home;
