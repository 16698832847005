import API from './api'
import {User} from './endpoints'
import { LoginPage } from './endpoints'

export const users = (data) => {
  return API.post(`${User}`, data);
};
export const UserVerifyOTP = (data) => {
    return API.post(`${LoginPage}/verifyOtp`, data);
  };

export const userResendOtp = (data) => {
  return API.post(`${LoginPage}/Login`, data);
};