import {React,useState,useEffect} from 'react'
import './Prouducts.css'
import Business__Mind from '../../DistributorDetailPage/BusinessMind/Business_Mind'
import { useLocation } from 'react-router-dom'
import { getSingleFranchiseDetails } from '../../../api/showfrachises';
import {Link} from 'react-router-dom';
const Prouducts = () => {
  const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    const [brand, setBrand]=useState([]);

    useEffect(()=>{
        getDetails();


    },[]);

    const getDetails = () =>{
        if(!id) return;

        getSingleFranchiseDetails(id)
        .then((res)=>{
            setBrand([res.data.result]);

        })
        .catch((err)=>{
            console.log(err);
        });
    }

  return (
    <div>
      
        <div className='ViewProuducts'>
            <h3>Our Products & Services</h3>
          
            {brand.map((data)=>(
  <div className='VSeriveceAll'>
            <div className='SeviceCards' key={data.id}>
              
                <div className='ProductImage'>
                <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45809.png&w=384&q=75' alt=''/>
                </div>
                <div className='OtherProducts'>
                <h5>HDPE Sheet</h5>
                
                 <Link to='/moredetails'> <p>View More</p> </Link>
                  </div>
              
             </div>  
             <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45810.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Nylon PP Sheet</h5>
              
                <p>View More</p>
                </div>
            
           </div>  
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45811.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Polyurethane Round Rod</h5>
              
                <p>View More</p>
                </div>
            
           </div> 
            <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45812.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Stopper Caster Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>  
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45813.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Industrial Trolley Castor Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>  
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45814.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Rubber Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>  
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45815.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Nylon Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div> 
            <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45816.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>PU Wheels</h5>
              
                <p>View More</p>
                </div>
              
           </div> 
            <Business__Mind/>
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45817.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Antistatic Castor Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45818.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Furniture Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45819.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Heavy Duty Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45820.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Hospital Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>
         
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45821.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Cast Iron Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45822.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Spring Loaded Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>
           <div className='SeviceCards'>
              
              <div className='ProductImage'>
              <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45823.png&w=384&q=75' alt=''/>
              </div>
              <div className='OtherProducts'>
              <h5>Trolley Caster Wheels</h5>
              
                <p>View More</p>
                </div>
            
           </div>

        

        </div>
     ) )}

        
        </div>

    </div>
  )
}

export default Prouducts
