import React, { useEffect, useState } from 'react';
import './Industrial.css';
import { useLocation } from 'react-router-dom';
import { getSingleBrandDetails } from '../../../api/showbrand ';

const Industrial = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");

  const [brand, setBrand] = useState([]);

  useEffect(() => {
    getDetails();
  }, [id]);

  const getDetails = () => {
    if (!id) return;

    getSingleBrandDetails(id)
      .then((res) => {
        // console.log("siva",res)
        setBrand(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className='container-fluid mb-3' >
      {brand.map((data) => (
          <div className='row' key={data.id}>
              <div className=' head_premium  col-md-12 col-lg-12 d-flex  '>
              <p>Distributors <i class="bi bi-chevron-right "></i> </p>
              <p>Industrial Supplies <i class="bi bi-chevron-right"></i></p>
              <p className='All_txt'>{data.name}</p>
            </div>
            <div className='col-12 d-block d-lg-none'>
              <div className='d-flex algin-items-center '>
                <div className='imgae_premium'>
                  <img src={data.imageUrl} alt={data.imageUrl} />
                </div>
                <div className='Brand_logo mt-3'>
                  <img src='https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Fpremium-brand-1.png&w=96&q=75' alt='' />
                </div>
              </div>
            </div>
            <div className='col-6 col-lg-2 d-none d-lg-block'>
              <div className='imgae_premium'>
              <img src={data.imageUrl} alt={data.imageUrl} />
              </div>
            </div>
            <div className='col-6 col-lg-8 '>
              <div className='Brand_logo d-none d-lg-block'>
                <img src='https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Fpremium-brand-1.png&w=96&q=75' alt='' />
              </div>
              <div className='brand_logo_head '>
                <h3>{data.companyName}</h3>
              </div>
              <div className='brand_amount d-flex align-items-center'>
                <div className='Brand_inve pt-3 px-3'>
                  <p className='text-white'>Categories</p>
                </div>
                <div className='investment_required d-flex gap-2 pt-3 px-3'>
                  <p className='text-white'>Fashion & Desingns</p>
                </div>
              </div>
            </div>

            <div className='col-12 col-lg-2'>
              <div className='all_mobile_media'>
                <div className='all_icons_detials d-flex gap-5'>
                  <div className='like_icons d-flex  md-gap-0'>
                    <i className="bi bi-hand-thumbs-up"></i>
                    <p>Like<span>(10)</span></p>
                  </div>
                  <span><i className="bi bi-share"></i></span>
                </div>
                <div className='call_numbes px-3 '>
                  <i className="bi bi-telephone"></i>
                  <span className='px-2'>{data.ownerMobileNumber}6382303224</span>
                </div>
              </div>
              <div class='response_button pt-2 mb-3'>
                <button>Send Your Response</button>
              </div>
            </div>
          </div>
      ))}
        </div>





    </div>
  );
};

export default Industrial;
