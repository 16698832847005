import React, { useRef, useState, useEffect } from "react";
import "./Francises.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../../../Utils/FileUpload";
import { toast } from "react-toastify";
import {Brand} from "../../../api/band";
import {getCategory} from "../../../api/category";
import { distributorId } from "../../../Utils/Storage";
 
 
const Franciese = () => {
  const navigate = useNavigate();
 
  const initialSateInputs = {
    distributorId: distributorId(),
    name: "",
    imageUrl: "", 
    investmentAmount: "",
    category: "",
  };
 
  const initialSateErrors = {
    name: false,
    imageUrl: false,
    investmentAmount: false,
    category: false,
  };
 
  const [inputs, setInputs] = useState(initialSateInputs);
  const [errors, setErrors] = useState(initialSateErrors);
  const [submitted, setSubmitted] = useState(false);
  const [categorydropdown, setCategorydropdown] = useState([]);
 
 
 
  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
  };
 
  const handleValidation = (data) => {
    let newErrors = { ...initialSateErrors };
    if (data.name === "") {
      newErrors.name = true;
    }
 
    if (data.category === "") {
      newErrors.category = true;
    }
 
    if (data.imageUrl === "") {
      newErrors.imageUrl = true;
    }
 
    if (data.investmentAmount === "") {
      newErrors.investmentAmount = true;
    }
 
   
 
    return newErrors;
  };
 
  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = handleValidation(inputs);
    setErrors(newErrors);
 
    const hasErrors = Object.values(newErrors).some((x) => x);
    if (!hasErrors) {
      Brand(inputs)
        .then((res) => {
          toast.success("Details Submitted Successfully");
          event.target.reset();
          setInputs(initialSateInputs);
          setErrors(initialSateErrors);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };
 
  const handleFileInputs1 = (e) => {
    const file = e?.target?.files[0];
    const folder = "category/image/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const imageUrl = res?.Location;
          setInputs({ ...inputs, imageUrl: imageUrl });
        })
        .catch((err) => {
          console.log(err);
        });

        
    }
  };
 
  const categoryDropdown = async() => {
       try{
              const response = await getCategory()
              console.log(response,"eni")
              setCategorydropdown(response.data.result)
       }catch(err){
console.log(err)
       }
  }
 
  useEffect(() => { 
    categoryDropdown();
  },[]);
 
  const imageref = useRef(null);
  return (
    <div>
      <div className="frnacisesContainer">
        <nav>
          <div className="francises">
            <p>Distributors </p>|<p>Franchises </p>|<p>Request A Callback</p>
          </div>
          <div className="getFrancises">
            <div>
              <h2>GetDistributors.com</h2>
              <ul>
                <p>
                  <li>Distributors</li>
                </p>
                <li>Franchies</li>
              </ul>
            </div>
            <div className="francisesdropdown">
              <div className="farncisesdropbutn">
                <i className="bi bi-person-fill"></i> My  Franchise
              </div>
            </div>
          </div>
 
          <div className="frnacieshm1">
            <div className="frnacieshomeInquire">
              <p>
                <Link to="/userprofile">
                  <i className="bi bi-house-door-fill"></i> My Profile
                </Link>
              </p>
              <p>
                <i className="bi bi-send-check"></i> My Inquiries
              </p>
            </div>
          </div>
        </nav>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="formGruppDetials">
          <h3>Basic Franchisor Details</h3>
          <br />
          <div className="labelcontent">
            <label>
              <h5>
                Name of the Franchise<span className="span">*</span>
              </h5>
            </label>
            {errors.name && <span className="error text-danger">Name is required</span>}
 
            <input
              type="text"
              placeholder="Name of the Franchise"
              name="name"
              value={inputs.name}
              onChange={handleInputs}
            />
          </div>
 
          <div className="labelcontent">
            <label>
              <h5>
                Image Upload<span className="span">*</span>
              </h5>
            </label>
            {errors.imageUrl && <span className="error text-danger">Image is required</span>}
 
            <div className="mb-3 font-szie-2rem ImageFile">
              <input
                type="file"
                id="formFile"
                placeholder="Image"
                name="imageUrl"
                onChange={handleFileInputs1}
                ref={imageref}
              />
         
            </div>
          </div>
 
          <div className="labelcontent categories_all ">
          <label>
            <h5>
              Category<span className="span">*</span>
            </h5>
          </label>
          {errors.category && <span className="error text-danger">Category is required</span>}
 
          <select
            name="category"
            value={inputs.category}
            onChange={handleInputs}
            className="categoriesDetials"
          >
            <option value="">Select category</option>
            {categorydropdown.map((category) => (
              <option key={category.id} value={category.id}>
                {category.categoryName}
              </option>
            ))}
          </select>
        </div>
 
          <h3>Franchisor Investment Detail</h3>
 
          <div className="labelcontent InvestmentDetials">
            <label>
              <h5>
              investmentAmount<span className="span">*</span>
              </h5>
            </label>
            {errors.investmentAmount && <span className="error text-danger">Investment Amount is required</span>}
 
            <span className="mobileInfomation">
              <input
                type="text"
                placeholder=" &#8377; investmentAmount"
                name="investmentAmount"
                value={inputs.investmentAmount}
                onChange={handleInputs}
              />
           
            </span>
          </div>
 
          <div className="updateDetials">
            <button type="submit">Update</button>
          </div>
        </div>
      </form>
    </div>
  );
};
 
export default Franciese;