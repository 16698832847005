import React, { useState, useEffect } from 'react';
import './Discover.css';
import { getBrandDetails } from '../../../api/showbrand ';

const Discover = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    getBrandDetails()
      .then((res) => {
        setBrands(res.data.result);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="discoverx">
      <div className="card_header">
        <h2>Discover Distributors' Inquiries</h2>
      </div>
      <div className="Separate">
      {brands.map((brand, index) => (
  <div key={index} className="discover_card">
    <div className='inital_all'>
      <div className="Initial">
        {brand.name ? <h2>{brand.name.charAt(0)}</h2> : <h2>N/A</h2>}
      </div>
      <div className="Topic_btn">
        <div className="Topic_detials">{brand.category}</div>
        <div className="Company_Head1">
          {brand.name ? <h3>{brand.name}</h3> : <h3>Unknown</h3>}
        </div>
      </div>
      <div className="Verifys">
        <i className="bi bi-patch-check-fill"></i>
      </div>
    </div>

    <div className="venue">
      <span><i className="bi bi-geo-alt"></i> {brand.location || 'Unknown Location'}Chennai</span>
      <div className='capacity_investment'>
        <h6>Investment capacity</h6>
        <h5>₹ {brand.investmentAmount} Lac</h5>
      </div>
      <div className='capacity_all'>
        <p>Experience</p>
        <p>{brand.experience ? `3 ${brand.experience} year` : 'N/A'}</p>
      </div>
      <p className='paradistribuor'>Want to become distributor</p>
      <div className="view-al">
        <a href="/" className="btn">
          Send Proposal<i className="bi bi-arrow-right-short"></i>
        </a>
      </div>
    </div>
  </div>
))}

      </div>
    </div>
  );
};

export default Discover;





// import React, { useState, useEffect } from 'react';
// import './Discover.css';
// import { getBrandDetails } from '../../../api/showbrand';

// const Discover = () => {
//   const [brands, setBrands] = useState([]);

//   useEffect(() => {
//     getDetails();
//   }, []);

//   const getDetails = () => {
//     getBrandDetails()
//      .then((res) => {
//         setBrands(res.data.result);
//         console.log(res);
//       })
//      .catch((err) => {
//         console.log(err);
//       });
//   };

//   return (
//     <div className="discoverx">
//       <div className="card_header">
//         <h2>Discover Distributors' Inquiries</h2>
//       </div>
//       <div className="Separate">
//         {brands.map((brand, index) => (
//           <div key={index} className="discover_card">
//             <div className="Initial">
//               <h1>{brand.name.charAt(0)}</h1>
//             </div>
//             <div className="Topic_btn">
//               <div className="Topic_details1">
//                 <span className="Topic_detials">{brand.category}</span>
//               </div>
//               {/* Modified part to display the first two letters and the rest as asterisks */}
//               <div className="Company_Head1">
//                 <h3>
//                   {brand.name.substring(0, 2)}{' '}
//                   {brand.name.slice(2).repeat(3).replace(/./g, '*')}
//                 </h3>
//               </div>
//             </div>
//             <div className="Verifys">
//               <i className="bi bi-patch-check-fill"></i>
//             </div>
//             <div className="venue">
//               <i className="bi bi-geo-alt"> {brand.location}</i>
//               <h6>
//                 Investment capacity
//                 <br />
//                 Experience
//                 <br /> <br /> <br />want to become distributor
//               </h6>
//               <h5>
//                 ₹ {brand.investment} Lac
//                 <br />
//                 {brand.experience} year
//               </h5>
//             </div>
//             <div className="view-al">
//               <a href="/" className="btn">
//                 Send Proposal<i className="bi bi-arrow-right-short"></i>
//               </a>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Discover;

