import React, { useState, useEffect } from "react";
import "./UserProfile.css";
import { getsingleProfile, updateProfile } from "../../../api/profile";
import { distributorId } from "../../../Utils/Storage";
import { isValidPhone } from "../../../Utils/Validation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { clearStorage } from "../../../Utils/Storage";
import { isAuthenticated } from "../../../Utils/Auth";

const UserProfile = () => {
  const initialState = {
    name: "",
    mobileNumber: "",
    websiteUrl: "",
    pinCode: "",
    city: "",
    address: "",
  };

  const initialSateErrors = {
    name: { required: false },
    mobileNumber: { required: false },
    websiteUrl: { required: false },
    pinCode: { required: false },
    city: { required: false },
    address: { required: false },
  };

  const [distributor, setdistributor] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(initialSateErrors);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    const id = distributorId();
    getsingleProfile(id)
      .then((res) => {
        setdistributor(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(distributor);
  };

    const handleInputs = (event) => {
    setdistributor({ ...distributor, [event.target.name]: event.target.value });
    if (submitted) {
      const newError = handleValidation({
        ...distributor,
        [event.target.name]: event.target.value,
      });
      setErrors(newError);
    }
  };

  const handleValidation = (data) => {
    let error = initialSateErrors;
    if (data.name === "") {
      error.name.required = true;
    }
    if (data.mobileNumber === "") {
      error.mobileNumber.required = true;
    }
    if (data.websiteUrl === "") {
      error.websiteUrl.required = true;
    }
    if (data.pincode === "") {
      error.pinCode.required = true;
    }
    if (data.city === "") {
      error.city.required = true;
    }
    if (data.address === "") {
      error.address.required = true;
    }
    if (!isValidPhone(data.mobileNumber)) {
      error.mobileNumber.valid = true;
    }

    return error;
  };

  const handleErros = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const prop = obj[key];
        if (prop.required === true) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(distributor);
    setErrors(newError);
    setSubmitted(true);
    if (handleErros(newError)) {
      updateProfile(distributor)
        .then((res) => {
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleLogout = () => {
    console.log("Logging out...");
    clearStorage();
    setIsLoggedIn(false);
    toast.success("You have logged out successfully.");
    navigate("/");
  };

  return (
    <div>
      <div className="ProfileContainer">
        <nav>
          <div className="allRequests">
            <p>Distributors </p>|<Link to="/frnachies"><p>Create Brand</p></Link>|<p>Request A Callback</p>
          </div>
          <div className="getDistributorss">
         <div className="all_distriburos_home">
            <span>
              
              <h2>GetDistributors.com</h2>
              <ul>
                <p>Distributors</p>
                <li>Franchies</li>
              </ul>
              </span>

            <div className="person_details">
              <div className="dropbtn">
                <i className="bi bi-person-fill"></i> My Getdisributor
             
            </div>
            <div className="Wallet_inform">
          <i class="bi bi-wallet-fill"></i><i className="bi bi-dash-lg icon_dash"></i><span>20000</span></div>
          </div>

          </div>
          </div>
          <br />
          <div className="homeInqurie">
            <div className="drop_downall">
            <p>
           <Link to ="/"><i className="bi bi-house-door-fill"></i>Home</Link> 
            </p>
            <p>My Inquiries </p>
            </div>
           
            
            <div className="dropdown-contentss">
                <Link to="/">
                  <button className="btn btn-primary" onClick={handleLogout}>
                    Logout
                  </button>
                </Link>
              </div> 
          </div>
          <br />
        </nav>
        <form onSubmit={handleSubmit}>
          <div className="joinfromGroup">
            <div className="lableCnt">
              <label>
                <h5>
                  Enter Your Names<span>*</span>
                </h5>
              </label>
              <input
                type="text"
                name="name"
                value={distributor.name}
                onChange={handleInputs}
              />
            </div>
            <div className="lableCnt">
              <label>
                <h5>Website URL</h5>
              </label>
              <input
                type="text"
                name="websiteUrl"
                placeholder="http:/www.abc.com/"
                value={distributor.websiteUrl}
                onChange={handleInputs}
              />
            </div>
            <div className="lableCnt">
              <label>
                <h5>
                  Phone<span>*</span>
                </h5>
              </label>
              <span className="phnDeitals">
                <input
                  required
                  placeholder="Phone number"
                  value={distributor.mobileNumber}
                />
                <input
                  type="text"
                  name="pinCode"
                  placeholder="Pincode"
                  pattern="[0-9]*"
                  title="Please enter a valid pincode"
                  maxLength={6}
                  value={distributor.pinCode}
                  onChange={handleInputs}
                />
              </span>

            </div>
            
            <div className="lableCnt">
              <label>
                <h5>Fax</h5>
              </label>
              <input
                type="text"
                name="fax"
                placeholder="Fax No."
                value={distributor.fax}
                onChange={handleInputs}
              />
            </div>
            <div className="lableCnt">
              <label>
                <h5>
                  City<span>*</span>
                </h5>
              </label>
              <input
                type="text"
                required
                name="city"
                placeholder="Enter your cty"
                value={distributor.city}
                onChange={handleInputs}
              />
            </div>
            <div className="lableCnt">
              <label>
                <h5>
                  Address<span>*</span>
                </h5>
              </label>
              <div className="areaAdress">
                <textarea
                  rows="3"
                  name="address"
                  required
                  placeholder="Enter your Address"
                  value={distributor.address}
                  onChange={handleInputs}
                />
              </div>
            </div>
         
            <div className="UpdateInfo">
              <button type="submit">Update</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
