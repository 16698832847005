import React from 'react'
import './Whatsaapp.css'
const Whatsapp = () => {
  return (
    <div>


         <a
        href="https://wa.me/+918778584566"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class= "fa fa-whatsapp whatsapp-icon "></i>
        <span className="chat-text">Chat with us</span>
        
      </a>
    </div>
  )
}

export default Whatsapp