import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py footer_containers">
      <div className="containerss">
        <div className="color">
          <div className="col-md-5 offset-md-1 mb- py-5 bg-blue suscribe_boxes">
            <form>
              <h5>Subscribe to our newsletter</h5>
              <p>
                For Exclusive Updates, Latest Trends, and Exciting Business
                Opportunities!
              </p>
              <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                <label htmlFor="newsletter1" className="visually-hidden">
                  Email address
                </label>
                <input
                  id="newsletter1"
                  type="text"
                  className="form-control"
                  placeholder="&#9993;  Enter You Email Address"
                />
                <button className="btn btn-primary subscribe" type="button">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="color">
          <div className="row">
            <div className="col-6 col-md-2 mb-3 quick-linkss">
              <h5>Quick Links</h5>
              <ul className="nav flex-column">
                <Link to="/about">
                  {" "}
                  <li className="nav-item mb-2">
                    <a href="/" className="nav-link p-0 text-body-secondary">
                      About Us
                    </a>
                  </li>{" "}
                </Link>
                <li className="nav-item mb-2">
               <Link to ="/contactus" >  <a href="/" className="nav-link p-0 text-body-secondary">
                    Contact us
                  </a></Link>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    Testimonials
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    Careers
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    Blog
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-6 col-md-2 mb-3 getdistributorss">
              <h5>Getdistributors</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    Make A Payment
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    TradeKhata
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    TiPay
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    Request a callback
                  </a>
                </li>
                <li className="nav-item mb-2">
                <Link to = "/membership">  <a href="/" className="nav-link p-0 text-body-secondary">
                    Membership
                  </a></Link>
                </li>
              </ul>
            </div>

            <div className="col-6 col-md-2 mb-3 lookingfors">
              <h5>Looking for</h5>
              <ul className="nav flex-column">
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    Distributor
                  </a>
                </li>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    Franchisor
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-6 col-md-2 mb-3 helpss">
              <h5>Help</h5>
              <ul className="nav flex-column ">
                <Link to="/feedback">
                  {" "}
                  <li className="nav-item mb-2">
                    <a href="/" className="nav-link p-0 text-body-secondary">
                      Feedback
                    </a>
                  </li>
                </Link>
                <li className="nav-item mb-2">
                  <a href="/" className="nav-link p-0 text-body-secondary">
                    Site map Report
                  </a>
                </li>
                <li className="nav-item mb-2">
                <Link to="/terms">  <a href="/" className="nav-link p-0 text-body-secondary">
                    Terms
                  </a></Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top last text-white">
            <p className="text-white">
              &copy;GetDistributors.com - An ideal platform for anybody who is
              looking forward to Appointing or Becoming a Distributor,
              Franchisee and Sales Agent. GetDistributors.com is an initiative
              by tradeindia.com © 2023 Infocom Network Private Limited. All
              rights reserved.
            </p>
          </div>
          <ul className="list-unstyled d-flex  logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-brand-facebook"
              className="bi"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-brand-instagram"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
              <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
              <path d="M16.5 7.5l0 .01" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-brand-twitter"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c0 -.249 1.51 -2.772 1.818 -4.013z" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-brand-linkedin"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
              <path d="M8 11l0 5" />
              <path d="M8 8l0 .01" />
              <path d="M12 16l0 -5" />
              <path d="M16 16v-3a2 2 0 0 0 -4 0" />
            </svg>

            <li className="ms-3">
              <a className="link-body-emphasis" href="/">
                <svg className="bi" width="24" height="24">
                  <use xlinkHref="#twitter" />
                </svg>
              </a>
            </li>
            <li className="ms-3">
              <a className="link-body-emphasis" href="/">
                <svg className="bi text-white" width="24" height="24">
                  <use xlinkHref="#instagram" />
                </svg>
              </a>
            </li>
            <li className="ms-3">
              <a className="link-body-emphasis" href="/">
                <svg className="bi" width="24" height="24">
                  <use xlinkHref="#facebook" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
