import React from 'react'
import FoodBeverage from './FoodBeverage/FoodBeverage'
import FoodBeverageCards from './FoodBeverageCards/FoodBeverageCards'
import Inquiry from './Inquiry/Inquiry'
import Looking from './Looking/Looking'
import Header1 from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import Whatsapp from '../../Components/Whatsapp/Whatsapp'

export default function 
() {
  return (
    <div>
        <Header1/>
        <FoodBeverage/>
        <FoodBeverageCards/>
        <Inquiry/>
        <Looking/>
        <Inquiry/>
        <Footer/>
        <Whatsapp/>
    </div>
  )
}
