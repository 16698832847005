import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import CategoriesDetailPage from "./Pages/CategoriesDetailPage/CategoriesDetailPage";
import DistributorDetailPage from "./Pages/DistributorDetailPage/DistributorDetailPage";
import MoreDetails from "./Pages/MoreDetailsPage/MoreDetails";
import About from "./Pages/AboutUs/About";
import Feedback from "./Pages/Feedback/Feedback";
import Login from "./Components/Login/Login";
import Signup from "./Components/Signup/Signup";
import UserProfile from "./Pages/ProfilePages/UserDetailsPage/UserProfile";
import Franciese from "./Pages/ProfilePages/FrancisesPage/Franciese";
import Contact from "./Pages/ContactUs/Contact";
import Exclusive from "./Pages/Home/Exclusive/Exclusive";
import FranchieseProfile from "./Pages/FrancisesDetails/FrancisesDetailspage/FrancisesDetails";
import MemberShip from "./Pages/MemberShip/MemberShip";
import Terms from "./Pages/Terms&Contation/Terms";
import RegistrationForm from "./Pages/Registrationform/RegistartionForm";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/api" element={<div>Okay</div>} />
          <Route path="/" element={<Home />} />
          <Route path="/categoryDetails" element={<CategoriesDetailPage />} />
          <Route
            path="/distributorDetails"
            element={<DistributorDetailPage />}
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/moredetails" element={<MoreDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/frnachies" element={<Franciese />} />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/exclusive" element={<Exclusive />} />
          <Route path="/franchisesdetails" element={<FranchieseProfile />} />
          <Route path="/membership" element={<MemberShip />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/RegistrationForm" element={<RegistrationForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
