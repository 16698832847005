import API from "./api"
import {categorys} from "./endpoints"

export const getCategory = (data) => {
    return API.get(`${categorys}`, data);

}

export const  getSingleCategory = (data)=>{
    return API.put(`${categorys}/filterCategory`, {params: {category:data}});
}                                                   
