import { useState } from "react";
import React from "react";
import "./Askyourexpert.css";

const Askyourexperts = () => {
  const [activeTab, setActiveTab] = useState("distributors");

  const changeTab = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <div className="container-fluid ask_container">
        <div className="row pt-5 pb-5">
          <div className="col-lg-6 text-white mt-3 ">
            <h1>Ask Our Experts</h1>
            <div className="row">
              <div className="col-lg-1 mt-5">
                <img
                  src="https://www.getdistributors.com/images/moving-truck-icon.svg"
                  alt=""
                />
              </div>
              <div className="col-lg-9 mt-5">
                <div>
                  <h5>Distributors</h5>
                  <p>
                    Willing to appoint distributors for your business? Seek
                    expert guidance and unlock limitless possibilities!
                  </p>
                  <p>
                    Want to take distributorship? Don't hesitate to reach out to
                    our industry specialists to drive your business forward.
                  </p>
                  <hr />
                </div>
               
              </div>
            </div>
            <div className="row">
              <div className="col-lg-1 text-algin-center">
                <img
                  src="https://www.getdistributors.com/images/moving-truck-icon.svg"
                  alt=""
                />
              </div>
              <div className="col-lg-9">
                <div>
                  <h5>Distributors</h5>
                  <p>
                    Willing to appoint distributors for your business? Seek
                    expert guidance and unlock limitless possibilities!
                  </p>
                  <p>
                    Want to take distributorship? Don't hesitate to reach out to
                    our industry specialists to drive your business forward.
                  </p>
                  <hr />
                </div>
               
              </div>
            </div>
          </div>
          <div className="col-md-6 ask_inner_container pb-2">
            <h3>What are you looking?</h3>
            <h6>Fast Track Your Business</h6>
            <div
              id="tab"
              className="btn-group gap-3 mt-2"
              data-toggle="buttons"
            >
              <button
                onClick={() => changeTab("distributors")}
                className={`btn rounded-pill ${
                  activeTab === "distributors" ? "active" : ""
                }`}
              >
                <i className="bi bi-check-circle"></i>
                <span>Distributors</span>
              </button>
              <button
                onClick={() => changeTab("franchise")}
                className={`btn rounded-pill ${
                  activeTab === "franchise" ? "active" : ""
                }`}
              >
                <i className="bi bi-check-circle"></i>
                <span>Franchise</span>
              </button>
            </div>
            <div className="form d-flex gap-3 mt-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  I am looking for distributor
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  I want to become a distributor
                </label>
              </div>
            </div>
            <form id="contact_form" name="contact_form " method="post">
              <div className=" row  ask_contact_form mt-3">
                <div className="col-md d-flex align-items-center allemail">
                  <label htmlFor="email_addr"></label>
                  <input
                    type="email"
                    required
                    maxLength={50}
                    className="form-control inputemail"
                    id="email_addr"
                    name="email"
                    placeholder="Email Address"
                  />
                </div>
                <div className="col-md d-flex align-items-center  allemail" >
                  <label htmlFor="phone_input"></label>
                  <input
                    type="tel"
                    required
                    maxLength={30}
                    className="form-control inputemail"
                    id="phone_input"
                    name="Phone"
                    placeholder="Mobile Number"
                  />
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="email_addr"></label>
                <input
                  type="email"
                  required
                  maxLength={100}
                  className="form-control"
                  id="email_addr"
                  name="email"
                  placeholder="Enter Product"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="message"></label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Type your requirement"
                  rows={5}
                />
              </div>
              <div className="bt_varite text-white mt-2 p-2">
                <button type="button" class="skip">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Askyourexperts;
