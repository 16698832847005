import React from 'react'
import './MoreDetails.css'
import Header1 from '../../Components/Header/Header'
import Prouducts from '../DistributorDetailPage/Product and Services/Prouducts'
import Footer from '../../Components/Footer/Footer'
const MoreDetails = () => {
  return (
    <div>
      <Header1/>
      <div className='HdpeSheets'>
        <div className='HdpeDetials'>
               <a href='/'>
                <p>Distributors  <i class="bi bi-chevron-right"></i></p></a>
                <a href='/'>
                <p>Global Wheels <i class="bi bi-chevron-right"></i> </p></a>
                <p>Hdpe Sheet  </p>
            
        </div>
       <div className='HdpeAllInfo' >
        <div className='HdpeImage'>
            <img src='https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fv1%2F45809.png&w=384&q=75'/>
            </div>
        <div className='SheetHeadingDetials'>
            <h2>HDPE Sheet</h2>
            <div className='DeitalsBox'>
                <div className='FirstrowValues'>
                <div className='shrinkvalue11'>

                   <div className='UpdateFirst'>
                    <h6>LAST UPDATED ON</h6>
                    <p>29 Jan 2022</p>
                    </div> 
                    </div>
                    <div className='shrinkvalue'>
                    <div className='UpdateFirst'>
                    <h6>PRODUCT ADDED ON</h6>
                    <p>29 Jan 2022</p>
                    </div> 
                    </div>
                    <div className='shrinkvalues12'>

                    <div className='UpdateFirst'>
                    <h6>WE ARE  </h6>
                    <p>looking for distributors</p>
                    </div> 
                    </div>
                    </div>
                 <div className='SecondrowValues'>
                 <div className='shrinkvalue112'>

                 <div className='UpdateFirst'>
                    <h6>SPACE REQUIRED</h6>
                    <p>500 sq ft</p>
                    </div> 
                    </div>
                    <div className='shrinkvalue'>

                    <div className='UpdateFirst'>
                    <h6>INVESTMENT REQUIRED</h6>
                    <p> Rs 2 Lac - 5 Lac</p>
                    </div> 
                    </div>
                    <div className='shrinkvalue1'>
                    <div className='UpdateFirst'>
                    <h6>Brand</h6>
                    <p>-</p>
                    </div> 
                    </div>
                    </div>
                    </div>  
            <div className='ApplyDistubutors'>
                <p>Apply for Distributors</p>
                </div> 
            </div>

        </div>
        <br></br>
        <h3 className='prohead'>Product Description</h3>
        <p>HDPE Sheet</p>
        </div>
       <Prouducts/>
       <Footer/>

    </div>
  
  )
}

export default MoreDetails
