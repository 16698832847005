import React from 'react'
import Wheel from './Wheels/Wheels'
import Paragraph from './Paragraph/Paragraph'
import Business from './Business Categroy/Business'
import Investment from './Investment Details/Investment'
import MoreInformation from './More Information/MoreInformation'
import Distributorship from './Distributorship/Distributorship'
import Location from './Location/Location'
import Header1 from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import Enquiry from './Enquiry/Enquiry'
import Industrial from './Industrial/Industrial'
import Business__Mind from './BusinessMind/Business_Mind'
import Whatsapp from '../../Components/Whatsapp/Whatsapp'
import Advertisement from '../Home/Advertisement/Advertisement'
import AddsAll from './AddsAll/AddsAll'


export default function 
() {
  return (
    <div>
      
      <Header1/>

      <Industrial/>
        <Wheel/>
        {/* <Advertisement/>
        <Paragraph/>
        <Business/>
        <Investment/>
        <Distributorship/>
        <Location/>
        <Business__Mind/> */}
        {/* <MoreInformation/>
        <Footer/> */}
        <Whatsapp/>
    </div>
  )
}


