import React from 'react'
import { FaBuildingUser } from "react-icons/fa6";
import "../RegistrationForm.css"
import { FaPercentage } from "react-icons/fa";



const Payments = () => {
  return (
    <div>
           <div className="personal_details">
            <div className="container">
              <div className="row ">
                <div className="bg-white border-light col-lg-8">
                  <h3 className="text-start pt-3">Payment Details</h3>
                  <hr />
                  <div className="row">
                  
                    <div className="col-lg-10 p-5 ">
                   
                   
                    <div className="form-group form_inputs">
        <label htmlFor="formEmail">
          Upload Company Logo(Max 2mb) <span className="text-danger">*</span>
        </label>
        <div className="input-group REG_BUTTON">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FaBuildingUser />
            </span>
          </div>
          <input
            type="file"
            id="formFile"
            placeholder="Image"
            name="imageUrl"
            className="form-control"
            // onChange={handleFileInputs}
            // ref={imageref}
          /> 
          {/* {errors.imageUrl && <span className="error text-danger">Image is required</span>} */}
        </div>
      </div>
      <div className="form-group form_inputs">
        <label htmlFor="formEmail">
          Video <span className="text-danger">*</span>
        </label>
        <div className="input-group REG_BUTTON">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FaBuildingUser />
            </span>
          </div>
          <input
            type="file"
            id="formFile"
            placeholder="Image"
            name="imageUrl"
            className="form-control"
            // onChange={handleFileInputs}
            // ref={imageref}
          />
          {/* {errors.imageUrl && <span className="error text-danger">Image is required</span>} */}
        </div>
      </div>

      <div className="form-group form_inputs">
        <label htmlFor="formEmail">
          Upload Company Logo(Max 2mb) <span className="text-danger">*</span>
        </label>
        <div className="input-group REG_BUTTON">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FaBuildingUser />
            </span>
          </div>
          <input
            type="file"
            id="formFile"
            placeholder="Image"
            name="imageUrl"
            className="form-control"
            // onChange={handleFileInputs}
            // ref={imageref}
          />
          {/* {errors.imageUrl && <span className="error text-danger">Image is required</span>} */}
        </div>
      </div>
      <div className="form-group form_inputs">
        <label htmlFor="formEmail">
          Upload Company Banner <span className="text-danger">*</span>
        </label>
        <div className="input-group REG_BUTTON">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FaBuildingUser />
            </span>
          </div>
          <input
            type="file"
            id="formFile"
            placeholder="Image"
            name="imageUrl"
            className="form-control"
            // onChange={handleFileInputs}
            // ref={imageref}
          />
          {/* {errors.imageUrl && <span className="error text-danger">Image is required</span>} */}
        </div>
      </div>
      <div className="form-group form_inputs">
        <label htmlFor="formEmail">
          Upload company images <span className="text-danger">*</span>
        </label>
        <div className="input-group REG_BUTTON">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FaBuildingUser />
            </span>
          </div>
          <input
            type="file"
            id="formFile"
            placeholder="Image"
            name="imageUrl"
            className="form-control"
            // onChange={handleFileInputs}
            // ref={imageref}
          />
          {/* {errors.imageUrl && <span className="error text-danger">Image is required</span>} */}
        </div>
      </div>
      <div className="form-group form_inputs">
        <label htmlFor="formEmail">
          Upload Image (2Mb) <span className="text-danger">*</span>
        </label>
        <div className="input-group REG_BUTTON">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FaBuildingUser />
            </span>
          </div>
          <input
            type="file"
            id="formFile"
            placeholder="Image"
            name="imageUrl"
            className="form-control"
            // onChange={handleFileInputs}
            // ref={imageref}
          />
          {/* {errors.imageUrl && <span className="error text-danger">Image is required</span>} */}
        </div>
      </div>
      <div className="form-group form_inputs">
        <label htmlFor="formEmail">
          Upload Image (2Mb) <span className="text-danger">*</span>
        </label>
        <div className="input-group REG_BUTTON">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <FaBuildingUser />
            </span>
          </div>
          <input
            type="file"
            id="formFile"
            placeholder="Image"
            name="imageUrl"
            className="form-control"
            // onChange={handleFileInputs}
            // ref={imageref}
          />
          {/* {errors.imageUrl && <span className="error text-danger">Image is required</span>} */}
        </div>
      </div>

      
      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          GST Number{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                            <FaPercentage />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="formOtp"
                            // value={inputs.ownerMobileNumber}
                            // name="ownerMobileNumber"
                            placeholder="GST Number"
                            required
                            // onChange={handleInputs}
                          />
             {/* {errors.ownerMobileNumber && <span className="error text-danger">Image is required</span>} */}

                        </div>
                      </div>
                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Mode Of Payment
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend"></div>
                            <select
                            className="form-select"
                            id="selectted"
                            // name="lookingForfranchisePartner"
                            // onChange={handleInputs}
                            // value={inputs.lookingForfranchisePartner}
                          >
                          {/* {errors.lookingForFranchisePartner && <span className="error text-danger">Image is required</span>} */}
                          <option value="select">select</option>
                            <option value="unit">UPI</option>

                            <option value="master">Creit Card</option>
                            <option value="multi units">Google Pay, Phone pay</option>
                          </select>
                        </div>
                      </div>
                    

              
                   
                    </div>
                      </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default Payments