export const Contactus ="contactus"
export const SignUp = "distributor"
export const  LoginPage = "login"
export const Profile = "distributor"
export const getBrandDetails= "brand"
export const showBrand="brand"
export const categorys ="category"
export const Franchises="franchise"
export const showFranchises="franchise"
export const User = 'user'



