import React from 'react'
import './Distributorship.css'

const Distributorship = () => {
  
  return (
    <div>
       <div className="container distributor_container">
    <div className="distributorship">

      {/* First Row with One Column */}
      <div className="distributorship-row">
        <div className=" distributor-column">
            <h1>Global Wheels Distributorship Level</h1>
        </div>
      </div>

      {/* Second Row with Two Columns */}
      <div className="distributor-row">
        <div className="distributo-column">
            <h6>City Level</h6>
           
        </div>
       
      </div>
    </div>
    </div>
    </div>
  )
}

export default Distributorship