import React from 'react'
import './Business__Mind.css'
const Business__Mind = () => {
  return (
    <div>
      <div className='Inform__Detail_Background'>
        <div className='Business__Heading'>
        <h3>CONTACT US</h3>
        <h1>Have a business in mind! <br></br>Drop me a line</h1>
        </div>
        <div className='Business__Contain'>
          <div className='Get_details'>
            <div className='Get_Head'>
              <p>Get In Touch</p>
            </div>
          <div className='Company__Details'>
            <div className='Company__Name'>
              <span className='Suitcase_icon'>
              <i class="bi bi-suitcase-lg"></i>
                </span>
                <span className='Cname'>
                  <span className='Cname1'>Company Name</span>
                  <p className='Product_Name'>Global Wheels</p>
                </span>
 
            </div>
          </div>
          <div className='Locatiion__Details'>
            <span className='Location__icon'>
            <i class="bi bi-geo-alt"></i>
            </span>
            <span className='Baddress'>
              <span className='Baddress1'>
                Adress
              </span>
              <p className='Adress__details'>
                Gat No. 599/1B, Behind-Bajaj Electricials,Mahaluge<br>
                </br>chakan,
                </p>
            </span>
          </div>
          <div className='Contact__deitails'>
            <span className='Call__Icon'><i class="bi bi-telephone-inbound"></i>
            </span>
            <span className='Phne__Details'>
              <h6>Phone Number</h6>
              <p> 08045812609</p>
            </span>
            </div>
           
 
 
        </div>
         
          <div className='User__Detials'>
            <form>
            <span className='All_Name'>
 
              <div clm assName='Full__Name'>
                <input type='text' required placeholder='Full Name'  maxLength={20} />
 
              </div>
              <span className='Full__Name'>
                <input type='text'required placeholder='Company Name'maxLength={30}/>
              </span>
            </span>
            <div className='Other__Detail'>
             
             
              <div className='Mobile_Detials'>
               
                <input type='number'required placeholder='Mobile No'/>
 
              </div>
              <div className='Mobile_Detials'>
              <input type='email id'required placeholder='Email Adress'/>
 
              </div>
              <div className='Mobile_Detials'>
                <input type='number'required placeholder='Pincode'/>
 
              </div>
            </div>
            <div className='Subject_Name'>
              <div className='Subj'>
                <input type='text'required placeholder='Subject'/>
 
              </div>
              <div className='Type_Message'>
                <textarea rows='6'required placeholder='Type Message'/>
 
              </div>
              <div className='Submit__btn'>
                <button type='submit'>Submit</button>
              </div>
            </div>
            </form>
             </div>
        </div>
 
 
 
 
        </div>
 
        </div>
 
     
  )
}
 
export default Business__Mind
 
 