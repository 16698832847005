import React, { useRef, useEffect, useState } from "react";
import "./RegistrationForm.css"; // Create this CSS file to style the component
import { IoPerson } from "react-icons/io5";
import { FaBuildingUser } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { GrUserManager } from "react-icons/gr";
import { RiBuilding3Fill } from "react-icons/ri";
import { FaImage } from "react-icons/fa6";
import { FaShop } from "react-icons/fa6";
import { TbMapPinCode } from "react-icons/tb";
import { MdOutlineLocationCity } from "react-icons/md";
import { MdMarkEmailRead } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { RiBuilding2Fill } from "react-icons/ri";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { distributorId } from "../../Utils/Storage";
import { Brand } from "../../api/band";
import { toast } from "react-toastify";
import { getCategory } from "../../api/category";
import { uploadFile } from "../../Utils/FileUpload";
import { useNavigate } from "react-router-dom";
import Payments from "./Paymentss/Payments";

const RegistrationForm = () => {
  const [lookingForExpansion, setLookingForExpansion] = useState(null);
  const [lookingforMarketing, setlookingforMarketing] = useState(false);
  const navigate = useNavigate();
  const handleRadioChange = (value) => {
    setLookingForExpansion(value);
  };
  const combinedOnChange = (event, value) => {
    handleInputs(event);
    handleRadioChange(value);
  };
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    "Personal",
    "Business",
    "Professional",
    "Franchiese Details",
    "Property Details",
    "Agreements",
    "Payment",
  ];

  const handleSelectChange = (e) => {
    setlookingforMarketing(e.target.value === "Yes");
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  // const navigate = useNavigate();
  const initialSateInputs = {
    distributorId: distributorId(),
    brandName: "",
    companyName: "",
    ownerName: "",
    ownerMobileNumber: "",
    ownerEmail: "",
    managerName: "",
    managerMobileNumber: "",
    managerEmail: "",
    address: "",
    country: "",
    countryId: "",
    state: "",
    stateId: "",
    city: "",
    cityId: "",
    pincode: "",
    websiteLink: "",
    lookingFor: "",
    lookingForfranchisePartner: "",
    category: "",
    subCategory: "",
    serviceOrProduct: "",
    yearCommencedOperation: "",
    yearCommencedFranchising: "",
    noOfFranchiseOutlets: "",
    noOfRetailOutlets: "",
    noOfCompanyOwnedOutlets: "",
    currentOutletsLocatedAt: "",
    materialsAvailable: "",
    describeYourBusiness: "",
    imageUrl: "",
    investmentAmount: "",
    internationalExpansion: "",
    territorialRights: "",
    performanceGuarantee: "",
    leviespayable: "",
    percentageReturn: "",
    paybackperiod: "",
    investmentRequirement: "",
    provideAidInFinancing: "",
    propertyIsRequired: "",
    floorAreaRequired: "",
    floorAreaRequiredMax: "",
    preferredLocation: "",
    outfitOfPremise: "",
    siteSelectionAssistance: "",
    operatingManuals: "",
    franchiseTraining: "",
    fieldAssistance: "",
    headOffice: "",
    itSystems: "",
    franchiseAgreement: "",
    contractDuration: "",
    termRenewable: "",
  };

  const initialSateErrors = {
    brandName: false,
    companyName: false,
    ownerName: false,
    ownerMobileNumber: false,
    ownerEmail: false,
    managerName: false,
    managerMobileNumber: false,
    managerEmail: false,
    address: false,
    country: false,
    state: false,
    city: false,
    pincode: false,
    websiteLink: false,
    lookingFor: false,
    lookingForFranchisePartner: false,
    category: false,
    subCategory: false,
    serviceOrProduct: false,
    yearCommencedOperation: false,
    yearCommencedFranchising: false,
    noOfFranchiseOutlets: false,
    noOfRetailOutlets: false,
    noOfCompanyOwnedOutlets: false,
    currentOutletsLocatedAt: false,
    materialsAvailable: false,
    describeYourBusiness: false,
    imageUrl: false,
    investmentAmount: false,
    internationalExpansion: false,
    territorialRights: false,
    performanceGuarantee: false,
    leviespayable: false,
    percentageReturn: false,
    paybackperiod: false,
    investmentRequirement: false,
    provideAidInFinancing: false,
    propertyIsRequired: false,
    floorAreaRequired: false,
    floorAreaRequiredMax: false,
    preferredLocation: false,
    outfitOfPremise: false,
    siteSelectionAssistance: false,
    operatingManuals: false,
    franchiseTraining: false,
    fieldAssistance: false,
    headOffice: false,
    itSystems: false,
    franchiseAgreement: false,
    contractDuration: false,
    termRenewable: false,
  };

  const [inputs, setInputs] = useState(initialSateInputs);
  const [errors, setErrors] = useState(initialSateErrors);
  const [submitted, setSubmitted] = useState(false);
  const [categorydropdown, setCategorydropdown] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);

  const handleInputs = (eOrName, value) => {
    // If eOrName is an event object (from onChange handler)
    if (typeof eOrName === "object" && eOrName !== null && eOrName.target) {
      const { name, value } = eOrName.target;
      // Convert "on" or "" to boolean true or false for internationalExpansion
      const finalValue =
        name === "internationalExpansion" ? value === "on" : value;
      setInputs((prevState) => ({
        ...prevState,
        [name]:
          finalValue === "true"
            ? true
            : finalValue === "false"
            ? false
            : finalValue,
      }));
      setErrors((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    } else {
      // If eOrName is name and value directly passed
      const name = eOrName;
      const finalValue =
        name === "internationalExpansion" ? value === "on" : value;
      setInputs((prevState) => ({
        ...prevState,
        [name]:
          finalValue === "true"
            ? true
            : finalValue === "false"
            ? false
            : finalValue,
      }));
      setErrors((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }
  };

  const handleChange = (name) => (event) => {
    handleInputs(name, event.target.value);
  };

  const handleCountryChange = (selectedCountry) => {
    handleInputs("countryId", selectedCountry.id);
    handleInputs("country", selectedCountry.name);
    handleInputs("stateId", "");
    handleInputs("state", "");
    handleInputs("cityId", "");
    handleInputs("city", "");
  };

  const handleStateChange = (selectedState) => {
    handleInputs("stateId", selectedState.id);
    handleInputs("state", selectedState.name);
    handleInputs("cityId", "");
    handleInputs("city", "");
  };

  const handleCityChange = (selectedCity) => {
    handleInputs("cityId", selectedCity.id);
    handleInputs("city", selectedCity.name);
  };

  const handleValidation = (data) => {
    let newerrors = { ...initialSateErrors };
    if (!data.brandName === "") {
      newerrors.name = true;
    }
    if (!data.companyName === "") {
      newerrors.companyName = true;
    }
    if (!data.ownerName === "") {
      newerrors.ownerName = true;
    }
    if (!data.ownerMobileNumber === "") {
      newerrors.ownerMobileNumber = true;
    }
    if (!data.ownerEmail === "") {
      newerrors.ownerEmail = true;
    }
    if (!data.mangerName === "") {
      newerrors.mangerName = true;
    }
    if (!data.mangerMobileNumber === "") {
      newerrors.mangerMobileNumber = true;
    }
    if (!data.managerEmail === "") {
      newerrors.managerEmail = true;
    }
    if (!data.address === "") {
      newerrors.address = true;
    }
    if (!data.country === "") {
      newerrors.country = true;
    }
    if (!data.state === "") {
      newerrors.state = true;
    }
    if (!data.city === "") {
      newerrors.city = true;
    }
    if (!data.pincode === "") {
      newerrors.pincode = true;
    }
    if (!data.websiteLink === "") {
      newerrors.websiteLink = true;
    }
    if (!data.lookingFor === "") {
      newerrors.lookingFor = true;
    }
    if (!data.lookingForfranchisePartner === "") {
      newerrors.lookingForFranchisePartner = true;
    }
    if (!data.category === "") {
      newerrors.category = true;
    }
    if (!data.subCategory === "") {
      newerrors.subCategory = true;
    }
    if (!data.serviceOrProduct === "") {
      newerrors.serviceOrProduct = true;
    }
    if (!data.yearCommencedOperation === "") {
      newerrors.yearCommencedOperation = true;
    }
    if (!data.yearCommencedFranchising === "") {
      newerrors.yearCommencedFranchising = true;
    }
    if (!data.noOfFranchiseOutlets === "") {
      newerrors.noOfFranchiseOutlets = true;
    }
    if (!data.noOfRetailOutlets === "") {
      newerrors.noOfRetailOutlets = true;
    }
    if (!data.noOfCompanyOwnedOutlets === "") {
      newerrors.noOfCompanyOwnedOutlets = true;
    }
    if (!data.currentOutletsLocatedAt === "") {
      newerrors.currentOutletsLocatedAt = true;
    }
    if (!data.materialsAvailable === "") {
      newerrors.materialsAvailable = true;
    }
    if (!data.describeYourBusiness === "") {
      newerrors.describeYourBusiness = true;
    }
    if (!data.imageUrl === "") {
      newerrors.imageUrl = true;
    }
    if (!data.investmentAmount === "") {
      newerrors.investmentAmount = true;
    }
    if (!data.internationalExpansion === "") {
      newerrors.internationalExpansion = true;
    }
    if (!data.territorialRights === "") {
      newerrors.territorialRights = true;
    }
    if (!data.performanceGuarantee === "") {
      newerrors.performanceGuarantee = true;
    }
    if (!data.leviespayable === "") {
      newerrors.leviespayable = true;
    }
    if (!data.percentageReturn === "") {
      newerrors.percentageReturn = true;
    }
    if (!data.paybackperiod === "") {
      newerrors.paybackperiod = true;
    }
    if (!data.investmentRequirement === "") {
      newerrors.investmentRequirement = true;
    }
    if (!data.provideAidInFinancing === "") {
      newerrors.provideAidInFinancing = true;
    }
    if (!data.propertyIsRequired === "") {
      newerrors.propertyIsRequired = true;
    }
    if (!data.floorAreaRequired === "") {
      newerrors.floorAreaRequired = true;
    }
    if (!data.floorAreaRequiredMax === "") {
      newerrors.floorAreaRequiredMax = true;
    }
    if (!data.preferredLocation === "") {
      newerrors.preferredLocation = true;
    }
    if (!data.outfitOfPremise === "") {
      newerrors.outfitOfPremise = true;
    }
    if (!data.siteSelectionAssistance === "") {
      newerrors.siteSelectionAssistance = true;
    }
    if (!data.operatingManuals === "") {
      newerrors.operatingManuals = true;
    }
    if (!data.franchiseTraining === "") {
      newerrors.franchiseTraining = true;
    }
    if (!data.fieldAssistance === "") {
      newerrors.fieldAssistance = true;
    }
    if (!data.headOffice === "") {
      newerrors.headOffice = true;
    }
    if (!data.itSystems === "") {
      newerrors.itSystems = true;
    }
    if (!data.contractDuration === "") {
      newerrors.contractDuration = true;
    }
    if (!data.termRenewable === "") {
      newerrors.termRenewable = true;
    }
    return newerrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = handleValidation(inputs);
    setErrors(newErrors);
    const hasErrors = Object.values(newErrors).some((x) => x);
    if (!hasErrors) {
      Brand(inputs)
        .then((res) => {
          toast.success("Sumbitted Successfully");
          event.target.reset();
          setInputs(initialSateInputs);
          setErrors(initialSateErrors);
          navigate("/");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleFileInputs = (e) => {
    const file = e?.target?.files[0];
    const folder = "category/image/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const imageUrl = res?.Location;
          setInputs({ ...inputs, imageUrl: imageUrl });
          console.log("image url", imageUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const categoryDropdown = async () => {
    try {
      const response = await getCategory();
      console.log(
        "eni========================================>",
        response.data.result.map((category) => category.categoryName)
      );
      setCategorydropdown(response.data.result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    categoryDropdown();
  }, []);

  const imageref = useRef(null);

  //
  const [inputs1, setInputs1] = useState({
    lookingForfranchisePartner: "select", // Initial state, assuming 'select' as default
    // other input states as needed
  });

  const handleInputs1 = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };
  return (
    <div className="stepper-container">
      <h2>It's Free & Easy To Register Your Company Now With Us!</h2>
      <div className="stepper">
        {steps.map((label, index) => (
          <div
            key={index}
            className={`step ${index === activeStep ? "active" : ""}`}
          >
            <div className="circle">{index + 1}</div>
            <div className="label fs-5">{label}</div>
            {index !== steps.length - 1 && <div className="line"></div>}
          </div>
        ))}
      </div>

      <div className="stepper-content">
        {/* Add contents for each step */}
        {activeStep === 0 && (
          <div className="personal_details">
            <div className="container">
              <div className="row ">
                <div className="bg-white border-light col-lg-8">
                  <h3 className="text-start pt-3">Personal Details</h3>
                  <hr />
                  <div className="row">
                    <form onSubmit={handleSubmit}>
                      <div className="col-lg-10 p-5 ">
                        <div className="form-group form_inputs">
                          <label htmlFor="formName">
                            Name <span className="text-danger">*</span>
                          </label>
                          <div className="input-group  REG_BUTTON">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fas fa-user"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              id="formName"
                              placeholder="Enter Your Name"
                              required
                              onChange={handleInputs}
                            />
                            {errors.name && (
                              <span className="error text-danger">
                                Name is required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group form_inputs">
                          <label htmlFor="formEmail">
                            Email Id (User Id){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group REG_BUTTON">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                            <input
                              type="email"
                              className="form-control"
                              id="formEmail"
                              placeholder="Enter Your User ID"
                              required
                              onChange={handleInputs}
                            />
                          </div>
                        </div>
                        <div className="form-group form_inputs">
                          <label htmlFor="formPassword">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="input-group REG_BUTTON">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fas fa-lock"></i>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              id="formPassword"
                              placeholder="Enter Your Password"
                              required
                              onChange={handleInputs}
                            />
                          </div>
                        </div>

                        <div className="form-group form_inputs">
                          <label htmlFor="formMobileNumber">
                            Mobile Number Verify{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group REG_BUTTON">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fas fa-phone"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              id="formMobileNumber"
                              placeholder="Enter Your Mobile Number"
                              required
                              onChange={handleInputs}
                            />
                            <button class="btn btn-verify">Verify</button>
                          </div>
                        </div>

                        <div className="form-group form_inputs">
                          <label htmlFor="formOtp">
                            Otp <span className="text-danger">*</span>
                          </label>
                          <div className="input-group REG_BUTTON">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fas fa-key"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              id="formOtp"
                              placeholder="Enter Your Otp"
                              required
                              onChange={handleInputs}
                            />
                          </div>
                        </div>
                      </div>
                    </form>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 1 && (
          <div className="personal_details">
            <div className="container">
              <div className="row ">
                <div className="bg-white border-light col-lg-8">
                  <h3 className="text-start pt-3">Busniess Details</h3>

                  <hr />
                  <div className="row">
                    <div className="col-lg-10 p-5 ">
                      <div className="form-group form_inputs">
                        <label htmlFor="formName">
                          Brand Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group  REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-user"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="formName"
                            name="brandName"
                            placeholder="Enter Your Name"
                            required
                            value={inputs.brandName}
                            onChange={handleInputs}
                          />
                          {errors.brandName && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group form_inputs">
                        <label htmlFor="formEmail">
                          Company Name <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <FaBuildingUser />
                            </span>
                          </div>
                          <input
                            type="email"
                            className="form-control"
                            id="formEmail"
                            placeholder="Enter Your User ID"
                            required
                            value={inputs.companyName}
                            name="companyName"
                            onChange={handleInputs}
                          />
                          {errors.companyName && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group form_inputs">
                        <label htmlFor="formEmail">
                          Image Upload <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <FaBuildingUser />
                            </span>
                          </div>
                          <input
                            type="file"
                            id="formFile"
                            placeholder="Image"
                            name="imageUrl"
                            className="form-control"
                            onChange={handleFileInputs}
                            ref={imageref}
                          />
                          {errors.imageUrl && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formPassword">
                          CEO / MD / Owner Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <FaUserTie />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="formPassword"
                            name="ownerName"
                            placeholder="Enter Your ownerName"
                            value={inputs.ownerName}
                            required
                            onChange={handleInputs}
                          />
                          {errors.ownerName && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formMobileNumber">
                          CEO / MD / Owner Email
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <MdOutlineMarkEmailUnread />
                            </span>
                          </div>
                          <input
                            type="email"
                            className="form-control"
                            id="formMobileNumber"
                            value={inputs.ownerEmail}
                            name="ownerEmail"
                            placeholder="Enter Your Mobile Number"
                            required
                            onChange={handleInputs}
                          />
                          {errors.ownerEmail && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          CEO / MD / Owner Mobile Number{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <HiOutlineDevicePhoneMobile />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="formOtp"
                            value={inputs.ownerMobileNumber}
                            name="ownerMobileNumber"
                            placeholder="Owner Mobile Number"
                            required
                            onChange={handleInputs}
                          />
                          {errors.ownerMobileNumber && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Manger Name<span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <GrUserManager />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="formOtp"
                            name="managerName"
                            placeholder="Enter Manger Name"
                            value={inputs.managerName}
                            required
                            onChange={handleInputs}
                          />
                          {errors.managerName && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                        Secondary Mobile Number
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <HiOutlineDevicePhoneMobile />
                            </span>
                          </div>
                          <input
                            type="text"
                            name="managerMobileNumber"
                            className="form-control"
                            id="formOtp"
                            placeholder="Enter Manger Number"
                            value={inputs.managerMobileNumber}
                            required
                            onChange={handleInputs}
                          />
                          {errors.managerMobileNumber && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                        secondary Email<span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <MdMarkEmailRead />
                            </span>
                          </div>
                          <input
                            type="text"
                            name="managerEmail"
                            className="form-control"
                            id="formOtp"
                            value={inputs.managerEmail}
                            placeholder="Enter Manger Email"
                            required
                            onChange={handleInputs}
                          />
                          {errors.managerEmail && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Country<span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="all_country_input">
                            <CountrySelect
                              onChange={handleCountryChange}
                              name="country"
                              className="form-control"
                              id="formOtp"
                              placeHolder="Select Country"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          State<span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="all_country_input">
                            <StateSelect
                              countryid={inputs.countryId}
                              onChange={handleStateChange}
                              className="form-control"
                              id="formOtp"
                              name="state"
                              placeholder="Select State"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          City<span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="all_country_input">
                            <CitySelect
                              countryid={inputs.countryId}
                              stateid={inputs.stateId}
                              onChange={handleCityChange}
                              className="form-control"
                              id="formOtp"
                              placeholder="Select City"
                              name="city"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form_inputs">
                          <label htmlFor="formOtp">
                            Looking for franchise Partners
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group REG_BUTTON">
                            <div className="input-group-prepend"></div>
                            <select
                              className="form-select"
                              id="selectted"
                              name="lookingForfranchisePartner"
                              onChange={handleInputs}
                              value={inputs.lookingForfranchisePartner}
                            >
                              <option value="select">select</option>
                              <option value="unit">Unit</option>
                              <option value="master/multiunits">
                                Master/Multi Units
                              </option>
                            </select>
                          </div>
                        </div>

                        {/* Conditionally render based on selected value */}
                        {inputs.lookingForfranchisePartner === "unit" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                              Investment Amount
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        {inputs.lookingForfranchisePartner === "unit" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                              Frnachise/Brand Fee
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        {inputs.lookingForfranchisePartner === "unit" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                              Royality/Commision Fee
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}

                        {inputs.lookingForfranchisePartner ===
                          "master/multiunits" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                              Investment Amount/Country Wise
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                         {inputs.lookingForfranchisePartner ===
                          "master/multiunits" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                            Unit/Brand fee /Country Wise
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                         {inputs.lookingForfranchisePartner ===
                          "master/multiunits" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                              Master/Brand fee /Country Wise
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                         {inputs.lookingForfranchisePartner ===
                          "master/multiunits" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                             Royality/Commision /Country Wise
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}


{inputs.lookingForfranchisePartner ===
                          "master/multiunits" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                              Investment Amount/ Region Wise
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                         {inputs.lookingForfranchisePartner ===
                          "master/multiunits" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                            Unit/Brand fee /Region wise
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                         {inputs.lookingForfranchisePartner ===
                          "master/multiunits" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                              Master/Brand fee /Region Wise
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                         {inputs.lookingForfranchisePartner ===
                          "master/multiunits" && (
                          <div className="form-group form_inputs mt-3">
                            <label htmlFor="formOtp">
                             Royality/Commision /Region Wise
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fas fa-key"></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="formOtp"
                                name="currentOutletsLocatedAt"
                                placeholder="Unit Input"
                                required
                                onChange={handleInputs}
                                value={inputs.currentOutletsLocatedAt}
                              />
                              {errors.currentOutletsLocatedAt && (
                                <span className="error text-danger">
                                  Image is required
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                         
                         
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Category<span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <MdOutlineLocationCity />
                            </span>
                          </div>
                          <select
                            className="form-control"
                            name="category"
                            value={inputs.category}
                            onChange={handleInputs}
                          >
                            <option value="">Select Category</option>
                            {categorydropdown.map((category) => (
                              <option key={category._id} value={category._id}>
                                {category.categoryName}
                              </option>
                            ))}
                          </select>
                          {errors.category && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Sub-Category<span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <RiBuilding3Fill />
                            </span>
                          </div>
                          <input
                            type="text"
                            name="subCategory"
                            className="form-control"
                            id="formOtp"
                            placeholder="sector"
                            value={inputs.subCategory}
                            required
                            onChange={handleInputs}
                          />
                          {errors.subCategory && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Service/Product<span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <select
                            className="form-select"
                            id="selectted"
                            name="serviceOrProduct"
                            value={inputs.serviceOrProduct}
                            onChange={handleInputs}
                          >
                            {errors.serviceOrProduct && (
                              <span className="error text-danger">
                                Image is required
                              </span>
                            )}
                            <option value="select">Select</option>
                            <option value="product">Product</option>

                            <option value="service">Service</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Year Commenced Operations
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <RiBuilding2Fill />
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            id="formOtp"
                            name="yearCommencedOperation"
                            placeholder="1990"
                            required
                            value={inputs.yearCommencedOperation}
                            onChange={handleInputs}
                          />
                          {errors.yearCommencedOperation && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Year commences Franchises
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <FaShop />
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control"
                            id="formOtp"
                            placeholder="1990"
                            required
                            value={inputs.yearCommencedFranchising}
                            name="yearCommencedFranchising"
                            onChange={handleInputs}
                          />
                          {errors.yearCommencedFranchising && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          No.Of. franchises Outlets
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-key"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="formOtp"
                            placeholder="ex:2"
                            required
                            name="noOfFranchiseOutlets"
                            value={inputs.noOfFranchiseOutlets}
                            onChange={handleInputs}
                          />
                          {errors.noOfFranchiseOutlets && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Number Retails Outlets
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-key"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            name="noOfRetailOutlets"
                            className="form-control"
                            id="formOtp"
                            placeholder="ex:3"
                            required
                            value={inputs.noOfRetailOutlets}
                            onChange={handleInputs}
                          />
                          {errors.noOfRetailOutlets && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          No.of.Company owned.Outleds
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-key"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            name="noOfCompanyOwnedOutlets"
                            className="form-control"
                            id="formOtp"
                            placeholder="ex:3"
                            required
                            onChange={handleInputs}
                            value={inputs.noOfCompanyOwnedOutlets}
                          />
                          {errors.noOfCompanyOwnedOutlets && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Current Outleds are Located At
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-key"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="formOtp"
                            name="currentOutletsLocatedAt"
                            placeholder="sector"
                            required
                            onChange={handleInputs}
                            value={inputs.currentOutletsLocatedAt}
                          />
                          {errors.currentOutletsLocatedAt && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Marketing Materials Available
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group REG_BUTTON">
                          <div className="input-group-prepend"></div>
                          <select
                            className="form-select"
                            id="selectted"
                            name="materialsAvailable"
                            onChange={(e) => {
                              handleSelectChange(e);
                              handleInputs(e);
                            }}
                            value={inputs.materialsAvailable}
                          >
                            <option value="">Select</option>

                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          {errors.materialsAvailable && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>
                      {lookingforMarketing && (
                        <div>
                          <div className="form-group form_inputs">
                            <label htmlFor="formOtp"></label>
                            <div className="input-group REG_BUTTON">
                              <div className="input-group-prepend"></div>
                              <select
                                className="form-select"
                                id="selected"
                                name="marketingMaterialType"
                                onChange={handleInputs}
                              >
                                <option value="Frnachise">Frnachises</option>

                                <option value="Partners Deals">
                                  KitBrochures
                                </option>
                                <option value="Disbutors">
                                  Company Profile
                                </option>
                                <option value="service">Others</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Address
                          <span className="text-danger">*</span>
                        </label>
                        <div className=" REG_BUTTON">
                          <textarea
                            onChange={handleInputs}
                            placeholder=""
                            name="address"
                            rows={5}
                            value={inputs.address}
                            className="form-control"
                          ></textarea>
                          {errors.address && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="form-group form_inputs">
                        <label htmlFor="formOtp">
                          Description
                          <span className="text-danger">*</span>
                        </label>
                        <div className=" REG_BUTTON">
                          <textarea
                            onChange={handleInputs}
                            placeholder=""
                            name="describeYourBusiness"
                            rows={5}
                            value={inputs.describeYourBusiness}
                            className="form-control"
                          ></textarea>
                          {errors.describeYourBusiness && (
                            <span className="error text-danger">
                              Image is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div className="professional_details">
            <div className="container">
              <div className="row">
                <div className="bg-white border-light col-lg-8">
                  <h3 className="text-start pt-3">Professional Details</h3>
                  <hr />
                  <div className="row">
                    <div className="col-lg-12 p-5">
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Are you looking for International Expansion?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <div>
                            <span className="radios_btn">
                              <input
                                type="radio"
                                name="internationalExpansion"
                                onChange={(e) => combinedOnChange(e, true)}
                                checked={inputs.internationalExpansion === true}
                              />
                              &nbsp; Yes
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="internationalExpansion"
                                onChange={(e) => combinedOnChange(e, false)}
                                checked={
                                  inputs.internationalExpansion === false
                                }
                              />
                              &nbsp; No
                            </span>
                          </div>
                          {errors.internationalExpansion && (
                            <span className="error text-danger">
                              Selection is required
                            </span>
                          )}
                        </div>
                      </div>
                      {lookingForExpansion && (
                        <div className="all_country_input">
                          <CountrySelect
                            onChange={handleCountryChange}
                            name="country"
                            className="form-control"
                            id="formOtp"
                            placeHolder="Select Country"
                          />
                          <StateSelect
                            countryid={inputs.countryId}
                            onChange={handleStateChange}
                            className="form-control"
                            id="formOtp"
                            name="state"
                            placeholder="Select State"
                          />
                          <CitySelect
                            countryid={inputs.countryId}
                            stateid={inputs.stateId}
                            onChange={handleCityChange}
                            className="form-control"
                            id="formOtp"
                            placeholder="Select City"
                            name="city"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 3 && (
          <div>
            <div className="professional_details">
              <div className="container">
                <div className="row  ">
                  <div className="bg-white border-light col-lg-8">
                    <h3 className="text-start pt-3">Franchiese Details</h3>
                    <hr />
                    <div className="row ">
                      <div className="col-lg-12 p-5 ">
                        <div className="row mb-3">
                          <div className="col-lg-7 text-lg-start">
                            <label htmlFor="formEmail">
                              Are there exclusive territorial rights to give to
                              a unit franchise
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <div className="">
                              <span className=" radios_btn ">
                                <input
                                  type="radio"
                                  name="territorialRights"
                                  value="true"
                                  onChange={handleInputs}
                                  checked={inputs.territorialRights === true}
                                />
                                {errors.describeYourBusiness && (
                                  <span className="error text-danger">
                                    Image is required
                                  </span>
                                )}
                                &nbsp; yes
                              </span>
                              <span>
                                <input
                                  type="radio"
                                  name="territorialRights"
                                  value="false"
                                  onChange={handleInputs}
                                  checked={inputs.territorialRights === false}
                                />
                                {errors.territorialRights && (
                                  <span className="error text-danger">
                                    Image is required
                                  </span>
                                )}
                                &nbsp; No
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        <div className="row mb-3">
                          <div className="col-lg-7 text-lg-start">
                            <label htmlFor="formEmail">
                              Are any performance guarantee given for the unit
                              Franchise
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <div className="">
                              <span className=" radios_btn ">
                                <input
                                  type="radio"
                                  name="performanceGuarantee"
                                  value="true"
                                  onChange={handleInputs}
                                  checked={inputs.performanceGuarantee === true}
                                />
                                &nbsp; yes
                              </span>
                              <span>
                                <input
                                  type="radio"
                                  name="performanceGuarantee"
                                  value="false"
                                  onChange={handleInputs}
                                  checked={
                                    inputs.performanceGuarantee === false
                                  }
                                />
                                &nbsp; No
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="row mb-3">
                          <div className="col-lg-7 text-lg-start">
                            <label htmlFor="formEmail">
                              Are any advesting/ marketing levies payable of the
                              frnachisors
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <div className="">
                              <span className=" radios_btn ">
                                <input
                                  type="radio"
                                  name="leviespayable"
                                  value="true"
                                  onChange={handleInputs}
                                  checked={inputs.leviespayable === true}
                                />
                                &nbsp; yes
                              </span>
                              <span>
                                <input
                                  type="radio"
                                  name="leviespayable"
                                  value="false"
                                  onChange={handleInputs}
                                  checked={inputs.leviespayable === false}
                                />
                                &nbsp; No
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="row mb-3">
                          <div className="col-lg-7 text-lg-start">
                            <label htmlFor="formEmail">
                              What is the anticipated percentage return on
                              investment
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <input
                              type="text"
                              name="percentageReturn"
                              className="form-control"
                              placeholder="Enter Percentage return investment"
                              onChange={handleInputs}
                              value={inputs.percentageReturn}
                            />
                          </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="form-group">
                        <div className="row mb-3">
                          <div className="col-lg-7 text-lg-start">
                            <label htmlFor="formEmail">
                              What is the likely payback period of capital for a
                              unit franchise?
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <select
                              className="form-select"
                              onChange={handleInputs}
                              name="paybackperiod"
                              value={inputs.paybackperiod}
                            >
                              <option value="Select">Select</option>
                              <option value="Min">Min in Months()</option>
                              <option value="Max">Max in Year()s</option>
                            </select>
                          </div>
                        </div>

                        {inputs.paybackperiod === "Min" &&(
                            <div className="row mb-3">
                            <div className="col-lg-7 text-lg-start">
                              <label htmlFor="formEmail">
                              Minium in Months
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-lg-5">
                            <input
                              type="text"
                              name="percentageReturn"
                              className="form-control"
                              placeholder="Enter in Months"
                             
                             
                            />
                          </div>
                          </div>
                        )}
                          {inputs.paybackperiod === "Min" &&(
                            <div className="row mb-3">
                            <div className="col-lg-7 text-lg-start">
                              <label htmlFor="formEmail">
                              Maximium in Months
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-lg-5">
                            <input
                              type="text"
                              name="percentageReturn"
                              className="form-control"
                              placeholder="Enter in Months"
                             
                             
                            />
                          </div>
                          </div>
                        )}

{inputs.paybackperiod === "Max" &&(
                            <div className="row mb-3">
                            <div className="col-lg-7 text-lg-start">
                              <label htmlFor="formEmail">
                              Maximium in Years
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                            <div className="col-lg-5">
                            <input
                              type="text"
                              name="percentageReturn"
                              className="form-control"
                              placeholder="Enter in Years"
                             
                             
                            />
                          </div>
                          </div>
                        )}
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="row mb-3">
                          <div className="col-lg-7 text-lg-start">
                            <label htmlFor="formEmail ">
                              Are there any other investment requirement
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <input
                              type="text"
                              name="investmentRequirement"
                              className="form-control"
                              placeholder="anyother investment requirement"
                              onChange={handleInputs}
                              value={inputs.investmentRequirement}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-lg-7 text-lg-start">
                            <label htmlFor="formEmail ">
                              investmentAmount
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <input
                              type="text"
                              name="investmentAmount"
                              className="form-control"
                              placeholder="investmentAmount"
                              onChange={handleInputs}
                              value={inputs.investmentAmount}
                            />
                          </div>
                        </div>
                        {/*  */}
                        {/*  */}
                        <div className="row mb-3">
                          <div className="col-lg-7 text-lg-start">
                            <label htmlFor="formEmail">
                              Do you Provide aid in financing
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                          <div className="col-lg-5">
                            <div className="">
                              <span className=" radios_btn ">
                                <input
                                  type="radio"
                                  name="provideAidInFinancing"
                                  value="true"
                                  onChange={handleInputs}
                                  checked={
                                    inputs.provideAidInFinancing === true
                                  }
                                />
                                &nbsp; yes
                              </span>
                              <span>
                                <input
                                  type="radio"
                                  name="provideAidInFinancing"
                                  value="false"
                                  onChange={handleInputs}
                                  checked={
                                    inputs.provideAidInFinancing === false
                                  }
                                />
                                &nbsp; No
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 4 && (
          <div className="Property_details">
            <div className="container">
              <div className="row">
                <div className="bg-white border-light col-lg-8">
                  <h3 className="text-start pt-3">Property Details</h3>
                  <hr />
                  <div className="row ">
                    <div className="col-lg-12 p-5 ">
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            What type of property is required for this
                            franchiese oppourtunity?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <select
                            className="form-select"
                            id="selectted"
                            name="propertyIsRequired"
                            onChange={handleInputs}
                            value={inputs.propertyIsRequired}
                          >
                            <option value="select">Select</option>
                            <option value="commersail">Commersial</option>
                            <option value="domestic">Domestic</option>
                          </select>
                        </div>
                      </div>
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Floor Area Required?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            name="floorAreaRequired"
                            className="form-control"
                            placeholder="Enter yes or no "
                            onChange={handleInputs}
                            value={inputs.floorAreaRequired}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
  <div className="col-lg-7 text-lg-start">
    <label htmlFor="formEmail">
      Floor Area Requirements (min/max, sq.ft)
      <span className="text-danger">*</span>
    </label>
  </div>
  <div className="col-lg-5">
    <div className="row">
      <div className="col-lg-6">
        <input
          type="text"
          name="floorAreaRequiredMin"
          className="form-control"
          placeholder="Min sq.ft"
          onChange={handleInputs}
          value={inputs.floorAreaRequiredMin}
        />
      </div>
      <div className="col-lg-6">
        <input
          type="text"
          name="floorAreaRequiredMax"
          className="form-control"
          placeholder="Max sq.ft"
          onChange={handleInputs}
          value={inputs.floorAreaRequiredMax}
        />
      </div>
    </div>
  </div>
</div>

                      {/*  */}
                      {/*  */}
                      {/*  */}
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Preffered Location
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your location"
                            name="preferredLocation"
                            onChange={handleInputs}
                            value={inputs.preferredLocation}
                          />
                        </div>
                      </div>

                    
                      {/*  */}
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Franchisor or Franchiese will arrange outfit of
                            permises
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <select
                            className="form-select"
                            id="selectted"
                            name="outfitOfPremise"
                            onChange={handleInputs}
                            value={inputs.outfitOfPremise}
                          >
                            <option value="select">select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Do you provide site selection assistance
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <select
                            className="form-select"
                            id="selectted"
                            name="siteSelectionAssistance"
                            onChange={handleInputs}
                            value={inputs.siteSelectionAssistance}
                          >
                            <option value="select">select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 5 && (
          <div className="Agreements">
            <div className="container">
              <div className="row">
                <div className="bg-white border-light col-lg-8">
                  <h3 className="text-start pt-3">Training Details</h3>
                  <hr />
                  <div className="row ">
                    <div className="col-lg-12 p-5 ">
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Do you currently have detailed operating manuals for
                            franchieses?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <div className="">
                            <span className=" radios_btn ">
                              <input
                                type="radio"
                                name="operatingManuals"
                                value="true"
                                onChange={handleInputs}
                                checked={inputs.operatingManuals === true}
                              />
                              &nbsp; yes
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="operatingManuals"
                                value="false"
                                onChange={handleInputs}
                                checked={inputs.operatingManuals === false}
                              />
                              &nbsp; No
                            </span>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Where is the franchiese training done?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <input
                            type="text"
                            name="franchiseTraining"
                            className="form-control"
                            onChange={handleInputs}
                          />
                        </div>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Is the field assistance avaible for franchises?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <div className="">
                            <span className=" radios_btn ">
                              <input
                                type="radio"
                                name="fieldAssistance"
                                value="true"
                                onChange={handleInputs}
                                checked={inputs.fieldAssistance === true}
                              />
                              &nbsp; yes
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="fieldAssistance"
                                value="false"
                                onChange={handleInputs}
                                checked={inputs.fieldAssistance === false}
                              />
                              &nbsp; No
                            </span>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Will the head office be providing assistance to the
                            franchiese?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <div className="">
                            <span className=" radios_btn ">
                              <input
                                type="radio"
                                name="headOffice"
                                value="false"
                                onChange={handleInputs}
                                checked={inputs.headOffice === true}
                              />
                              &nbsp; yes
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="headOffice"
                                value="false"
                                onChange={handleInputs}
                                checked={inputs.headOffice === false}
                              />
                              &nbsp; No
                            </span>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            What IT systems do you presently have that will be
                            included in the franchiese?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <div className="">
                            <span className=" radios_btn ">
                              <input
                                type="radio"
                                name="itSystems"
                                value="true"
                                onChange={handleInputs}
                                checked={inputs.itSystems === true}
                              />
                              &nbsp; yes
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="itSystems"
                                value="false"
                                onChange={handleInputs}
                                checked={inputs.itSystems === false}
                              />
                              &nbsp; No
                            </span>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
                <div className="bg-white border-light col-lg-8">
                  <h3 className="text-start pt-3">Agreements Details</h3>
                  <hr />
                  <div className="row ">
                    <div className="col-lg-12 p-5 ">
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Do you have standard franchiese agreement?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <div className="">
                            <span className=" radios_btn ">
                              <input
                                type="radio"
                                name="franchiseAgreement"
                                value="true"
                                onChange={handleInputs}
                                checked={inputs.franchiseAgreement === true}
                              />
                              &nbsp; yes
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="franchiseAgreement"
                                value="false"
                                onChange={handleInputs}
                                checked={inputs.franchiseAgreement === false}
                              />
                              &nbsp; No
                            </span>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Duration of the Contract
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <select
                            className="form-select"
                            onChange={handleInputs}
                            name="contractDuration"
                            value={inputs.contractDuration}
                          >
                            <option value="select">Select</option>
                            <option value="ife time">Life time</option>
                            <option value="monthe&years">monthe&years</option>
                          </select>
                        </div>
                      </div>
                      {/*  */}
                      {/*  */}
                      <div className="row mb-3">
                        <div className="col-lg-7 text-lg-start">
                          <label htmlFor="formEmail">
                            Is the term renewable?
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="col-lg-5">
                          <div className="">
                            <span className=" radios_btn ">
                              <input
                                type="radio"
                                name="termRenewable"
                                value="true"
                                onChange={handleInputs}
                                checked={inputs.termRenewable === true}
                              />
                              &nbsp; yes
                            </span>
                            <span>
                              <input
                                type="radio"
                                name="termRenewable"
                                value="false"
                                onChange={handleInputs}
                                checked={inputs.termRenewable === false}
                              />
                              &nbsp; No
                            </span>
                          </div>
                        </div>
                      </div>

                      {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="btn btn-danger bg-primary "
              onClick={handleSubmit}
              style={{ width: "10%" }}
            >
              submit
            </button>
          </div>
        )}
        {activeStep === 6 && (
          <div>
            <Payments />
          </div>
        )}
      </div>
      <br />
      <div className="container ">
        <div className="row">
          <div className="col-lg-8">
            <div className="stepper-buttons">
              <button
                type="button"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                Back
              </button>
              <button
                type="submit"
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
