import React, { useState } from 'react';
import './Login.css';
import login from '../../Asssets/login/login.jpg';
import { toast } from 'react-toastify';
import { Loginpanel, signVerifyDistributorOTP } from '../../api/login';
import { getToken, loginType, saveToken } from '../../Utils/Storage';
import { useNavigate } from "react-router-dom";
import { isValidPhone } from '../../Utils/Validation';
import { isAuthenticated } from "../../Utils/Auth";

function Login() {
  const navigate = useNavigate();

  const initialStateInputs = {
    mobileNumber: "",
    otp: "",
  };
  const initialStateErrors = {
    mobileNumber: { required: false, valid: false },
    otp: { required: false },
  };
  const [inputs, setInputs] = useState(initialStateInputs);
  const [errors, setErrors] = useState(initialStateErrors);
  const [step, setStep] = useState("mobileNumber");
  const [submitted, setSubmitted] = useState(false);

  const handleInputs = (event) => {
    const { name } = event.target;
    setInputs({ ...inputs, [event.target.name]: event.target.value });

    if (submitted) {
      const newError = handleValidation({ ...inputs, [event.target.name]: event.target.value });
      setErrors(newError);
    }
  };

  const handleValidation = (data) => {
    let error = { ...initialStateErrors };

    if (data.mobileNumber === "") {
      error.mobileNumber = { ...error.mobileNumber, required: true };
    }
    if (data.otp === "") {
      error.otp = { ...error.otp, required: true };
    }
    if (!isValidPhone(data.mobileNumber)) {
      error.mobileNumber = { ...error.mobileNumber, valid: true };
    }
    return error;
  };

  const handleContinue = () => {
    if (isValidPhone(inputs.mobileNumber)) {
      setStep('otp');
    } else {
      setStep('mobileNumber');
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);
    const allInputsValid = !newError.mobileNumber.required && !newError.mobileNumber.valid;
    if (allInputsValid) {
      const data = { mobileNumber: inputs.mobileNumber };
      Loginpanel(data)
        .then((res) => {
          const _id = res?.data?.result?._id;
          // console.log("login type",res)
          setInputs({ ...inputs, _id });
          toast.success(res?.data?.message);
          if (res?.data?.result?.sendOtp) {
            setStep("otp");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleOTPSubmit = (event) => {
    event.preventDefault();
    const newError = handleValidation(inputs);
    setErrors(newError);
    setSubmitted(true);
    const allInputsValid = !newError.otp.required;
    if (allInputsValid) {
      const data = {
        mobileNumber: inputs.mobileNumber,
        otp: inputs.otp
      };
     
      signVerifyDistributorOTP(data)
        .then((res) => {
          if (res?.data?.result?.loginType === "User") {
            let token = res?.data?.result?.userDetails?.bearer_Token;
            let userId = res?.data?.result?.userDetails?._id;
            let loginType= res?.data?.result?.loginType;
            let userData = {
              token: token,
              userId: userId,
              loginType:loginType
            };
            saveToken(userData);
            console.log("siva",res,userData)
            if (isAuthenticated()) {
              navigate('/')
            }
            toast.success(res.data.message);
          }
          else{
            if (res?.data?.success) {
              let token = res?.data?.result?.distributorDetails?.bearer_Token;
              let distributorId = res?.data?.result?.distributorDetails?._id;
              let loginType= res?.data?.result?.loginType;
              let userData = {
                token: token,
                distributorId: distributorId,
                loginType:loginType
              };
              saveToken(userData);
              console.log("siva" ,userData)
              if (isAuthenticated()) {
                navigate('/')
              }
              toast.success(res.data.message);
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
      
     
        
    }
  };

  return (
    <>
      <div className='container shadow login-main-container p-0 ps-2'>
        <div className='row'>
          <div className='col-lg-6 col-md-12 login-image'>
            <img src={login} alt="login" className='img-fluid' />
          </div>

          <div className='col-lg-6 col-md-12 login-form-container rounded-end-3'>
            <h2 className='mt-4 fw-bold fs-1 txt-color'>Start your Journey with <br />Pixalive Franchise.</h2>
            <p className='mt-4 text-black log-content'>Login in to Get The Best Deals, Exclusive<br />Offers with pixalive franchises</p>

            <form onSubmit={handleLogin}>
              <div className='form-group'>
                <label htmlFor='username' className='txt-color'>Enter Mobile Number</label>
                <input
                  type='text'
                  className='form-control'
                  id='username'
                  name='mobileNumber'
                  placeholder='Enter your number'
                  onChange={handleInputs}
                  maxLength={10}
                />
                {errors.mobileNumber.required && <span className="error-text text-danger">*This field Required*</span>}
                {errors.mobileNumber.valid && <span className="error-text text-danger">*Invalid Mobile Number*</span>}
              </div>

              <div className='form-group'>
                <button type='submit' className='btn btn-primary' onClick={handleContinue}>Continue</button>
              </div>
            </form>

            <form onSubmit={handleOTPSubmit}>
              {step === "otp" ? (
                <div>
                  <div className='form-group otp-box'>
                    <label htmlFor='otp' className='txt-color'>Enter OTP</label>
                    <input
                      type='text'
                      className='form-control'
                      id='otp'
                      name='otp'
                      value={inputs.otp}
                      onChange={handleInputs}
                      placeholder='Enter OTP'
                    />
                    {errors.otp.required && <span className="error-text text-danger">*This field Required*</span>}
                  </div>
                  <div className='form-group'>
                    <button type='submit' className='btn btn-primary'>Verify OTP</button>
                  </div>
                </div>
              ) : null}
            </form>

            <div className='col-lg-6 col-md-12'>
              <p className='txt-color'>Don't have an account? <a href='/signup'>Signup</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;