import React from "react";
import "./Inquiry.css";

const Inquiry = () => {
  return (
    <div className="Container-fluid">
      <div className="wrapper_container row ">
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FA-K-Namkeen-Udhyog-v2-100350.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>A K Namkeen Udyog</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">3.00 Lac - 5.00 Lac</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2016</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Mad Angles, Noodles, Popcorn, Pasta Namkeen
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08037303551</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FTRUSPICE-VENTURES-PRIVATE-LIMITED-v1-197547.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Truspice Ventures Private Limited</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">1.00 Lac - 2.00 Lac</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2019</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Chilli powder ,coriander Cumin powder,Garam masala, Kitchen King
                masala
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08037303551</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FIRAJ-FOOD-BEVERAGES-PVT-LTD-v2-227302.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Iraj Food And Beverages Pvt Ltd</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">1.00 Lac - 2.00 Lac</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2016</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Mad Angles, Noodles, Popcorn, Pasta Namkeen
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FREMNI-FOOD-PRODUCTS-v1-182271.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Remni Food Products</h5>
              </div>

              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2021</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Red Chilli Powder, Coriander Powder, Long Grain Rice, Turmeric
                Powder
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08037303551</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FWAPRAA-BIO-ORGANIC-INDUSTRIES-PRIVATE-LIMITED-v1-110157.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Wapraa Bio-organic Industries Priv</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">5.00 Lac - 10.00 Lac</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2019</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Agricultural Fertilizer, 100% Crop Protector, Organic N.P.K Mix,
                Sanjivni
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08037303551</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FFLABITE-SPICES-v1-207599.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Flabite Spices</h5>
              </div>

              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2021</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Grounded Spices, Healthy Chana Besan, Chakki Fresh Atta, Dry
                Fruits
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08037303551</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FVIRAT-CRANE-INDUSTRIES-LTD--v1-207846.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Virat Crane Industries Ltd.</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">2.00 Lac - 3.00 Lac</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">1995</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Durga Ghee, Kamadhenu Cow Ghee, Bhavani Ghee, Durga Cow Ghee
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FKEVALAM-v1-193470.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Smd Products & Co</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">20.00 K - 300.00 K</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2019</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Raw Spices - Jeera / Cumin, Black Pepper, Cardamom, Mustered
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08037303551</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FGpc-Natural-Food-Products-v1-166799.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Gpc Natural Food Products</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">1.00 Lac - 2.00 Lac</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2017</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Besan, Ragi Atta, Sattu, Singhara Atta
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08037303551</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FVt-Ecogreen-Technologies-Pvt-Ltd-v1-173655.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Vt Ecogreen Technologies Private Li</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">25.00 K - 50.00 K</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2018</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Green cardamom, Black pepper, White pepper, Nutmeg
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08045812462</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FJusica-Agro-Foods-Pvt-Ltd--v1-168582.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Jusica Agro Foods Pvt. Ltd.</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">2.00 Lac - 5.00 Lac</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2015</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Tetra-Jusica Mango 200 ml, Tetra- Taaza Mango Drink 200 ml,
                Tetra -Miss Mango 200 ml, PET Bottles -J
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08037626256</i>
              </div>
            </div>
          </div>
        </div>
        <div className="card_container">
          <div className="Img_container">
            <img
              src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FZillion-International-Royal-Foods-v1-209744.jpeg&w=384&q=75"
              alt="Teserract"
            />
          </div>
          <div className="detail_container">
            <div className="">
              <span className="Card1__InfoCard_Subtitle">food & Beverage</span>
              <div className="title">
                <h5>Zillion International Royal Foods</h5>
              </div>
              <div className="price_range">
                <div className="price_range_text">Investment price range</div>
                <div className="Price_amount">5.00 Lac - 15.00 Lac</div>
              </div>
              <div className="Establish">
                <div className="Info">Established</div>
                <div className="Year">2022</div>
              </div>
              <div className="Dist_details">Distributors of</div>
              <div className="Dist_products">
                Refined n non refined Sunflower Oil, Refined and non refined
                Soybean Oil, Refined and non-refined Pa
              </div>

              <div>
                <button className="contact_btn">
                  <i class="bi bi-send">Send Inquiry</i>
                </button>
              </div>
              <div className="Contact_Deitail">
                <i class="bi bi-telephone">08071859554 PIN:(498)</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="show_more">
        <button>Show More Results</button>
      </div>
    </div>
  );
};

export default Inquiry;
