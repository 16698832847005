import React from "react";
import "./About.css";
import Header1 from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const About = () => {
  return (
    <div>
      <Header1 />
      <div className="aboutus">
        <div className="about_firsr">
          <div className="about_paragraph">
            <h1>Who We Are</h1>
            <h6>Changing Business Connections with Pixalive Franchise!</h6>
            <p>
              Pixalive Franchise is a new way for businesses to connect. It was
              made by pixalivebrands.com because they saw that <br /> companies
              need to find distributors and franchisees, and distributors and
              franchisees need to find the right <br /> businesses. That's why
              they made pixalivefranchise.com .
            </p>
          </div>
          <div className="aboutimg">
            <img
              src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fabout-us-1.png&w=640&q=75"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="aboutus_box">
        <div className="aboutus_firstbox">
          <img
            src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbulb.png&w=96&q=75"
            alt=""
          />
          <h1>Our Vision</h1>
          <p>
            {" "}
            We want to make it easy for businesses to connect and work together.
            At Pixalive Franchise, we want to be the best place for companies to
            grow and make good partnerships. We want to make it cheap, easy, and
            simple for businesses to expand.
          </p>
        </div>

        <div className="aboutus_secoundbox">
          <img
            src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Frating.png&w=96&q=75"
            alt=""
          />
          <h1>Our Mission</h1>
          <p>
            {" "}
            We want to give businesses a full online solution with
            pixalivefranchise.com . We want to help both distributors and
            franchisees, and the companies that need them, by showing them the
            best partners. We want to make it simple to find the right
            distributors and franchisees so businesses can build strong
            relationships.
          </p>
        </div>
      </div>

      <div className="aboutus_thirdbox">
        <img
          src="https://www.getdistributors.com/images/about-us-two.svg"
          alt=""
        />
        <div className="aboutus_beast">
          <h1>We're the leading force</h1>
          <h3>within your vicinity</h3>
          <h6>Revolution</h6>
          <p>
            We adopt revolutionary approaches to address the changing demands of
            enterprises. By utilizing state-of-the-art innovations, we maintain
            a competitive edge. As a division of pixalivebrands.com , the
            premier B2B hub, we've established a robust standing and extensive
            proficiency, attracting a substantial audience. At Pixalive
            Franchise, our aim is to revolutionize the business landscape.
            Whether you're a corporation seeking dependable allies or an
            aspiring distributor or franchisee striving to make your mark,
            pixalivefranchise.com offers indispensable tools and support to help
            you reach your objectives. Join us now and unlock boundless
            entrepreneurial prospects!
          </p>

          <div className="aboutus_Integrity">
            <img
              src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fpuzzel.png&w=128&q=75"
              alt=""
            />

            <p>
              {" "}
              <b>Trustworthiness</b> <br />
              We uphold integrity, openness, and ethical behavior in every
              interaction. Trust forms the bedrock of our platform.
            </p>
          </div>

          <div className="aboutus_Integrity">
            <img
              src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fcircle-user.png&w=128&q=75"
              alt=""
            />
            <p>
              {" "}
              <b>Partnership</b> <br />
              We believe in the strength of partnership and urge enterprises to
              collaborate, exchange insights, and capitalize on each other's
              capabilities.
            </p>{" "}
          </div>

          <div className="aboutus_Integrity">
            <img
              src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Ftraget.png&w=128&q=75"
              alt=""
            />
            <p>
              {" "}
              <b>Client-Centric Approach</b> <br />
              We prioritize our clients' requirements and contentment. Our
              user-centric platform ensures a smooth journey.
            </p>{" "}
          </div>

          <div className="aboutus_number">
            <p>
              <h1>1,22,000+</h1>
              <h5>Distributors</h5>{" "}
            </p>

            <p>
              <h1>20,000+</h1>
              <h5>Franchisees</h5>{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
