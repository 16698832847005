import React from 'react'
import './Feedback.css';
import feedback from "../../Asssets/feedback.jpg"
import Header1 from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
function Feedback() {
    return (
        <>
            <Header1 />
            <div className='feedback-container'>
                <div className="container shadow mt-5 feedback-main-container">
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 '>
                            <div className='feedback-image'>
                                <img src={feedback} alt="feedback" className='img-fluid' />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 feedback-form">
                            <h3 className='fw-bold'>Send Us Your Feedback!</h3>
                            <p>Feel free to drop us a line and give us your feedback</p>
                            <form>
                                <div className='feedback-innerform'>
                                    <div className="form-group">
                                        <label for="name">Name</label>
                                        <input type="text" className="form-control" placeholder="Enter name" />
                                    </div>
                                    <div className="form-group">
                                        <label for="name">Company Name</label>
                                        <input type="text" className="form-control" placeholder="Enter company name" />
                                    </div>
                                </div>
                                <div className='feedback-innerform'>
                                    <div className='form-group'>
                                        <label for="phone">Phone</label>
                                        <input type="text" className="form-control" placeholder='Enter phone' />
                                    </div>
                                    <div className="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" className="form-control" placeholder="Enter email" />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label for="subject">Subject</label>
                                    <input type="text" className="form-control" placeholder='Enter subject'/>
                                </div>
                                <div className="form-group">
                                    <label for="message">Message</label>
                                    <textarea className="form-control" id="message" placeholder="Enter message" rows="5"></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
export default Feedback;