import React from "react";
import "./Stories.css";

const Stories = () => {
  return (
    <div className="container stroies_container mt-5">
      <div className="frame " id="frame">
        <div className="square">
          <h2>Success Stories</h2>
        </div>
        <div className="views">
          <div className="sub_title">
            <h3>Our clients' testimonials speak volumes of satisfaction</h3>
          </div>
          
        </div>

        <div className="move">
          <div className="stories_card_container">
            <article className="stories_cards">
              <div className="text">
                <div className="top">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Ftestimony_pics%2Fver1%2F18%2F52966.jpg&w=64&q=75"
                    alt=""
                  />
                </div>
                <div className="star">
                  <h4>Bussiness Developement Executive</h4>
                  <h5>EXOTIC PHARMA</h5>
                </div>
              </div>
              <div className="para">
                <p>
                  Thanks to GetDistributors.com,I found reliable distributors
                  who are now a vital part of my firm's success story.Their
                  platform simplified the process of bussiness expansion and
                  gave my bussiness the...
                </p>
              </div>

              <div className="miss">
                <div className="mister">
                  <h1>M</h1>
                </div>
                <div className="name">
                  <h1>MR HIREN</h1>
                </div>
              </div>
            </article>
            <article className="stories_cards">
              <div className="text">
                <div className="top">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Ftestimony_pics%2Fver1%2F18%2F52967.jpg&w=64&q=75"
                    alt=""
                  />
                </div>
                <div className="star">
                  <h4>Partner</h4>
                  <h5>NANDU TRADING CO.</h5>
                </div>
              </div>
              <div className="para">
                <p>
                  I was Skeptical at first,but GetDistributors.com
                  <br /> proved me wrong.Their provided mewith an
                  <br /> account mnager who went above and beyondto
                  <br /> ensure I found the right distributors.Today,my...
                </p>
              </div>

              <div className="miss">
                <div className="mister">
                  <h1>M</h1>
                </div>
                <div className="name">
                  <h1>MR MITHESH NANDU</h1>
                </div>
              </div>
            </article>
            <article className="stories_cards">
              <div className="text">
                <div className="top">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Ftestimony_pics%2Fver1%2F18%2F52965.jpg&w=64&q=75"
                    alt=""
                  />
                </div>
                <div className="star">
                  <h4>Director</h4>
                  <h5>Southen Lab Pvt. Ltd</h5>
                </div>
              </div>
              <div className="para">
                <p>
                  Thanks to GetDistributors.com,I found reliable distributors
                  who are now a vital part of my firm's success story.Their
                  platform simplified the process of bussiness expansion and
                  gave my bussiness the...
                </p>
              </div>

              <div className="miss">
                <div className="mister">
                  <h1>M</h1>
                </div>
                <div className="name">
                  <h1>MR SAGAL NAGPAL</h1>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stories;
