import React, { useEffect, useState } from 'react';
import './Wheels.css';
import Paragraph from '../Paragraph/Paragraph';
import Business from '../Business Categroy/Business';
import Investment from '../Investment Details/Investment';
import Distributorship from '../Distributorship/Distributorship';
import Location from '../Location/Location';
import Footer from '../../../Components/Footer/Footer';
import MoreInformation from '../More Information/MoreInformation';
import Business__Mind from '../BusinessMind/Business_Mind';
import Enquiry from '../Enquiry/Enquiry';
import Prouducts from '../Product and Services/Prouducts';
import { useLocation } from 'react-router-dom';
import { getSingleBrandDetails } from '../../../api/showbrand ';
import Contact from '../../ContactUs/Contact';
const Wheel = () => {
    const [activeTab, setActiveTab] = useState('overall');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }

    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    const [brand, setBrand] = useState([]);

    useEffect(()=>{
        getDetails();
    },[id]);

    const getDetails=()=>{
        if (!id)return;

        getSingleBrandDetails(id)
       .then((res)=>{
            setBrand(res.data.result);
        })
       .catch((err)=>{
            console.log(err);
        });
    }

    return (
        <div>
            <div className="container wheels_container">
                <div className="entek-box mt-5">
                    <div className="column" onClick={()=> handleTabClick ('overall')}>
                        <p> Overall</p>
                    </div>
                    <div className="column" onClick={() => handleTabClick('businessdetails')}>
                        <p>Business Details</p>
                    </div>
                    <div className="column" onClick={() => handleTabClick('productandservices')}>
                        <p>Product & Services</p>
                    </div>
                    <div className="column" onClick={() => handleTabClick ('contatdetails')}>
                        <p>Contact Details</p>
                    </div>
                </div>

                {activeTab === 'businessdetails' && (
                    <div className="businessdetails-section">
                        <Paragraph />
                        <Business/>
                        <Investment/>
                        <Distributorship/>
                        <Location/>
                    </div>
                )}

                {activeTab === 'productandservices'  &&(
                    <div className='productandservices-section'>
                        <Prouducts/>
                    </div>
                )}

                {activeTab === 'contatdetails' &&(
                    <div className='contatdetails-section'>
                        <Contact/>
                        <MoreInformation/>
                    </div>
                )}

                {activeTab === 'overall' && (
                    brand.map((data)=>(
                        <div className='entek_container'>
                            {/* <div className="entek-boxs">
                                <div className="column">
                                    <h5>Mode</h5>
                                    <p>Looking for distributors</p>
                                </div>
                                <div className="column">
                                    <h5>INVESTMENT REQUIRED</h5>
                                    <p>{data.investmentAmount}laks</p>
                                </div>
                                <div className="column">
                                    <h5>GST.NO</h5>
                                    <p>19AXXXXXX</p>
                                </div>
                                <div className="column">
                                    <h5>PAN NO.</h5>
                                    <p>AADFXXXXXXXX</p>
                                </div>
                            </div>
                            <div className="entek-boxs">
                                <div className="column3">
                                    <h5>ESTABLISHED</h5>
                                    <p>1980</p>
                                </div>
                                <div className="column3">
                                    <h5>SPACE REQUIRED</h5>
                                    <p>{data.floorAreaRequiredMax} sq.ft</p>
                                </div>
                                <div className="column3">
                                    <h5>NO. OF DISTRIBUTORS</h5>
                                    <p>-</p>
                                </div>
                                <div className="column3">
                                    <h5>COMPANY NAME</h5>
                                    <p>{data.companyName}</p>
                                </div>
                            </div> */}
                            <div className='container mt-5'>
                            <div className='row'>
  <div className='col-md-12 col-lg-3  Area_req all_ref'>
    < h3>{data.floorAreaRequiredMax}</h3>
    <p>Area Req</p>
  </div>
  <div className='col-md-12 col-lg-3 Area_req all_ref'>
    < h3>{data.investmentAmount}</h3>
    <p>Investment Size    </p>
  </div>
  <div className='col-md-12 col-lg-3 Area_req all_ref'>
    < h3>{data.noOfFranchiseOutlets}</h3>
    <p>No. Of Franchise Outlets</p>
  </div>
  <div className='col-md-12 col-lg-3 Area_req establish_year all_ref'>
    < h3>{data.yearCommencedOperation}</h3>
    <p>Establishment Year
    </p>
  </div>
  </div>
</div>
                        </div>
                    ))
                )}

                {activeTab === 'overall' &&(
                    <div className='overall-section'>
                        <Enquiry/>
                        <Paragraph />
                        <Business/>
                        <Investment/>
                        <Distributorship/>
                        <Location/>
                         <Contact/>
                        <MoreInformation/>
                    </div>
                )}
            </div>
            <Footer /> {/* Render Footer outside the businessdetails-section */}
        </div>
    );
};

export default Wheel;
