import React, { useEffect, useState } from 'react'
import './MoreInformation.css'
import { useLocation } from 'react-router-dom'
import { getSingleBrandDetails } from '../../../api/showbrand ';

const MoreInformation = () => {
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    const  [brand, setBrand] = useState([]);

    useEffect(()=>{
        getDetails();

    },[id]);

    const getDetails = ()=>{
        if (!id) return;

        getSingleBrandDetails(id)
        .then((res)=>{
            setBrand(res.data.result);
        })
        .catch((err)=>{

        });
    }
  return (
    <div>
        {brand.map((data)=>(

       
        <div className="container moreinformation_container" key={data.id}>
                <div className="moreinformation-container">

                    {/* First Row with One Column */}
                    <div className="moreinformation-row">
                        <div className="moreinformation-column">
                            <h1>More Information</h1>
                        </div>
                    </div>
                    {/* Second Row with Two Columns */}
                    <div className="information-row">
                        <div className="information-column">
                            <h6>Name</h6>
                        </div>
                        <div className="information-column">
                            <p>Mr Anmol</p>
                        </div>
                    </div>

                    {/* Add more rows and columns with the same class name */}
                    <div className="information-row">
                        <div className="information-column">
                            <h6>Company Name</h6>
                        </div>
                        <div className="information-column">
                            <p>{data.companyName}</p>
                        </div>
                    </div>

                    <div className="information-row">
                        <div className="information-column">
                            <h6>City</h6>
                        </div>
                        <div className="information-column">
                            <p>{data.city}</p>
                        </div>
                    </div>

                    <div className="information-row">
                        <div className="information-column">
                            <h6>State</h6>
                        </div>
                        <div className="information-column">
                            <p>{data.state}</p>
                        </div>
                    </div>

                    <div className="information-row">
                        <div className="information-column">
                            <h6>Coutry</h6>
                        </div>
                        <div className="information-column">
                            <p>{data.country}</p>
                        </div>
                    </div>

                    <div className="information-row">
                        <div className="information-column">
                            <h6>Zip Code</h6>
                        </div>
                        <div className="information-column">
                            <p>{data.pincode}</p>
                        </div>
                    </div>

                    <div className="information-row">
                        <div className="information-column borders-left">
                            <h6>Phone</h6>
                        </div>
                        <div className="information-column borders-right">
                            <p>{data.ownerMobileNumber}</p>
                        </div>
                    </div>

                </div>
            </div>
             ))}
        </div>
    
  )
}

export default MoreInformation