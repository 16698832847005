import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './AddsAll.css';
import image1 from '../../../Asssets/Adds/sales.jpg';
import image2 from '../../../Asssets/Adds/Adds2.jpg';
import image3 from '../../../Asssets/Adds/Adds3.jpg';

function CustomPaging() {
  const images = [image1, image2, image3, image1]; // List of images for the slider

  const settings = {
    customPaging: function(i) {
      return (
        <a className="image_thumbnail">
          <img src={images[i]} alt={`thumbnail ${i + 1}`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          dots: true,
          customPaging: function(i) {
            return (
              <a className="image_thumbnail_mobile">
                <img src={images[i]} alt={`thumbnail ${i + 1}`} />
              </a>
            );
          },
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((img, index) => (
          <div key={index}>
            <img src={img} alt={`slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CustomPaging;
