import React, { useState, useEffect } from 'react';
import './Enquiry.css';
import CustomPaging from '../AddsAll/AddsAll';

const Enquiry = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleResizeError = () => {
      try {
        // Intentionally empty to catch ResizeObserver loop limit errors.
      } catch (error) {
        console.error('ResizeObserver loop error:', error);
      }
    };

    window.addEventListener('resize', handleResizeError);

    return () => {
      window.removeEventListener('resize', handleResizeError);
    };
  }, []);

  return (
    <div className='Inform__Detail_Background'>
      <div className='Send' onClick={openModal}>
        <div className='Images_Banners'>
          <div className='row_image'>
            <img src='https://franchiseindia.s3.ap-south-1.amazonaws.com/franchisor/template/slider/0513/714952562.jpg' className='img-fluid' alt="Banner" />
          </div>
          <div className='image_slide_ll'>
            <img src='https://franchiseindia.s3.ap-south-1.amazonaws.com/franchisor/template/slider/0513/311915618.jpg' className='img-fluid' alt="Slide 1" />
            <img src='https://franchiseindia.s3.ap-south-1.amazonaws.com/franchisor/template/slider/0513/1521308529.jpg' className='img-fluid' alt="Slide 2" />
            <img src='https://franchiseindia.s3.ap-south-1.amazonaws.com/franchisor/template/slider/0513/311915618.jpg' className='img-fluid' alt="Slide 3" />
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modals_detials">
            <span className="close_popups" onClick={closeModal}>&times;</span>
            <h2 className='text-cent'>Shops</h2>
            <CustomPaging />
          </div>
        </div>
      )}
    </div>
  );
}

export default Enquiry;
