import React from 'react'
import './Location.css'

const Location = () => {
    
    return (
        <div>
            <div className="container location_container">
                <div className="investment-container">

                    {/* First Row with One Column */}
                    <div className="location-row">
                        <div className="location-column">
                            <h1>Preferred Location</h1>
                        </div>
                    </div>






















                    {/* Second Row with Two Columns */}
                    <div className="locatio-row">
                        <div className="locatio-column location_unions">
                            <h6>East</h6>
                        </div>
                        <div className="locatio-column">
                            <p>West Benga, Arunachal Pradesh, Assam, Manipur,Meghalaya, Mizoram, Nagaland, Sikkim,Tripura, Odisha, </p>
                        </div>
                    </div>

                    {/* Add more rows and columns with the same class name */}
                    <div className="locatio-row">
                        <div className="locatio-column location_union">
                            <h6>Union Territories</h6>
                        </div>
                        <div className="locatio-column">
                            <p>Andaman and Nicobar Islands
                                , Chandigarh, Dadra and Nagar Haveli, Daman and Diu, Lakshadweep, Puducherry, </p>
                        </div>
                    </div>

                    <div className="locatio-row">
                        <div className="locatio-column">
                            <h6>South</h6>
                        </div>
                        <div className="locatio-column">
                            <p>Andhra Pradesh, Karnataka, Kerala, Tamil Nadu, Telangana, </p>
                        </div>
                    </div>

                    <div className="locatio-row">
                        <div className="locatio-column">
                            <h6>Central</h6>
                        </div>
                        <div className="locatio-column">
                            <p>Bihar, Chhattisgarh, Jharkhand, Madhya Pradesh, </p>
                        </div>
                    </div>

                    <div className="locatio-row">
                        <div className="locatio-column">
                            <h6>North</h6>
                        </div>
                        <div className="locatio-column">
                            <p>Delhi, Haryana, Himachal Pradesh, Jammu and Kashmir, Uttar Pradesh, Punjab, Uttarakhand, </p>
                        </div>
                    </div>

                    <div className="locatio-row">
                        <div className="locatio-column borders-left">
                            <h6>West</h6>
                        </div>
                        <div className="locatio-column borders-right">
                            <p>Goa, Gujarat, Maharashtra, Rajasthan, </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Location
