import React, { useRef, useState, useEffect } from "react";
import "./Francises.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { distributorId } from "../../../Utils/Storage";
import { FranchiseDetails } from "../../../api/francises";
import { toast } from "react-toastify";
import { getCategory } from "../../../api/category";
import { uploadFile } from "../../../Utils/FileUpload";

const FranchieseProfile = () => {

  const initialSateInputs = {
    distributorId: distributorId(),
    franchiseName: "",
    email: "",
    image: "",
    category: "",
    subCategory: "",
    specialFeatures: "",
    description: "",
    brochure:"",
    areaRequired:"",
    gstNumber:"",
    noOfOutlets:"",
    establishedYear:"",
    lookingForInvestor:"",
    investmentAmount: "",
    rateOfInterest: "",
  };
  const initialSateErrors = {
    franchiseName: false,
    email: false,
    image: false,
    category: false,
    subCategory: false,
    specialFeatures: false,
    description: false,
    investmentAmount: false,
    rateOfInterest: false,
  };
  const [inputs, setInputs] = useState(initialSateInputs);
  const [errors, setErrors] = useState(initialSateErrors);
  const [submitted, setSubmiited] = useState(false);
  const navigate = useNavigate();
  const [categorydropdown, setCategorydropdown] = useState([]);
  const [subcategoryDropdown, setSubcategorydropdown] = useState([]);
  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
  };
  const handleValidation = (data) => {
    let newErrors = { ...initialSateErrors };
    if (data.franchiseName === "") {
      newErrors.franchiseName = true;
    }
    if (data.email === "") {
      newErrors.email = true;
    }
    if (data.image === "") {
      newErrors.image = true;
    }
    if (data.category === "") {
      newErrors.category = true;
    }
    if (data.subCategory === "") {
      newErrors.subCategory = true;
    }
    if (data.specialFeatures === "") {
      newErrors.specialFeatures = true;
    }
    if (data.description === "") {
      newErrors.description = true;
    }
    if (data.description < 200) {
      newErrors.description = true;
    }
    if (data.investmentAmount === "") {
      newErrors.investmentAmount = true;
    }
    if (data.rateOfInterest === "") {
      newErrors.rateOfInterest = true;
    }
    return newErrors;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = handleValidation(inputs);
    console.log("dfdfd",inputs)
    setErrors(newErrors);
    const hasErrors = Object.values(newErrors).some((x) => x);
    if (!hasErrors) {
      FranchiseDetails(inputs)
        .then((res) => {
          toast.success("Sumbitted Successfully");
          setInputs(initialSateInputs);
        
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleFileInputs1 = (e) => {
    const file = e?.target?.files[0];
    const folder = "category/image/";
    if (file) {
      uploadFile(file, folder)
        .then((res) => {
          const image = res?.Location;
          setInputs({ ...inputs, image: image });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const categoryDropdown = async () => {
    try {
      const response = await getCategory();
      console.log(response, "eni");
      setCategorydropdown(response.data.result);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    categoryDropdown();
  }, []);
  const imageref = useRef(null);

  return (
    <div>
      <div>
        <div className="frnacisesContainer">
          <nav>
            <div className="francises">
              <p>Distributors </p>|<p>Franchises </p>|<p>Request A Callback</p>
            </div>
            <div className="getFrancises">
              <div>
                <h2>GetDistributors.com</h2>
                <ul>
                  <p>
                    <li>Distributors</li>
                  </p>
                  <li>Franchies</li>
                </ul>
              </div>
              <div className="francisesdropdown">
                <div className="farncisesdropbutn">
                  <i className="bi bi-person-fill"></i> My Franchiesm
                </div>
              </div>
            </div>

            <div className="frnacieshm1">
              <div className="frnacieshomeInquire">
                <p>
                  <Link to="/userprofile">
                    <i className="bi bi-house-door-fill"></i> My Profile
                  </Link>
                </p>
                <p>
                  {" "}
                  <i class="bi bi-send-check"></i> My Inquiries{" "}
                </p>
              </div>
            </div>
          </nav>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="formGruppDetials">
            <h3>Basic Franchisor Detials</h3>
            <br />
            <div className="labelcontent">
              <label>
                <h5>
                  Name of the Brands<span className="span">*</span>
                </h5>
              </label>
              {errors.franchiseName && (
                <span className="error text-danger">Name is required</span>
              )}

              <input
                type="text"
                placeholder="Name of the Brands"
                name="franchiseName"
                value={inputs.franchiseName}
                onChange={handleInputs}
              />
            </div>

            <div className="labelcontent ">
              <label>
                <h5>
                  Email<span className="span">*</span>
                </h5>
              </label>
              {errors.email && (
                <span className="error text-danger">Email is required</span>
              )}

              <span className="mobileInfomation1">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={inputs.email}
                  onChange={handleInputs}
                />
              </span>
            </div>

            <div className="labelcontent">
              <label>
                <h5>
                  Image Upload<span className="span">*</span>
                </h5>
              </label>
              {errors.image && (
                <span className="error text-danger">Image is required</span>
              )}

              <div class="mb-3 font-szie-2rem ImageFile">
                <input
                  type="file"
                  id="formFile"
                  placeholder="Image"
                  name="image"
                  onChange={handleFileInputs1}
                />
                <img src="" />
              </div>
            </div>

            <div className="labelcontent">
              <label>
                <h5>
                  Brochure<span className="span">*</span>
                </h5>
              </label>
              {errors.brochure && (
                <span className="error text-danger">brochure is required</span>
              )}

              <div class="mb-3 font-szie-2rem ImageFile">
                <input
                  type="file"
                  // id="formFile"
                  placeholder="brochure"
                  // name="image"
                  // onChange={handleFileInputs1}
                />
                <img src="" />
              </div>
            </div>

            <div className="labelcontent categoryall">
              <label>
                <h5>
                  Industry Category<span className="span">*</span>
                </h5>
              </label>
              {errors.category && (
                <span className="error text-danger">category is required</span>
              )}

              <select
                name="category"
                value={inputs.category}
                onChange={handleInputs}
              >
                <option value="">Select Category</option>
                {categorydropdown.map((category) => (
                  <option key={category._id} value={category.categoryName}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div>
            <div className="labelcontent">
              <label>
                <h5>
                  Select Sub Category<span className="span">*</span>
                </h5>
              </label>
              <input
                type="text"
                placeholder="Sub Category"
                name="subCategory"
                value={inputs.subCategory}
                onChange={handleInputs}
              />
            </div>
            <div className="labelcontent">
              <label>
                <h5>Special specialFeatures</h5>
              </label>
              <input
                type="text"
                name="specialFeatures"
                value={inputs.specialFeatures}
                onChange={handleInputs}
                placeholder="Feature of Franchise"
              />
            </div>

            <div className="labelcontent">
              <label>
                <h5>GST.Number</h5>
              </label>
              <input
                // type="text"
                // name="specialFeatures"
                // value={inputs.specialFeatures}
                // onChange={handleInputs}
                placeholder="GST Number"
              />
            </div>

            <div className="labelcontent">
              <label>
                <h5>Area Required</h5>
              </label>
              <input
                // type="text"
                // name="specialFeatures"
                // value={inputs.specialFeatures}
                // onChange={handleInputs}
                placeholder="Sqr fts"
              />
            </div>

            <div className="labelcontent">
              <label>
                <h5>Number of Outleds</h5>
              </label>
              <input
                // type="text"
                // name="specialFeatures"
                // value={inputs.specialFeatures}
                // onChange={handleInputs}
                placeholder="Number of Outets"
              />
            </div>

            <div className="labelcontent">
              <label>
                <h5>Established Year</h5>
              </label>
              <input
                // type="text"
                // name="specialFeatures"
                // value={inputs.specialFeatures}
                // onChange={handleInputs}
                placeholder="Established year"
              />
            </div>

            <div className="labelcontent">
              <label>
                <h5>Looking for Investor</h5>
              </label>

              <select name="investmentAvailability" id="investmentAvailability">
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              {/* <input
                // type="text"
                // name="specialFeatures"
                // value={inputs.specialFeatures}
                // onChange={handleInputs}
                placeholder="established year"
              /> */}
            </div>

            <div className="labelcontent">
              <label>
                <h5>
                  Description<span className="span">*</span>
                </h5>
              </label>
              <div className="descriptionAdress">
                {errors.description && (
                  <span className="error text-danger">
                    Minium required 200 words
                  </span>
                )}

                <textarea
                  rows="3"
                  placeholder="Description"
                  name="description"
                  className=""
                  value={inputs.description}
                  onChange={handleInputs}
                />
              </div>
            </div>

            <h3>Franchisor Investment Detail</h3>

            <div className="labelcontent">
              <label>
                <h5>
                  Investment Amount<span className="span">*</span>
                </h5>
              </label>

              <div className="investment-inputs">
                <input
                  type="text"
                  name="minInvestmentAmount"
                  placeholder="Min Amount"
                  value={inputs.minInvestmentAmount}
                  onChange={handleInputs}
                />
                <input
                  type="text"
                  name="maxInvestmentAmount"
                  placeholder="Max Amount"
                  value={inputs.maxInvestmentAmount}
                  onChange={handleInputs}
                />
              </div>
            </div>

            <div className="updateDetials">
              <button type="submit">Update</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FranchieseProfile;
