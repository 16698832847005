import React, { useState, useEffect } from 'react'
import './FoodBeverageCards.css'
import { useLocation } from 'react-router-dom'
import { getSingleCategory } from '../../../api/category';


const FoodBeverageCards = () => {
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("category")

    const [brand, setBrnad] = useState([]);

    useEffect(()=>{
      getBrandDetails();
    }, [id]);

    const getBrandDetails=()=>{
      if(!id)return;


      getSingleCategory(id)
      .then((res)=>{
        setBrnad(res.data.result);
        console.log("Brand data:", res.data.result);
        

      })

      .catch((err)=>{
        console.log(err);
      })
    }


  return (
    <div className="container food-main-container">
      <div className="wrapper">
        <div className="Cards_Container">
          {brand && brand.length > 0 &&
            brand.map((brand) => (
              <div key={brand.id} className="card_inner_container">
                <div className="card_imgcontainer">
                  <img src={brand.imageUrl} alt={brand.name} />
                </div>
                <div className="content_container">
                  <div className="">
                    <span className="Card1__InfoCard_Subtitle">
                      {brand.category}
                    </span>
                    <div className="Card1__Tittle">
                      <h5>{brand.name}</h5>
                    </div>
                    <div className="Card1__Price_range">
                      <div className="Card1__Prange">Investment price range</div>
                      <div className="Card1__Price">{brand.investmentAmount}</div>
                    </div>
                    <div className="Card1__Info">
                      <div className="Info">Established</div>
                      <div className="Year">2020</div>
                    </div>
                    <div className="Card1__Distributors">Distributors of</div>
                    <div className="Card1__Remarks">
                      Mad Angles, NoodSyrup - Kuff-Cure, U-liv, Diabi-Cure,
                      Ky-Stone les, Popcorn, Pasta Namkeen
                    </div>
                    <div className="brand_logo">
                      <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Flisting-trusted.png&w=96&q=75" alt="" />
                    </div>
                    <button className="Card1__Call d-flex gap-2">
                      <i class="bi bi-send"></i>
                      <p className="mt-3 ">Send Inquiry</p>
                    </button>
                    <div className="View__Deitails d-flex gap-2">
                      <i class="bi bi-telephone"></i>
                      <p className="mt-3">08037303551</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default FoodBeverageCards;
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  // import React, { useEffect, useState } from 'react'
  // import './FoodBeverageCards.css'
  // import { getBrandDetails } from '../../../api/showbrand '
  // import { FileterCategorys } from '../../../api/category'
  // const FoodBeverageCards = ({slectedCateogry}) => {

  //   const[brands,setBrands]=useState([])
  //     useEffect(()=>{
  //       getDetails();
  //     },[slectedCateogry])
  //   const getDetails=()=>{
  //     getBrandDetails()
  //     .then((res) => {
  //       const filteredBrands = res.data.result.filter(brand => brand.category === slectedCateogry);
  //       console.log('Filtered Brands:', filteredBrands); // Debugging line

  //        setBrands(res.data.result);
         
  //       console.log(res);
        
  //     })
  //   .catch((err) => {
  //       console.log(err);
  //     });
  //   }
  //   return (
  //   <div className="container food-main-container">
  //     <div className="wrapper">
  //     <div className="Cards_Container">
  //       {brands && brands.length>0 &&
  //       brands.map((brand)=>(

  //         <div key={brand.id} className="card_inner_container">
  //         <div className="card_imgcontainer">
  //           <img
  //             src={brand.imageUrl}
  //             alt={brand.name} 
  //           />
  //         </div>
  //         <div className="content_container">
  //           <div className="">
  //             <span className="Card1__InfoCard_Subtitle">
  //               {brand.category}
  //             </span>
  //             <div className="Card1__Tittle">
  //               <h5>{brand.name}</h5>
  //             </div>
  //             <div className="Card1__Price_range">
  //               <div className="Card1__Prange">Investment price range</div>
  //               <div className="Card1__Price">{brand.investmentAmount}</div>
  //             </div>
  //             <div className="Card1__Info">
  //               <div className="Info">Established</div>
  //               <div className="Year">2020</div>
  //             </div>
  //             <div className="Card1__Distributors">Distributors of</div>
  //             <div className="Card1__Remarks">
  //               Mad Angles, NoodSyrup - Kuff-Cure, U-liv, Diabi-Cure,
  //               Ky-Stone les, Popcorn, Pasta Namkeen
  //             </div>
  //             <div className="brand_logo">
  //               <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Flisting-trusted.png&w=96&q=75" alt="" />
  //             </div>
              
  //               <button className="Card1__Call d-flex gap-2">
  //                 <i class="bi bi-send"></i>
  //                 <p className="mt-3 ">Send Inquiry</p>
  //               </button>
  //             <div className="View__Deitails d-flex gap-2">
  //               <i class="bi bi-telephone"></i>
  //               <p className="mt-3">08037303551</p>
  //             </div>
            
  //           </div>
  //         </div>
  //       </div>
  //       ))}
            


  //                       
  //           </div>
  //     </div>
  //   </div>
  //   )
  // }

  // export default FoodBeverageCards