import React from 'react'
import './Advertisement.css'

const Advertisement = () => {
  return (
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbanner%2Fvidhyut.jpg&w=1200&q=75" class="d-block w-100 " alt=""/>
      </div>
      <div class="carousel-item">
        <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbanner%2Fgd-banner-2.gif&w=1200&q=75" class="d-block w-100 " alt=""/>
      </div>
      <div class="carousel-item">
        <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbanner%2Fgd-banner-1.gif&w=1200&q=75" class="d-block w-100 " alt=""/>
      </div>
      <div class="carousel-item">
        <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbanner%2Fost-dlabs.gif&w=1200&q=75" class="d-block w-100 " alt=""/>
      </div>
      <div>
      </div>

    </div>
    
  </div>
  
  )
}

export default Advertisement