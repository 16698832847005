import API from "./api";
import {getBrandDetails} from "./endpoints"

export const Brand = (data) => {
return API.post(`${getBrandDetails}/`, data);


}


