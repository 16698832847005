import React, { useState } from "react";
import "./Looking.css";

const Looking = () => {
  const [activeTab, setActiveTab] = useState("distributors");

  const changeTab = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="col-10">
      <div className="row d-flex flex-wrap pt-5 pb-7 px-9 py-7">
        <div className="col-10 looking_container pb-2 rounded-4">
          <h3>What are you looking?</h3>
          <h6>Fast Track Your Business</h6>
          <div id="tab" className="btn-group" data-toggle="buttons">
            <button
              onClick={() => changeTab("distributors")}
              className={`btn rounded-pill ${
                activeTab === "distributors" ? "active" : ""
              }`}
            >
              <i className="bi bi-check-circle"></i>
              <span>Distributors</span>
            </button>
            <button
              onClick={() => changeTab("franchise")}
              className={`btn rounded-pill ${
                activeTab === "franchise" ? "active" : ""
              }`}
            >
              <i className="bi bi-check-circle"></i>
              <span>Franchise</span>
            </button>
          </div>
          <div className="form">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                I am looking for distributor
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                defaultChecked
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                I want to become a distributor
              </label>
            </div>
          </div>

          <form id="contact_form" name="contact_form" method="post">
            <div className="mb-5 row d-flex Looking_contact_details">
              <div className="col">
                <label htmlFor="email_addr"></label>
                <input
                  type="email"
                  required
                  maxLength={50}
                  className="form-control"
                  id="email_addr"
                  name="email"
                  placeholder="Email Address"
                />
              </div>
              <div className="col phone">
                <button
                  className="btn btn-light dropdown-toggle mb-2"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  +91
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      +91 India
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      +1 United States
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      +44 United Kingdom
                    </a>
                  </li>
                </ul>

                <label htmlFor="phone_input"></label>
                <input
                  type="tel"
                  required
                  maxLength={30}
                  className="form-control"
                  id="phone_input"
                  name="Phone"
                  placeholder="Mobile Number"
                />
              </div>
            </div>
            <div className="text_input">
              <label htmlFor="text"></label>
              <input
                type="text"
                required
                maxLength={100}
                className="form-control"
                id="text"
                name="text"
                placeholder="Enter Product"
              />
            </div>
            <div>
              <label htmlFor="message"></label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                placeholder="Type your requirement"
                rows={5}
              />
            </div>
            <div className="looking_button text-white mt-5 mb-4">
              <button type="button" className="skip btn-warning">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Looking;
