import React, { useEffect, useState } from 'react';
import './Investment.css';
import { getSingleBrandDetails } from '../../../api/showbrand ';
import { useLocation } from 'react-router-dom';

const Investment = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");

  const [brand, setBrand] = useState([]);

  useEffect(()=>{
    getDetails();
  }, [id]);

  const getDetails=()=>{
    if(!id) return;
    getSingleBrandDetails(id)
    .then((res)=>{
      console.log("api res",res)
      setBrand(res.data.result);

    })
    .catch((err)=>{
      console.log(err);
    });

  }
  return (
    
    <div className="container investment_container">
      {brand.map((data)=>(

   
    <div className="investment-containers" key ={data.id}>

      {/* First Row with One Column */}
      <div className="first-row">
        <div className="single-column">
            <h1>Global Wheels Investment Details</h1>
        </div>
      </div>











      

      {/* Second Row with Two Columns */}
      <div className="second-row">
        <div className="column1">
            <h6>Investment Required</h6>
           
        </div>
        <div className="column1">
        <h5>{data.investmentAmount}</h5>
        </div>
      </div>
    </div>
       ))}
    </div>
  );
}

export default Investment;



