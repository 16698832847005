import React, { useEffect, useState } from 'react'
import  './Business.css'
import { useLocation } from 'react-router-dom'
import { getSingleBrandDetails } from '../../../api/showbrand ';

const Business = () => {
  const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    const  [brand, setBrand] = useState([]);

    useEffect(()=>{
        getDetails();

    },[id]);

    const getDetails = ()=>{
        if (!id) return;

        getSingleBrandDetails(id)
        .then((res)=>{
            setBrand([res.data.result]);
        })
        .catch((err)=>{

        });
    }
  return (
    <div>
       <div className=' container business_container'>
       {brand.map((data)=>(

        <div className='product' key={data.id}>
        <h1>Global Wheels Business Category</h1>
        <div className='catergroy'>
          <h6>Palstics&Products</h6>
          <h6>Furniture Fitting & Fixtures</h6>
          <h6>Industrial Supplies</h6>
          <h6>Castor Wheels</h6>
          <h6>Engineering Palstics</h6>
          <h6>Furniture</h6>
          <h6>HDPE Sheets</h6>
          <h6>Heavy Duty Wheel</h6>
          <h6>Hdpe Wheels</h6>
          <h6> Palstic Wheels</h6>
          <h6>Nylon Caster Wheel</h6>
          <h6>Rubber Caster Wheels</h6>
          <h6> Trolley Caster Wheels</h6>
          <h6> Heavy Duty Casters</h6>
          <h6>Medicla Caster</h6>
          <h6>Nylon Wheels</h6>
          <h6>Nylon Caster</h6>
          <h6>Nylon Sheet</h6>
          <h6>Industril Caster</h6>
          <h6>Light Duty Caster</h6>
          <h6>Medium Duty Caster</h6>
          <h6>Pu Caster Wheel</h6>
          <h6>Cast Nylon Sheet</h6>
          <h6>Plastic Trolley Wheel</h6>
          <h6>Polypropylene Wheel</h6>
          <h6>Pu Wheels</h6>
          <h6>Rubber Wheels</h6>
          <h6>Rubber Wheels Caster</h6>
          <h6> Pu Coated Wheels</h6>
          <h6>Plastic Caster</h6>
          <h6>Rigid Caster</h6>
          <h6>Ball Caster</h6>
          <h6>Ball Caster</h6>
          <h6>Ball Bearing Caster</h6>
          <h6>Furniture Wheel</h6>
          <h6>Guide Wheel</h6>
          <h6>Twin Wheel Caster</h6>
          <h6>Sigle Wheel Caster</h6>
          <h6> Swivel Caster Wheel</h6>
          <h6>Epic Wheel</h6>
          <h6> Uhm Wheels</h6>
          <h6>Mounted Wheels</h6>
          <h6>Pu Foam Wheel</h6>
          <h6>Trolley Caster</h6>
          <h6>Caster Wheels</h6>
        </div>
       </div>
       ))}
    </div>
    </div>
  )
}

export default Business