import React, { useEffect, useState } from "react";
import "./Feature.css";
import { getFranchiseDetails } from "../../../api/showfrachises";
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../../Utils/Auth';

const Features = () => {
  const [franchises, setFranchises] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDetailss();
  }, []);

  const getDetailss = () => {
    getFranchiseDetails()
      .then((res) => {
        setFranchises(res.data.result.reverse());
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabClick = (e) => {
    if (!isAuthenticated()) {
      e.preventDefault();
      navigate('/login');
    }
  };

  const handleViewAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      <div className="offers_all">
        <div className="main_text">
          <h2>Featured Franchise Opportunities</h2>
        </div>
        <div className="connect">
          {franchises
            .slice(0, showAll ? franchises.length : 10)
            .map((franchise) => (
              <div className="cardes" key={franchise.id} onClick={handleTabClick}>
                <div className="cardes_img">
                  <img src={franchise.image} alt="" />
                </div>
                <div className="cardes_body">
                  <button className="button">{franchise.category}</button>
                  <h3>{franchise.franchiseName}</h3>
                  <span>Investment Price</span>
                  <h5> ${franchise.investmentAmount} </h5>
                </div>
              </div>
            ))}
          {showAll ? (
            <div className="MoreFranchise">
              <button className="MoreALLS" onClick={handleViewAll}>
                View Less<i className="bi bi-arrow-right-short"></i>
              </button>
            </div>
          ) : (
            <div className="MoreFranchise">
              <button className="MoreALLS" onClick={handleViewAll}>
                View More<i className="bi bi-arrow-right-short"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Features;
