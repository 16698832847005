import API from "./api";
import { LoginPage } from "./endpoints";


export const Loginpanel = (data) => {
  return API.post(`${LoginPage}`, data);
};

export const signVerifyDistributorOTP = (data) => {
  return API.post(`${LoginPage}/verifyOtp`, data);
};


