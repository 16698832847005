import React, { useState, useEffect } from "react";
import "./Image__Crousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { getBrandDetails } from "../../../api/showbrand ";

const Image__crousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1600, // XXL screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // XL screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Large Laptop screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Small Laptop / Large Tablet screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Tablet screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [slide, setSlide] = useState([]);
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    getBrandDetails()
      .then((res) => {
        setSlide(res.data.result);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="">
     
      <div className="container-fluid mt-5">
        <div className="row">
            <div className="col-lg-12 text-start p-3">
            <h2>Discover the Latest Distributorship Opportunities</h2>
            </div>
        </div>
        <div className="row mt-3 ">
          <div className="col-lg-3 col-md-3 col-12 col-xl-3 ALLGreen">
            <div className="CardShip1">
              <p>Recently Added Distributorships</p>
            </div>
          </div>
          
          <div className="col-lg-9 col-md-12 col-12">
            {slide && slide.length > 0 && (
              <Slider {...settings}>
                {slide.map((slides, index) => (
                  <div key={index} className="allCardsInfo">
                    <div className="MainCards">
                      <div className="ImgBtn">
                        <div className="ImageCircle">
                          <img
                            src={slides.imageUrl}
                            alt={slides.name}
                            className="Slide12"
                          />
                        </div>
                        <div className="TopBtom">
                          <span className="BtnEdit">{slides.category}</span>
                          <p>{slides.name}</p>
                        </div>
                      </div>
                      <div className="PriceRange">
                        <span className="Invest">Investment Price Range</span>
                        <p>&#8377;{slides.investmentAmount}</p>
                      </div>
                      <div className="FoundI">
                        <span className="FnIn">Founded In</span>
                        <p>{slides.foundedIn}</p>
                      </div>
                      <div className="ViewBtn1">
                        <p>
                          View<i className="bi bi-arrow-right-short"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Image__crousel;
