import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { saveContact } from "../../api/contactus";
import { isAuthenticated } from "../../Utils/Auth";
import { clearStorage,loginType } from "../../Utils/Storage";
import { useNavigate } from "react-router-dom";
import logo from "../../Asssets/shared image - Copy.jpg"

 
const Header = () => {
  const initialSateInputs = {
    fullName: "",
    email: "",
    mobileNumber: "",
    companyName: "",
    pincode: "",
    message: "",
    subject: "",
  };
 
  const initialSateErrors = {
    fullName: false,
    email: false,
    mobileNumber: false,
    companyName: false,
    pincode: false,
    message: false,
    subject: false,
  };
 
  const navigate = useNavigate();
  const [inputs, setInputs] = useState(initialSateInputs);
  const [errors, setErrors] = useState(initialSateErrors);
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const [iloginType, setILoginType] = useState(loginType()); // Initialize as empty string

  const [menuOpen, setMenuOpen] = useState(false);
 
  console.log("Is logged in:", isAuthenticated());
 
  useEffect(() => {
    setIsLoggedIn(isAuthenticated());
    setILoginType(loginType());
  }, []);
  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: false });
  };
 
  const handleValidation = (data) => {
    let newErrors = { ...initialSateErrors };
 
    if (data.fullName === "") {
      newErrors.fullName = true;
    }
    if (data.email === "") {
      newErrors.email = true;
    }
    if (data.mobileNumber === "") {
      newErrors.mobileNumber = true;
    }
    if (data.companyName === "") {
      newErrors.companyName = true;
    }
    if (data.pincode === "") {
      newErrors.pincode = true;
    }
    if (data.message === "") {
      newErrors.message = true;
    }
    if (data.subject === "") {
      newErrors.subject = true;
    }
    return newErrors;
  };
 
  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = handleValidation(inputs);
    setErrors(newErrors);
 
    const hasErrors = Object.values(newErrors).some((x) => x);
    if (!hasErrors) {
      saveContact(inputs)
        .then((res) => {
          toast.success("Details Submitted Successfully");
          event.target.reset();
          setInputs(initialSateInputs);
          setErrors(initialSateErrors);
          setIsLoggedIn(true);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };
 
  const handleLogout = () => {
    console.log("Logging out...");
    clearStorage();
    setILoginType(""); // Reset login type

    setIsLoggedIn(false);
    toast.success("You have logged out successfully.");
    navigate("/");
  };
 
  return (
    <section className="h_container mt-3">
      <div className="col-md-12 ">
        <div className="inner_container d-flex align-items-center justify-content-between">
          <div className="image_logos">
           <img src= {logo} alt="" />
          </div>
          <div className="input-group input_dropdowns  ">
            <button
              className="rounded-start-pill dropdown-toggle px-2 border-0 search_Dropdown"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ backgroundColor: "#CBD5E0" }}
            >
              Dropdown
            </button>
            <ul className="dropdown-menu">
              <li className="text-dark ">
                <NavLink active>Distributors</NavLink>
              </li>
              <li className="text-dark ">
                <NavLink active>Franchise</NavLink>
              </li>
            </ul>
            <input
              type="text"
              className="form-control form-control-lg "
              aria-label="Text input with 2 dropdown buttons"
              placeholder="Search  "
            />
            <button
              className="rounded-end-pill px-3 text-white py-2 border-0 search_input"
              type="button"
              style={{ backgroundColor: "#3D348B" }}
            >
              Search
            </button>
          </div>
          <button
            className={`menu-toggle menu_selects ${menuOpen ? "open" : ""}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            ☰
          </button>
 
          <div className={`menu-content ${menuOpen ? "open" : ""}`}>
           
            <div className="popupBtn">
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal5"
              >
                Get Quote
              </button>
            </div>
            <div
              className="modal fade"
              id="exampleModal5"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content designModel">
                  <div className="modal-header modalHeader">
                    <h3>Contact Us</h3>
                    <button
                      type="button"
                      className="close buttonClose"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="UserDetails">
                      <form onSubmit={handleSubmit}>
                        <div className="AllName">
                          <div className="FullName">
                            <input
                              type="text"
                              name="fullName"
                              value={inputs.fullName}
                              onChange={handleInputs}
                              placeholder="Full Name"
                              maxLength={20}
                              className={errors.fullName ? "error" : ""}
                            />
                            {errors.fullName && (
                              <span className="error-text text-danger">
                                *This field Required*
                              </span>
                            )}
                          </div>
                          <div className="FullName">
                            <input
                              type="email"
                              name="email"
                              value={inputs.email}
                              onChange={handleInputs}
                              placeholder="Email Address"
                              className={errors.email ? "error" : ""}
                            />
                            {errors.email && (
                              <span className="error-text text-danger">
                                *This field Required*
                              </span>
                            )}
                          </div>
                        </div>  
                        <div className="CompanyDetials">
                          <input
                            type="text"
                            name="companyName"
                            value={inputs.companyName}
                            onChange={handleInputs}
                            placeholder="Company Name"
                            maxLength={30}
                            className={errors.companyName ? "error" : ""}
                          />
                          {errors.companyName && (
                            <span className="error-text text-danger">
                              *This field Required*
                            </span>
                          )}
                        </div>
                        <div className="OtherDetail">
                          <div className="MobileDetials">
                            <input
                              type="tel"
                              name="mobileNumber"
                              value={inputs.mobileNumber}
                              onChange={handleInputs}
                              placeholder="Mobile No"
                              className={errors.mobileNumber ? "error" : ""}
                            />
                            {errors.mobileNumber && (
                              <span className="error-text text-danger">
                                *This field Required*
                              </span>
                            )}
                          </div>
                          <div className="MobileDetials">
                            <input
                              type="number"
                              name="pincode"
                              value={inputs.pincode}
                              onChange={handleInputs}
                              placeholder="Pincode"
                            />
                          </div>
                        </div>
                        <div className="SubjectName">
                          <div className="Subjets">
                            <input
                              type="text"
                              name="subject"
                              value={inputs.subject}
                              onChange={handleInputs}
                              placeholder="Subject"
                              className={errors.subject ? "error" : ""}
                            />
                            {errors.subject && (
                              <span className="error-text text-danger">
                                *This field Required*
                              </span>
                            )}
                          </div>
                          <div className="TypeEnter">
                            <textarea
                              rows="5"
                              name="message"
                              value={inputs.message}
                              onChange={handleInputs}
                              placeholder="Type Message"
                              className= {`text_areamessage${errors.message} ? "error" : ""`}
                            />
                            {errors.message && (
                              <span className="error-text text-danger ">
                                *This field Required*
                              </span>
                            )}
                          </div>
                          <div className="Submitbtn">
                            <button type="submit">Submit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              {isLoggedIn ? (
              iloginType === 'User' ? (
                <div>
                <div className="dropdown">
                  <div className="profiles" type="button" data-bs-toggle="dropdown">
                    Profile
                  </div>
                  <ul className="dropdown-menu dropdown-content">
                    <li>
                      <Link to="/userprofile">
                       <span className='text-white'>Profile</span>  <i className="bi bi-arrow-right"></i>
                      </Link>
                    </li>
                    <div className="dropdown-content">
                        <span className="btn btn-primary" onClick={handleLogout}>
                          Logout <i className="bi bi-arrow-right"></i> <br />
                          <i className="bi bi-emoji-tear emoji"></i>
                        </span>
                    </div>
                  </ul>
                </div>
                
                </div>
               
              ) : iloginType === 'distributor' ? (
                <div className="dropdown">
                  <div className="profiles" type="button" data-bs-toggle="dropdown">
                   <span >Profile</span> 
                  </div>
                  <ul className="dropdown-menu dropdown-content">
                    <li>
                      <Link to="/userprofile">
                        <span className="text-white">My Distributor</span><i className="bi bi-arrow-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/franchisesdetails">
                        <span className="text-white">My Franchise </span><i className="bi bi-arrow-right"></i>
                      </Link>
                    </li>
                    <div className="dropdown-content">
                      <Link to="/">
                        <div className="logout_button" onClick={handleLogout}>
                          Logout <i className="bi bi-arrow-right"></i> <br />
                          <i className="bi bi-emoji-tear emoji"></i>
                        </div>
                      </Link>
                    </div>
                  </ul>
                </div>
              ) : null
            ) : (
              <div className="d-flex align-items-center signin gap-5">
                <Link to="/login">
                  <span className="text-dark">Login In</span>
                </Link>
                <Link to="/signup">
                  <div className="join_free">Register</div>
                </Link>
           
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
 
export default Header;