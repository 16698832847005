import API from "./api";
import { showBrand } from "./endpoints";

export const getBrandDetails = (data) => {
  return API.get(`${showBrand}/`, data);
};

export const getSingleBrandDetails = (data) => {
  return API.get(`${showBrand}/getSingleBrand`, { params: { _id: data } });
};
