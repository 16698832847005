import API from "./api";
import {SignUp} from "./endpoints"
import { LoginPage } from "./endpoints";

export const signUp = (data) => {
  return API.post(`${SignUp}`, data);
};

export const signVerifyDistributorOTP = (data) => {
    return API.post(`${LoginPage}/verifyOtp`, data);
  };

export const signVerifyOTP = (data) => {
  return API.post(`${LoginPage}/verifyOtp`, data);
};

export const signResendOTP = (data) => {
  return API.post(`${LoginPage}/Login`, data);
};
