import React, { useState, useEffect } from "react";
import "./Exlcusive.css";
import { getBrandDetails } from "../../../api/showbrand ";


const Exclusive = () => {


    const [brands, setBrands] = useState([]);
  
    useEffect(() => {
      getDetails();
    }, []);
  
    const getDetails = () => {
    
      getBrandDetails()
       .then((res) => {
          setBrands(res.data.result);
          console.log(res);
          
        })
       .catch((err) => {
          console.log(err);
        });
    };
  
    return (
      <div>
        <div className="BrandsExclusive">
          <div className="Brands__Heading">
            <h2>Exclusive Brands</h2>
          </div>
          <div className="Brands__Container">

            {brands && brands.length > 0 &&
              brands.map((brand) => (

                <div key={brand.id} className="Small__Cards">
                  <div className='Ifo_images'>
                  <div className="Brand__Images">
                    <img src={brand.imageUrl} alt={brand.name} />
                  </div>
                    <div className="Info__btn">
                      <span className="Info__detials">{brand.category}</span>
                      <span className="Brands__Head">{brand.name}</span>
                      </div>
                  </div>
                 
                  <div className="Brands__Remarks">
                    <span className="Brand__Range">Investment price range</span>
                    <p>{brand.investmentAmount}</p>
                  </div>
                </div>
              ))}

          </div>
        </div>
      </div>
    );
  };
  
  export default Exclusive;