import React, { useEffect, useState } from "react";
import "./Distubutors.css";
import { Link } from "react-router-dom";
import { getBrandDetails } from "../../../api/showbrand ";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../../Utils/Auth";

const Distubutorsworks = () => {
  const [brands, setBrands] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    getBrandDetails()
      .then((res) => {
        setBrands(res.data.result.reverse());
        console.log("datas", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewAll = () => {
    setShowAll(!showAll);
  };

  const handleTabClick = (e) => {
    if (!isAuthenticated()) {
      e.preventDefault();
      navigate("/login");
    }
  };

  return (
    <div className="Inform__Detail_Background backgoudn_bottom">
      <div className="offset-1">
        <h2 className="HeadingDistibutors">
          Featured Distributorship Opportunities
        </h2>
      </div>

      <div className="AllDistubutorship ">
        {brands.slice(0, showAll ? brands.length : 8).map((brand) => (
          <Link
            to={{
              pathname: "/distributorDetails",
              search: `?id=${brand?._id}`,
            }}
            key={brand.id}
            onClick={handleTabClick}
            style={{ textDecoration: "none" }}
          >
            {" "}
            <div className="InfoDetailAll ">
              <div className="ImageDistubutors">
                <img src={brand.imageUrl} alt={brand.imageUrl} />
              </div>
              <div className="AllBtnText">
                <span className="AllInfoSubs">{brand.category}</span>
                <div className="SubHedingTittle">
                  <h5>{brand.companyName}</h5>
                </div>
                <div className="DistubutorPriceRange">
                  <div className="InvestmentRange">Investment price range</div>
                  <img
                    src="https://www.getdistributors.com/images/icons/money.svg"
                    className="LittleIcons"
                    alt="Prp"
                  />
                  <div className="DistubutorsPrice">
                    {" "}
                    {brand.minInvestment} &#8377; {brand.investmentAmount}laks
                  </div>
                </div>
                <div className="EstablishedAll">
                  <div className="EstablishInfo">Established</div>
                  <span className="LittleIcons">
                    <i className="bi bi-suitcase-lg"></i>
                  </span>
                  <div className="AllYears">{brand.established}</div>
                </div>
                <div className="RemarksDistubutors">Distributors of</div>
                <div className="RemarksAllSub">
                  {" "}
                  Chemicals, food, Agriculture, Machinearies, Mobile, Laptops{" "}
                  {brand.distributorOf}
                </div>
                <div>
                  <button className="TeleDetails">
                    <span className="IconCall">
                      <i className="bi bi-telephone"></i>
                    </span>
                    <span className="MNumber">
                      6382303224{brand.mobileNumber}
                    </span>
                  </button>
                </div>
                <div className="CallView">
                  <a href="viewdetails">View Details</a>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {showAll ? (
        <div className="MoreDistubutors">
          <button className="MoreALL" onClick={handleViewAll}>
            View Less<i className="bi bi-arrow-right-short"></i>
          </button>
        </div>
      ) : (
        <div className="MoreDistubutors">
          <button className="MoreALL" onClick={handleViewAll}>
            View More<i className="bi bi-arrow-right-short"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default Distubutorsworks;
