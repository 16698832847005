import React, { useState } from 'react';
import './Signup.css';
import vector from '../../Asssets/signup/v1.png';
import { toast } from 'react-toastify';
import { signUp, signVerifyDistributorOTP } from '../../api/signup';
import { getToken, saveToken } from '../../Utils/Storage';
import { isValidEmail, isValidPhone } from '../../Utils/Validation';

import {useNavigate} from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css"
import {isAuthenticated} from "../../Utils/Auth";
// *************************User******************//
import {users,UserVerifyOTP} from '../../api/user'
const Signup = () => {
   
 
    const[ph,setPh] = useState('')
 
    //..................Singup A
    const initialStateInputs = {
        name: "",
        email: "",
        mobileNumber: "",
        companyName: "",
        productName: "",
        pinCode: "",
        otp: "",
    };
    const initialStateErrors = {
        name: false,
        email: false,
        mobileNumber: false,
        companyName: false,
        productName: false,
        pinCode: false,
        otp:false,
    };
    const [inputs, setInputs] = useState(initialStateInputs);
    const [errors, setErrors] = useState(initialStateErrors);
    const [activePanel, setActivePanel] = useState('login');
    const [submitted, setSubmitted] = useState(false);
 
   const handleCombiedInputs = () => {
    setPh()
    handleInput()
   }
 
    const handleInputs = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.value });
        setErrors({ ...errors, [event.target.name]: false });  
    };
    const handleValidation = (data) => {
        let newErrors = { ...initialStateErrors };
        if (data.name === "") {
            newErrors.name = true;
        }
       if (data.email ===""){
        newErrors.email =true;
       }
       if (data.mobileNumber === ""){
        newErrors.mobileNumber=true;
       }
       if (data.companyName === ""){
        newErrors.companyName=true;
       }
       if (data.productName===""){
        newErrors.productName=true;
       }
       if (data.pinCode===""){
        newErrors.pinCode=true;
       }
        return newErrors;
    };
 
    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = handleValidation(inputs);
        setErrors(newErrors);
        const hasErrors = Object.values(newErrors).some((x) => x);
        if (!hasErrors) {
            signUp(inputs)
                .then((res) => {
                    if(res.data.message==='Saved Successfully')
                    {
                    toast.success("Details Submitted sucessfuly");
                    const distributorId=res?.data?.result?.distributorDetails?._id;
                    const token =res?.data?.result?.token;
                    const data=
                    {
                      distributorId:distributorId,
                      token:token
                    }
                    saveToken(data);
                   
                    setSubmitted(true);
 
                }
                else{
                    toast.error("User Alredy exist")
                    navigate('/login')
 
                }
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    };
 
   
    const handleOTPSubmit = async () => {
        const otpData = {
            mobileNumber: inputs.mobileNumber,
            otp: inputs.otp
        };
   
        // Call the API to verify OTP
        try {
            const response = await signVerifyDistributorOTP(otpData, getToken());
            if (response.data.message === "OTP is verified successfully") {
                toast.success("OTP Verified");
                navigate("/RegistrationForm");
            } else {
                toast.error("Failed to verify OTP");
            }
        } catch (error) {
            toast.error("Failed to verify OTP");
        }
    };
   
   
   
 
   
    const togglePanel = (panel) => {
        setActivePanel(panel);
    };
//  **************************************************User******************************//
  const initialStateInputsuser ={
    name:'',
    email:'',
    mobileNumber:'',
    address:'',
    state:'',
    pinCode:'',
    otp:''
    }
    const initialStateErrorsUser={
        name: false,
        email: false,
        mobileNumber:false,
        address:false,
        state:false,
        pinCode:false,
        otp:false,

    }
 
  const [user,setUser]= useState(initialStateInputsuser);
  const[userErrors,setUserErrors] = useState(initialStateErrorsUser);
  const navigate = useNavigate();

const handleValidationUser =(data)=>{
    const errorUer = { ...initialStateErrorsUser };
    if (data.name === "") {
      errorUer.name = true;
    }
    if (data.email === "") {
      errorUer.email = true;
    }
    if (data.mobileNumber === "") {
      errorUer.mobileNumber = true;
    }
    if (data.address === "") {
      errorUer.address = true;
    }
    if (data.state === "") {
        errorUer.state = true;
      }
    if (data.pinCode === "") {
        errorUer.pinCode = true;
    }
   
      if (data.otp === "") {
        errorUer.otp = true;
      }
    // if (!isValidEmail(data.email)) {
    //   errorUer.email.valid = true;
    // }
    // if (!isValidPhone(data.mobileNumber)) {
    //   errorUer.mobileNumber.valid = true;
    // }
    return errorUer;
}


const handleInput = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
    setUserErrors({ ...userErrors, [event.target.name]: false });  
};

  const handleSubmitUser = (event) => {
    event.preventDefault();
    const errorUer = handleValidationUser(user);
    setUserErrors(errorUer);
    const hasErrors = Object.values(errorUer).some((x) => x);
    if (hasErrors) {
        users(user)
            .then((res) => {
                //console.log("user",res)
                if(res.data.message==='Saved Successfully')
                {
                toast.success(res?.data?.message);
                // console.log("sasasa",res)
                const userId = res.data.result['User-Details']._id;
                const token =res?.data?.result?.token;
                const loginType=res?.data?.result?.loginType;
                const data=
                {
                  userId:userId,
                  token:token,
                  loginType:loginType
                }
                console.log("ssgggsvas",data);
                saveToken(data);
               
                setSubmitted(true);

            }
            else{
                toast.error("User Alredy exist")

            }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            });
    }
};
const handleOTPSubmitUser = async () => {
    const otpDatas = {
        mobileNumber: user.mobileNumber,
        otp: user.otp
    };
    console.log(otpDatas)


    // Call the API to verify OTP
    try {
        const response = await signVerifyDistributorOTP(otpDatas, getToken());
        
        if (response.data.message === "OTP is verified successfully") {
            toast.success("OTP Verified");
            navigate("/login");
        } else {
            toast.error("Failed to verify OTP");
        }
    } catch (error) {
        toast.error("Failed to verify OTP");
    }
};
    return (
        <div className='signup_main-container container-fluid'>
            <div className="row">
                <div className="col-lg-12 col-md-8 col-sm-10">
                    <div className="signup-reg-panel">
                       
                        <div className="signup-info-box">
                            <h1 className='text-dark'>Pixalive Franchise</h1>
                            <h2 className='mt-3'>Start your Journey with us as a distributor </h2>
                            <p>Discover the world's best manufacturers and distributors at pixalive franchise</p>
                            <label htmlFor="log-reg-show" id="label-register"   onClick={() => togglePanel('login')}>
                                SignUp
                            </label>        
                            <input type="radio" name="active-log-panel" id="log-reg-show" onChange={handleInputs} checked={activePanel === 'login'} />
                        </div>
 
                        <div className="register-info-box">
                            <h1 className='text-dark'>Pixalive Franchise</h1>
                            <h2 className='mt-3'>Start your Journey with us by becoming a franchise</h2>
 
                            <p>Discover the world's best manufacturers and distributors at pixalive franchise</p>
                            <label htmlFor="log-login-show" id="label-login" onClick={() => togglePanel('register')}>
                                 For Frnachise
                            </label>
 
                            <label htmlFor="log-login-show" id="label-login" onClick={() => togglePanel('signup')}>
                            For Distributor
                            </label>
                            <input type="radio" name="active-log-panel" id="log-login-show" onChange={handleInputs}  checked={activePanel === 'register'} />
                               <input type="radio" name="active-log-panel" id="log-login-show" onChange={handleInputs}  checked={activePanel === 'register'} />
                            <img src={vector} alt="vector" width={250} height={250} />
                        </div>
         
                        <div className={`white-panel ${activePanel === 'register' ? 'register-active' : ''}`}>
                            {!submitted && (
                                <>
 
                                      <div className={`signup-show ${activePanel === 'login' ? 'show-log-panel' : ''}`}>
                                        <h2>User</h2>
                                        <form onSubmit={handleSubmitUser}>
                                            
                                  
                                        <input type="text"
                                         placeholder="Name" 
                                         value={user.name}
                                          className="form-control"
                                           name="name" 
                                           onChange={handleInput} 
                                           />
                                    {userErrors.name && <span className="error-text text-danger">*This field Required*</span>}

                                        <input type="text"
                                         placeholder="Email" 
                                         value={user.email}
                                          className="form-control" 
                                          name="email" 
                                          onChange={handleInput}
                                           />
                                  {userErrors.email && <span className="error-text text-danger">*This field Required*</span>}
                                  <input type="text"
                                             placeholder="Mobile number"
                                              className="form-control"
                                               name="mobileNumber"
                                                value={user.mobileNumber}
                                                min={10}
                                                 onChange={handleInput} />
                                        {/* <div className='CountryNumber '>
                                        <PhoneInput country={"in"} value={user.mobileNumber} name='mobileNumber'
                                        
                                        onChange={(value) => handleCombiedInputs(value, 'mobileNumber')} 

                                        className='CountryNumber'/> </div> */}
                                        {userErrors.mobileNumber && <span className="error-text text-danger">*This field Required*</span>}

                                        <input type="text" placeholder="state" value={user.state}className="form-control" name="state" onChange={handleInput} />
                                        {userErrors.state && <span className="error-text text-danger">*This field Required*</span>}

                                        <input type="number" placeholder="pinCode" name='pinCode' value={user.pinCode} maxLength={6} className="form-control"  onChange={handleInput} />
                                        {userErrors.pinCode && <span className="error-text text-danger">*This field Required*</span>}

                                        <input type="submit" value="Submit" className="btn btn-primary mt-3"  />
                                        </form>

                                    </div>
 

                                     <div className={`register-show ${activePanel === 'signup' ? 'show-log-panel' : ''}`}>
                                        <h2>Signup as a Franchise</h2>
                                        <input type="text" placeholder="Name" className="form-control" name="name" onChange={handleInputs} />
                                        <input type="text" placeholder="Email" className="form-control" name="email" onChange={handleInputs} />
                                        <input type="text" placeholder="Phone" className="form-control" name="mobileNumber" onChange={handleInputs} />
                                        <input type="text" placeholder="Company Name" className="form-control" name="companyName" onChange={handleInputs} />
                                        <input type="number" placeholder="pinCode" maxLength={6} className="form-control" name="pinCode" onChange={handleInputs} />
                                        <input type="button" value="Submit" className="btn btn-primary mt-3" onClick={handleSubmit} />
                                    </div>
 
 
                                    <div className={`register-show ${activePanel === 'register' ? 'show-log-panel' : ''}`}>
                                        <h2>Signup as a Distributor</h2>
                                        <form onSubmit={handleSubmit}>
                                            <input
                                             type="text"
                                              placeholder="Name"
                                               className="form-control"
                                                name="name"
                                                 value={inputs.name}
                                                 onChange={handleInputs}
                                                  maxLength={20}
                                                  />
                                            {errors.name && <span className="error-text text-danger">*This field Required*</span>}
                                            <input
                                            type="email"
                                            placeholder="Email"
                                             className="form-control"
                                              name="email" value={inputs.email} onChange={handleInputs} />
 
                                            {errors.email && <span className="error-text text-danger">*This field Required*</span>}
 
                                            <input type="text"
                                             placeholder="Mobile number"
                                              className="form-control"
                                               name="mobileNumber"
                                                value={inputs.mobileNumber}
                                                required
                                                 onChange={handleInputs} />
 
                                            {errors.mobileNumber && <span className="error-text text-danger">*This field Required*</span>}
 
                                            <input type="text"
                                             placeholder="Company Name"
                                              className="form-control"
                                               name="companyName"
                                               value={inputs.companyName} onChange={handleInputs} />
 
                                            {errors.companyName && <span className="error-text text-danger">*This field Required*</span>}
 
                                            <input type="text"
                                            placeholder="Product Name"
                                             className="form-control"
                                              name="productName"
                                               value={inputs.productName} onChange={handleInputs} />
 
                                            {errors.productName && <span className="error-text text-danger">*This field Required*</span>}
 
                                            <input
                                             type="number"
                                              placeholder="pinCode"
                                               maxLength={6}
                                                className="form-control"
                                                name="pinCode"
                                                value={inputs.pinCode} onChange={handleInputs} />
 
                                            {errors.pinCode && <span className="error-text text-danger">*This field Required*</span>}
 
                                            <input type="submit" value="Submit" className="btn btn-primary mt-3"  />
                       
                                        </form>
                                    </div>
                                </>
                            )}
                            {submitted && (
                                <>
                                    <div className={`signup-show ${activePanel === 'login' ? 'show-log-panel' : ''}`}>
                                        <h2> OTP</h2>
                                        <input type="text"
                                        placeholder="Enter OTP"
                                  className="form-control mt-3"
                                  value={user.otp}
                                  name='otp'
                                  onChange={handleInput}
                                 
                                         />
                                        <input type="button" value="Verify OTP" className="btn btn-primary mt-3" onClick={handleOTPSubmitUser} />
                                    </div>
 
                                    <div className={`signup-show ${activePanel === 'signup' ? 'show-log-panel' : ''}`}>
                                        <h2>Enter OTP</h2>
                                        <input type="text"
                                        placeholder="Enter OTP"
                                  className="form-control mt-3"
                                 
                                         />
                                        <input type="button" value="Verify OTP" className="btn btn-primary mt-3" onClick={handleOTPSubmit} />
                                    </div>
                                    <div className={`register-show ${activePanel === 'register' ? 'show-log-panel' : ''}`}>
                                        <h2>Enter OTP</h2>
                                        <input type="text"
                                         placeholder="Enter OTP"
                                          className="form-control mt-3"
                                          name="otp"
                                          value={inputs.otp}
                                          onChange={handleInputs}
                                           />
                                        <input type="button" value="Verify OTP" className="btn btn-primary mt-3" onClick={handleOTPSubmit}  />
                                 
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default Signup;