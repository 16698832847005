import React from 'react'
import './Cards.css'


const Cards = () => {
  return (
    <div>
      <div className='container blog-main-container'>
        <div className='textet'>
          <h1>Latest Blog Posts</h1>
          <h3>Explore Insider Tips, Expert Guidance, Success Stories, & Industry Insights</h3>
          <div className='view-all'>
            <a href="/" class="btn">View all <i class="bi bi-arrow-right-short"></i></a>
          </div>
        </div>
        <div className='card-container'>
          <div class="card-group">
            <div class="card">

              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2024%2F01%2FA-Comprehensive-Guide-of-Importing-Goods-in-India.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">A Comprehensive Guide of Importing <br /> Goods In India</h5>
                <p class="card-text">India has now become one of the world’s biggest and fastest-growing economies and is now looked upon as a place for imports. The importers in the country import a wide range of goods and services, such as raw materials, machinery, electronic equipment, consumer goods, and so on. Importers refer to companies or individuals who bring […]</p>
                <a href="/" class="btn btn-primary">Read More  <i class="bi bi-chevron-right"></i> </a>

              </div>
            </div>

            <div class="card">
              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2024%2F01%2FBecome-a-franchisee-of-Ventus-Pharmaceuticals.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Become a Franchisee of Ventus <br /> Pharmaceuticals</h5>
                <p class="card-text">VENTUS PHARMACEUTICALS PVT.LTD. is engaged in offering an extensive array of olfemy care products and other pharmaceuticals items. Offered at nominal prices, all our products are highly effective and made with top-quality ingredients and the latest formulation techniques. Incorporated in 2011, VENTUS...</p>
                <a href="/" class="btn btn-primary">Read More <i class="bi bi-chevron-right"></i></a>

              </div>
            </div>
            <div class="card">
              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2024%2F01%2FHow-to-Get-Dealership-of-Spices.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">How to Get Dealership of Spices: Its<br /> Helath Benfits</h5>
                <p class="card-text">India has always been considered as the home of a variety of premium quality and taste-enhancing spices that are also known for their innumerable medicinal properties. Even today, Indian spices are highly preferred and sought after across the globe due to their superb taste, flavor and unique aroma, texture, & color. Countries like the</p>
                <a href="/" class="btn btn-primary">Read More <i class="bi bi-chevron-right"></i></a>

              </div>
            </div>
            <div class="card">

              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2023%2F12%2FFestive-Finances-Christmas-Money-Making-Ideas-and-New-Year-Business-Resolutions.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Festive Finances: Christmas Money<br />Making Ideas and New Year Bussiness..</h5>
                <p class="card-text">In our country, Christmas is celebrated equally enthusiastically by many communities as any other festival, such as Holi, Dussehra, and Diwali. Celebrated on the 25th day of December, this is the day on which Jesus Christ was born. Every year on this day, people around the world decorate their houses and Christmas trees and</p>
                <a href="/" class="btn btn-primary">Read More <i class="bi bi-chevron-right"></i> </a>

              </div>
            </div>

            <div class="card">
              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2023%2F12%2FHow-to-Get-Water-Dispenser.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">How to Get Dispenser:Check <br /> Features Before Buying it</h5>
                <p class="card-text">A water dispenser is a type of machine that is used to cool or sometimes heat up, purify, and dispense water for drinking. It is usually installed in houses, hostels, restaurants, beauty parlors, educational institutes, offices, and allied establishments near restrooms due to closer access to plumbing. It also comes with a drain line that […]</p>
                <a href="/" class="btn btn-primary">Read More <i class="bi bi-chevron-right"></i></a>

              </div>
            </div>

            <div class="card">

              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2023%2F12%2FTake-the-distributorship-of-Kanproof1.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">How to get dealership of Kanproof <br /> Waterproof Coating, Prines & Sealants</h5>
                <p class="card-text">KANGARU POLYMERS PRIVATE LIMITED is engaged in manufacturing and supplying – Kanproof Acrylic C Based Highly Elastomeric Waterproof Coating, Kanprime Acrylic Primer, Kanstrech HB Intermediate High Build Coating, and allied products. Made using optimum quality ingredients, our products easily blend and are appropriate</p>
                <a href="/" class="btn btn-primary">Read More <i class="bi bi-chevron-right"></i></a>

              </div>
            </div>

            <div class="card">

              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2023%2F12%2FHow-to-get-distributorship-of-Alo-Coco-Juices.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">How to get distributorship of Alo Coco<br />Juices</h5>
                <p class="card-text">MAA CHEHAR FOODS AND BEVERAGES provides delicious Alo Coco Juices in Peach, Litchi, Guava, and Pineapple flavors. These juices are affordable, taste great, and have a balanced and appealing flavor. Made with high-quality ingredients, these are carefully processed and tested to make sure they are safe and pure to drink. They come in bottles...</p>
                <a href="/" class="btn btn-primary">Read More <i class="bi bi-chevron-right"></i></a>

              </div>
            </div>



            <div class="card">

              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2023%2F12%2FExplore-Indias-Essential-Imports-from-China.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Explore India's Essential Importants from <br />China</h5>
                <p class="card-text">Explore the intricate web of India’s essential imports from China, a dynamic economic partnership shaping global trade. This symbiotic relationship manifests in a diverse array of crucial imports, spanning electronics, machinery, chemicals, and textiles. From cutting-edge technology components to everyday consumer goods,</p>
                <a href="/" class="btn btn-primary">Read More<i class="bi bi-chevron-right"></i></a>

              </div>
            </div>

            <div class="card">

              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2023%2F12%2FAmazing-Health-Benefits-of-Indian-Pickles-Achar.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Amazing Health Benefits of Indians Pickels <br />(Achar)</h5>
                <p class="card-text">Almost all of us have grown up eating authentic and mouth-watering pickles made by our grandmothers and mothers. In India, pickles are the spice of life, and no meal is complete without them. In most Indian households, big glass jars filled with freshly made pickles can be seen. Achar or pickles have been an intrinsic […]</p>
                <a href="/" class="btn btn-primary">Read More <i class="bi bi-chevron-right"></i></a>

              </div>
            </div>

            <div class="card">
              <img src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Fblog.getdistributors.com%2Fwp-content%2Fuploads%2F2023%2F12%2FQuenching-Thirst-Navigating-the-Indian-Beverage-Industry.jpg&w=384&q=75" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Quenching Thirst:Navigating Indian <br /> Beverage Industry</h5>
                <p class="card-text">The Indian beverage industry is a fast-flourishing sector. Our country accounts for almost 10 percent of beverage consumption worldwide. This makes India the third-largest consumer of beverages in the world after the United States and China. The beverages sector contributes 8-9 percent to the total FG (Food and Grocery) market in</p>
                <a href="/" class="btn btn-primary">Read More <i class="bi bi-chevron-right"></i></a>

              </div>
            </div>




          </div>
        </div>
      </div>
    </div>
  )
}

export default Cards