import React from "react";
import "./FoodBeverage.css";

const FoodBeverage = () => {
  
  return (
    <div className="container_fluid">
      <div className="wrapper row">
        <div className="left_container">
          <div className="location">
            <div className="title"> Location</div>
            <div className="search_box">
              <i class="bi bi-geo-alt-fill"></i>
              <input type="text" placeholder="Search City" />
            </div>
            <div className="city">
              <ul>
                <li>
                  <a href="">Munbai</a>
                </li>
                <li>
                  <a href="">Pune</a>
                </li>
                <li>
                  <a href="">Bengaluru</a>
                </li>
                <li>
                  <a href="">New Delhi</a>
                </li>
                <li>
                  <a href="">Chennai</a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="investment_range">
              <div className="investment_price">
                <h5>Investment Range</h5>
                <li>Rs. 5 Cr. and above</li>
                <li>Rs. 2 Cr. - Rs. 5 Cr.</li>
                <li>Rs. 1 Cr. - Rs. 2 Cr.</li>
                <li>Rs. 50 Lac. - Rs. 99 Lac.</li>
                <li>Rs. 30 Lac. - Rs. 50 Lac.</li>
                <li>Rs. 20 Lac. - Rs. 30 Lac.</li>
                <li>Rs. 10 Lac. - Rs. 20 Lac.</li>
                <li>Rs. 5 Lac. - Rs. 10 Lac.</li>
                <li>Rs. 2 Lac. - Rs. 5 Lac.</li>
                <li>Rs. 1 Lac. - Rs. 2 Lac.</li>
                <li>Rs. 50 K. - Rs. 99 K.</li>
              </div>
            </div>
          </div>
          <div className="Search_type">
            <h5>Search Type</h5>
            <div class="form-check">
              <input
                class="form-check-input black-radio"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
                checked
              />
              <label class="form-check-label" for="exampleRadios1">
                Brands
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input black-radio"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="option2"
              />
              <label class="form-check-label" for="exampleRadios2">
                Distributors
              </label>
            </div>
          </div>
        </div>

        <div className="right_container">
          <div className="distributor">
            <span>
              Distributors <i class="bi bi-chevron-right"></i>
            </span>
            <p>Food Beverage</p>
          </div>
          <div className="distributor_title">
            <h3>
              Food & Beverage Distributors <span>(8334 Results)</span>
            </h3>
            <p>
              People across the globe love to eat and drink making the food and
              drinks sector grow day by day. This has created a lot of
              opportunities in the distribution of food products in India.
              Become a distributor for food items, ready-to-eat snacks, fmcg
              products, organic food, juices, soft drinks and many more. You can
              also deal in organic food products, organic food, natural foods,
              breakfast food, breakfast food items, food ingredients, grocery,
              staples, chocolate, potato chips, sugar, tea, edible oil, candy,
              jams, turmeric powder, noodles, basmati rice, coriander powder,
              edible salt, soybean oil, red chilli powder, mustard oil, toffee,
              junk food and earn attractive returns on your investments. Open up
              your earning avenues by choosing from our comprehensive list of
              companies serving the market.
            </p>
          </div>

          <div className="food_container">
            <div className="food_varieties">
              <div className="food_items">
                <div className="image">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fcategoryimg%2Fv1%2F1%2FFresh-Fruits-16.jpg&w=48&q=75"
                    alt=""
                  />
                  <p>Fruit</p>
                </div>
              </div>
              <div className="food_items">
                <div className="image">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fcategoryimg%2Fv1%2F1%2FGrain-17.jpg&w=48&q=75"
                    alt=""
                  />
                  <p>Grain</p>
                </div>
              </div>
              <div className="food_items">
                <div className="image">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fcategoryimg%2Fv1%2F1%2FMushroom-Truffle-19.jpg&w=48&q=75"
                    alt=""
                  />
                  <p>Mushroom & Truffle</p>
                </div>
              </div>
              <div className="food_items">
                <div className="image">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fcategoryimg%2Fv1%2F1%2FNuts-Kernels-20.jpg&w=48&q=75"
                    alt=""
                  />
                  <p>Nuts & Kernels</p>
                </div>
              </div>
              <div className="food_items">
                <div className="image">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fcategoryimg%2Fv1%2F1%2FPlant-Animal-Oil-23.jpg&w=48&q=75"
                    alt=""
                  />
                  <p>Plant & Animal Oil</p>
                </div>
              </div>
              <div className="food_items">
                <div className="image">
                  {" "}
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fcategoryimg%2Fv1%2F1%2FTea-27.jpg&w=48&q=75"
                    alt=""
                  />
                  <p>Tea</p>
                </div>
              </div>
              <div className="food_items">
                <div className="image">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fcategoryimg%2Fv1%2F1%2FDried-Vegetables-28.jpg&w=48&q=75"
                    alt=""
                  />
                  <p>Dried Vegetables</p>
                </div>
              </div>
              <div className="food_items">
                <div className="image">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fcategoryimg%2Fv1%2F1%2FFlavours-Food-Additives-197.jpg&w=48&q=75"
                    alt=""
                  />
                  <p>Flavours & Additives</p>
                </div>
              </div>
            </div>

            <div className="load_more">
              <button>Load More</button>
            </div>
          </div>

          <div className="distributors_container">
            <div className="d_wrapper">
              <div className="d_image p-4">
                <img
                  src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FRangpur-Tea-Association-Ltd-v3-164797.jpeg&w=256&q=75"
                  alt=""
                />
              </div>

              <div className="vertical_line"></div>

              <div className="d_details">
                <div className="brand_details">
                  <div className="food_btn">Food & Beverage</div>
                  <div className="logo ">
                    <img
                      src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Fpremium-brand-1.png&w=96&q=75"
                      alt=""
                    />
                    <img
                      src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Flisting-trusted.png&w=96&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <h5>Rangpur Tea Association Ltd</h5>
                <div className="details ">
                  <div>
                    <span>Distributors of :</span> Dabri Tea, CTC Tea, Flavoured
                    Tea, Tea, Dabri Green Tea, Dabri Tea Red Packet, Dabri
                  </div>
                  <div>
                    <span>Investment price :</span>5.00 Lac - 10.00 Lac
                  </div>
                  <div>
                    <span>Founded In :</span> 1917
                  </div>
                </div>
                <div className="contact_details ">
                  <div className="mobile d-flex gap-2">
                    <i class="bi bi-telephone"></i>
                    <p>08045478109</p>
                  </div>
                  <div className="send_enquiry">
                    <i class="bi bi-telegram"> </i>
                    <span>Send Inquiry</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="distributors_container">
            <div className="d_wrapper">
              <div className="d_image ">
                <img
                  src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FHERBOVEDA-INDIA-v3-39671.jpeg&w=256&q=75"
                  alt=""
                />
              </div>

              <div className="vertical_line"></div>

              <div className="d_details">
                <div className="brand_details">
                  <div className="food_btn">Food & Beverage</div>
                  <div className="logo">
                    <img
                      src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Fpremium-brand-1.png&w=96&q=75"
                      alt=""
                    />
                    <img
                      src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Fpremium-brand-1.png&w=96&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <h5>Herboveda India</h5>
                <div className="details ">
                  <div>
                    <span>Distributors of :</span> Natural Sweeteners, Stevia,
                    Stevia Leaf, Stevia Liquid, Erythritol Powder
                  </div>
                  <div>
                    <span>Investment price :</span>
                    1.00 Lac - 2.00 Lac
                  </div>
                  <div>
                    <span>Founded In :</span>
                    2010
                  </div>
                </div>
                <div className="contact_details ">
                  <div className="mobile d-flex gap-2">
                    <i class="bi bi-telephone"></i>
                    <p>08037301911</p>
                  </div>
                  <div className="send_enquiry">
                    <i class="bi bi-telegram"> </i>
                    <span>Send Inquiry</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="distributors_container">
            <div className="d_wrapper">
              <div className="d_image">
                <img
                  src="https://www.getdistributors.com/_next/image/?url=https%3A%2F%2Ftiimg.tistatic.com%2Fgd%2Fco_logos%2FU-D-Food-Products-Pvt-Ltd--v2-101252.jpeg&w=256&q=75"
                  alt=""
                />
              </div>{" "}
              <div className="vertical_line"></div>
              <div className="d_details">
                <div className="brand_details">
                  <div className="food_btn">Food & Beverage</div>
                  <div className="logo">
                    <img
                      src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Fpremium-brand-1.png&w=96&q=75"
                      alt=""
                    />
                    <img
                      src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fbadges%2Flisting-trusted.png&w=96&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <h5>U.D.Food Products Pvt. Ltd</h5>
                <div className="details ">
                  <div>
                    <span>Distributors of :</span> Puffs, Crunchy & Tasty Corn
                    Sticks, Tasty & Crunchy Potato Chips, Crispy & Tasty Rings
                  </div>
                  <div>
                    <span>Investment price :</span>2.00 Lac - 5.00 Lac
                  </div>
                  <div>
                    <span>Founded In :</span> 1995
                  </div>
                </div>
                <div className="contact_details ">
                  <div className="mobile d-flex gap-2">
                    <i class="bi bi-telephone"></i>
                    <p>08037301911</p>
                  </div>
                  <div className="send_enquiry">
                    <i class="bi bi-telegram"> </i>
                    <span>Send Inquiry</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodBeverage;
