import React from 'react'
import { PiStarFourThin } from "react-icons/pi";
import './BanerScrol.css'


const BanerScrol = () => {
    
    const Banners=[
        {
            id1:1,
            name:'Flat 20% off on all New Special Products',

        },
        {
            id1:2,
            name:'Flat 20% off on all New Special Products',

        }, {
            id1:3,
            name:'Flat 20% off on all New Special Products',

        },
        {
            id1:4,
            name:'Flat 20% off on all New Special Products',

        },
        {
            id1:5,
            name:'Flat 20% off on all New Special Products',

        }

    ]
    const Bannersall= [ ...Banners,...Banners,...Banners]
  return (
    <div>

<div className='banner_ongoing'>
        <div className='baner_ongoingall'>
        {Bannersall.map((banner,index)=>(

<div className='banner_head' key={index}>
<p><span><PiStarFourThin /></span>{banner.name}</p>

    
</div>
        )) }
        </div>
     
      </div>
    </div>
  )
}

export default BanerScrol
