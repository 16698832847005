import React from 'react'
import './Web.css'
import Slider from "react-slick";

const Web = () => {
  return (
    <div>
    <div className='container web-main-container'>
      <div className='title'>
        <h1>Top Bunsiness Stories</h1>
      </div>

      <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col">
                <a href=" https://www.getdistributors.com/articles/web-stories/6-reasons-why-the-air-fryer-deserves-a-spot-in-the-kitchen/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-6-Reasons-Why-the-Air-Fryer-Deserves-a-Spot-in-the-Kitchen.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>6 Reason why the air <br /> Disrive the sopt in there</h5>
                <h6>By GetDistributors</h6>
              </div>


              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/elevate-your-lifestyle-with-hygiene-excellence/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Elevate-Your-Lifestyle-with-Hygiene-Excellence.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>Elevate YOur Life <br /> with Hygience <br />Excellence</h5>
                <h6>By:Getdistributors</h6>
              </div>




              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/reasons-why-indians-are-obsessed-with-coriander/" target="_blank"  rel="noreferrer">

                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Reasons-Why-Indians-Are-Obsessed-with-Coriander.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>Reasons Why Indians <br />Are Obsesses with <br /> Coriander</h5>
                <h6>By:Getdistributors</h6>
              </div>


              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/6-heart-protective-powers-of-garlic/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-6-Heart-Protective-Powers-of-Garlic.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>6 Heart-Protective <br />Powers of Garlic</h5>
                <h6>By:Getdistributors</h6>
              </div>


              <div class="col">
                <a href=" https://www.getdistributors.com/articles/web-stories/do-you-know-how-to-get-ayodhya-ram-mandir-prasad-for-free/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Do-You-Know-How-to-Get-Ayodhya-Ram-Mandir-Prasad-for-Free.jpg" class="d-block w-100" alt="..." />
                </a>
                <h6>Do you known</h6>
                <h5> "How to Get Ayodhya <br /> Ram Mandir Prasaf <br /> for Free </h5>
                <h6>By:Getdistributors</h6>
              </div>

              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/why-dark-chocolates-steal-the-show/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Why-Dark-Chocolates-Steal-the-Show.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>Why Drak <br />Chocolates Steal the <br />Show</h5>
                <h6>By:Getdistributors</h6>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/the-health-benefits-of-custard-apples/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-The-Health-Benefits-of-Custard-Apples.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>The Health Benfits <br />of Custard Apples</h5>
                <h6>By:Getdistributors</h6>

              </div>
              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/top-8-hot-beverages-to-keep-you-warm-during-winter-season/"target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Top-8-Hot-Beverages-to-Keep-You-Warm-During-Winter-Season.jpg" class="d-block w-100" alt="..." />
                </a>
                <h6>By:Getdistributors</h6>
              </div>

              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/explore-benefits-of-mustard-oil-for-hair/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Explore-Benefits-of-Mustard-Oil.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>Explore Benfits of <br /> Mustard Oil For Hair</h5>
                <h6>By:Getdistributors</h6>
              </div>


              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/top-10-automobile-parts-and-products-for-distributorship-business/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Top-10-Automobile-Parts-and-Products-for-Distributorship-Business.jpg" class="d-block w-100" alt="..." />
                </a>
                <h6>By:Getdistributors</h6>
              </div>


              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/mushrooms-and-their-countless-health-boosts/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Mushrooms-and-Their-Countless-Health-Boosts.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>Mushrooms <br /> and Their <br /> Countless <br />Health Boosts</h5>
                <h6>By:Getdistributors</h6>
              </div>


              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/7-things-to-enjoy-in-winter/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-7-Things-to-Enjoy-in-Winter.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>7 Things to Enjoy in <br /> Winter</h5>
                <h6>By:Getdistributors</h6>
              </div>
            </div>
          </div>

          <div class="carousel-item">
            <div class="row">
              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/explore-the-world-of-smart-furniture/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Explore-the-World-of-Smart-Furniture.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>Explore the World <br /> of Smart Furniture</h5>
                <h6>By:Getdistributors</h6>
              </div>
              <div class="col col_">
                <a href="https://www.getdistributors.com/articles/web-stories/6-amazing-benefits-of-eating-rice/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-6-Benefits-of-Eating-Rice.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>6 Amazing <br /> Benefits of Eating <br /> Rice</h5>
                <h6>By:Getdistributors</h6> </div>


              <div class="col">
                <a href="https://www.getdistributors.com/articles/web-stories/7-different-types-of-clocks-for-home/" target="_blank"  rel="noreferrer">
                  <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Reasons-Why-Indians-Are-Obsessed-with-Coriander.jpg" class="d-block w-100" alt="..." />
                </a>
                <h5>7 Diffrent <br /> Types of <br /> Clocks For <br /> Home</h5>
                <h6>By:Getdistributors</h6>
              </div>
              <div class="col">
                <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-Elevate-Your-Lifestyle-with-Hygiene-Excellence.jpg" class="d-block w-100" alt="..." />
              </div>
              <div class="col">
                <img src="https://www.getdistributors.com/wp-content/uploads/2024/01/cropped-6-Reasons-Why-the-Air-Fryer-Deserves-a-Spot-in-the-Kitchen.jpg" class="d-block w-100" alt="..." />
              </div>
            </div>
          </div>
        </div>

        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  <span class="visually-hidden">Previous</span>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
  <span class="carousel-control-next-icon" aria-hidden="true"></span>
  <span class="visually-hidden">Next</span>
</button>

      </div>

    </div>
  </div>
  )
}

export default Web