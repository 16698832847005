import React, { useEffect, useState } from 'react'
import './Paragraph.css'
import { useLocation } from 'react-router-dom'
import { getSingleBrandDetails } from '../../../api/showbrand ';


const Paragraph = () => {
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");

    const [brand, setBrand]=useState([]);

    useEffect(()=>{
        getDetails();


    },[id]);

    const getDetails = () =>{
        if(!id) return;

        getSingleBrandDetails(id)
        .then((res)=>{
            setBrand(res.data.result);

        })
        .catch((err)=>{
            console.log(err);
        });
    }
    return (
        <div>
            {brand.map((data)=>(
            <div className='container paragraph_container' key={data.id}>
                
<div className='row mt-5'>
  <div className='col-lg-9 '>
    <div className='about_information inform_all'>
    <div className='brand_about fw-bold'>ABOUT BRAND - <span className='text-bold '>GANESH BHEL AND CHAAT</span></div>
    <div className='brand_about fw-bold'>ABOUT BRAND - <span>{data.companyName}</span></div>
    <br/>
    <p>{data.describeYourBusiness}</p>
  
 <h6>Our Founders</h6>
 <p>{data.ownerName}, <span> Ramesh Gudmewar </span>coined the term Pani Puri nearly four decades ago and started a revolution in the desi street food culture. Begun with a humble hand cart he was dedicated to crafting mouthwatering delights day and night.</p>
 <h6>FEATURED PRODUCT:-</h6>
 <div className="Featured_produts">
  <div className='Featured_cards_all'>
    <p>DRY BHEL</p>
  </div>
  <div className='Featured_cards_all'>
    <p>FLAVORED PANIPURI</p>
  </div>
  <div className='Featured_cards_all'>
    <p>MUMBAI SHEVPURI</p>
  </div>
 </div>

<h6 className='mt-3'>OUR CATERING SERVICES:-</h6>
<div className="Featured_produts ">

<div className='Featured_cards_all'>
    <p>BIRTHDAY CATERING </p>
  </div>
  <div className='Featured_cards_all'>
    <p>WEDDING</p>
  </div>
  <div className='Featured_cards_all'>
    <p>CORPORATE GET-TOGETHER</p>
  </div>
  </div>



 
  </div>
  <div className='Frachise_cost mt-5'>
  <p className='brand_about fw-bold'> <span>GANESH BHEL AND CHAAT</span>FRANCHISE COST & INVESTMENT</p>
  <div className='commenced_operations'>
  <h5 className='fw-bold'>Commenced Operations</h5>
  <div className='operration_detials'>
   <div>
    <p>Operations Commenced On</p>
    <p>Franchise Commenced On</p>
   </div>
   <div>
    <p>1978</p>
    <p>2011</p>
   </div>

  </div>
  <h5>Franchise Details</h5>
  <div className='Units_detials mt-3 '>
  <h5 className='fw-bold'>UNITS</h5>

    <div className='unit_describe'>

    <div className='invest_fee' >
      <p>Investment</p>
      <p>Franchise/Brand Fee</p>
      <p>Royalty/Commission</p>
    </div>
    <div>
      <p>INR 20lakh - 30lakh</p>
      <p>INR 500000</p>
      <p>5 %</p>
    </div>
    </div>
    <h5 className='mt-5 fw-bold'>MASTER / MULTI UNITS</h5>
    {/* <p className='brand_city'>City Wise</p> */}
    <div className='unit_describe'>
   
    <div className='invest_fee'>
      <p>Investment</p>
      <p>Unit/Brand Fee</p>
      <p>Royalty/Commission</p>
    </div>
    <div>
      <p>INR 20lakh - 30lakh</p>
      <p>INR 500000</p>
      <p>5 %</p>
    </div>
    </div>

  </div>
  <h5 className='mt-5 fw-bold'>Details</h5>
  <div className='operration_detials'>


<div>
 <p>Anticipated percentage return on investment</p>
 <p>Likely pay back period of capital for a Unit Franchise</p>
</div>
<div>
 <p>28 % </p>
 <p>1-2 Years</p>
</div>

</div>
  </div>  
  <h4 className='Plans mt-5 fw-bold'>GANESH BHEL AND CHAAT EXPANSION PLANS</h4>

  <div className="Featured_produts mt-5">
  <div className='Featured_cards_all prodcut_plans '>
    <h5>NORTH</h5>
    <p className='text-white'>-NA-</p>
    
  </div>
  <div className='Featured_cards_all prodcut_plans'>
  <h5>SOUTH</h5>
  <p className='text-white'>-NA-</p>  </div>
  <div className='Featured_cards_all prodcut_plans'>
  <h5>EAST</h5>
  <p className='text-white'>-NA-</p>  
  </div>
  <div className='Featured_cards_all prodcut_plans'>
  <h5>EAST</h5>
  <p className='text-white'>-NA-</p>  
  </div>
  <div className='Featured_cards_all prodcut_plans'>
  <h5>EAST</h5>
  <p className='text-white'>-NA-</p>  
  </div>
   <div className='Featured_cards_all prodcut_plans'>
  <h5>EAST</h5>
  <p className='text-white'>-NA-</p>  
  </div>
 </div>
<div className='all_product_border mt-5 '>
 <h5 className='brand_about px-3 py-3 fw-bold'>GANESH BHEL AND CHAAT FRANCHISE REQUIREMENTS </h5>
  <div className='operration_detials'>
   <div>
    <p>Operations Commenced On</p>
    <p>Franchise Commenced On</p>
   </div>
   <div>
    <p>1978</p>
    <p>2011</p>
   </div>

  </div>
  </div>
  <div className='all_product_border mt-5 '>
 <h5 className='brand_about px-3 py-3 fw-bold'>GANESH BHEL AND CHAAT FRANCHISE TRAINING </h5>
  <div className='operration_detials'>
   <div>
    <p>Detailed operating manuals for franchisees</p>
    <p>Franchisee training location</p>

    <p>Is field assistance available for franchisee ?</p>
    <p>Expert guidance from Head Office to franchisee in opening the franchise</p>

   </div>
   <div className='invest_feess'>
    <p>Yes</p>
    <p>Pune</p>
    <p>Yes</p>
    <p>Yes</p>


   </div>

  </div>
  </div>
  <div className='all_product_border mt-5 '>
 <h5 className='brand_about px-3 py-3 fw-bold'>GANESH BHEL AND CHAAT FRANCHISE AGREEMENT DETAILS </h5>
  <div className='operration_detials'>
   <div>
    <p>Do you have a standard franchise agreement?</p>
    <p>How long is the franchise term for?</p>
    <p>Is the term renewable?</p>

   </div>
   <div>
    <p>Yes</p>
    <p >5 years</p>
    <p>yes</p>

   </div>

  </div>
  </div>
 
  </div>
  </div>
  
</div>
               
            </div>
            ))}
        </div>
    )
}

export default Paragraph