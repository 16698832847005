import API from "./api";
import { Profile } from "./endpoints";


export const getsingleProfile = (data) => {
    return API.get(`${Profile}/singleDistributor?_id=${data}`);
};

export const getProfileDetails = (data)=> {
    return API.get(`${Profile}`, { params: { _id: data } });
}

export const updateProfile = (data) => {
    return API.put(`${Profile}`, data);
}

 