import React from 'react';
import './MemberShip.css'; // Import CSS file for styling
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';

const MemberShip = () => {
  return (
    <div>
      <Header/>
      <table className="membership-table"> {/* Add class name for styling */}
        <thead>
          <tr  className='colu' style={{height: '8rem'}}>
            <th></th> 
            <th><img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Ficons%2Fgd-pro.png&w=96&q=75"/></th>
            <th><img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Ficons%2Fgd-business.png&w=96&q=75"  /></th>
            <th><img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Ficons%2Fgd-premium.png&w=96&q=75"  /></th>
          </tr>
        </thead>
        <tbody>
          <tr className='ship'>
            <td><h5>GD – Branded Landing Page for Brand</h5></td>
            <td><h4><i class="bi bi-x-circle"></i></h4></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
          </tr>
          <tr className='ship'>
            <td><h5>Profile Page on GD Portal</h5></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
          </tr>
          <tr className='ship'>
            <td><h5>rospect Enquiries – for your Product Category</h5></td>
            <td><h5>50</h5></td>
            <td><h5>80</h5></td>
            <td><h5>150</h5></td>
          </tr>
          <tr className='ship'>
            <td><h5>Distributors Enquiries Access (On 1 Category )</h5></td>
            <td><h5>5 Weekly</h5></td>
            <td><h5>15 Weekly</h5></td>
            <td><h5>25 Weekly</h5></td>
          </tr>
          <tr className='ship'>
            <td><h5>Direct enquiries from distributors through Landing Page & Profile Page</h5></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
          </tr>
          <tr className='ship'>
            <td><h5>Direct calls from distributors through Masking number</h5></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
          </tr>
          <tr className='ship'>
            <td><h5>Dedicated GD CRM A/c Manager</h5></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
          </tr>
          <tr className='ship'>
            <td><h5>Dedicated EC A/c Manager</h5></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
            <td><h3><i class="bi bi-check2-circle"></i></h3></td>
          </tr>
          <tr className='ship'>
            <td><h5>Free SMO</h5></td>
            <td><h5>1 Time</h5></td>
            <td><h5>2 Time</h5></td>
            <td><h5>3 Time</h5></td>
          </tr>
          <tr className='ship'>
            <td><h5>Duration</h5></td>
            <td><h5>6 Month</h5></td>
            <td><h5>1 Year</h5></td>
            <td><h5>1 Year</h5></td>
          </tr>
          <tr className='contactfrom'  style={{height: '8rem'}}> {/* Inline CSS to increase height */}

            <td></td>
            <td><h5>Contact sales <i class="bi bi-arrow-right"></i></h5></td>
            <td><h5>Contact sales <i class="bi bi-arrow-right"></i></h5></td>
            <td><h5>Contact sales <i class="bi bi-arrow-right"></i></h5></td>
          </tr>
        </tbody>
      </table>
      <Footer/>
    </div>
  );
};

export default MemberShip;
