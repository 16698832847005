import React, { useEffect } from "react";
import "./Hero.css";
import { useState } from 'react';
import { getBrandDetails } from "../../../api/showbrand ";
import { distributorId } from "../../../Utils/Storage";
import {Link} from "react-router-dom";
import sales1 from '../../../Asssets/Adds/sales.jpg'
 
 
 
 
const Hero = () => {
  const [brands, setBrands] = useState([]);
 
  const [activeTab, setActiveTab] = useState('distributors');
 
  const changeTab = (tabId) => {
    setActiveTab(tabId);
  };
 
  useEffect(() => {
    getDetails();
  }, []);
 
  const getDetails = () =>{
    const id =distributorId();
    getBrandDetails(id)
    .then((res) =>{
      setBrands(res.data.result);
 
    })
    .catch((err)=>{
      console.log(err);
    });
  };
 
 
  return (
    <div className="hero_wrapper">
      <div className="col-lg-12 col-md-6 hero_container">
        <div className="title">
          <h2>
          The Launchpad for Your Business Expansion <br />
           and Entrepreneurial Success!
          </h2>
        </div>
        <div className="main">
          <div className=" main_content">
          <div className="h_left">
            <div className="left_top">
            <div className="avatars">
  {brands && brands.length > 0 &&
    brands.slice(0, 5).map((brand) => (
      <span key={brand._id} className="avatar">
        <img
          src={brand.imageUrl}
          alt=""
        />
      </span>
    ))}
</div>
              <div className="left_top_text">
              <h4>Exclusive Brands</h4>
              <p>
                Top Industry Players Trust GetDistributors  <br />
                for their Business Success!
              </p>
 
              </div>
             <Link to ="/exclusive"> <div className="view">
                VIEW ALL              
                </div></Link>
              <div className="left_bottom">
                <img
                  src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fdummy%2Fsub-head%2Fpost-your-requirement.png&w=128&q=75"
                  alt=""
                />
                <h4>
                <span>
                  Supercharge Your <br />
                 
                </span>
                <span>Business Growth:</span>
                <span className="unlock_opputnities">Unlock <br /> </span>
                <span  className="unlock_opputnities">Limitless Opportunities!</span>
                </h4>
                <button className="post_requirements">Post Your Requirements</button>
              </div>
            </div>
          </div>
 
          <div className="h_center row">
            <img
              src={sales1}
              alt=""
            />
            <div className="categories ">
           
            <div className="c_text d-flex gap-2 align-items-center">
            <i class="bi bi-list "></i>
           
              <h6>ALL CATEGORIES</h6>
            </div>
           <i class="bi bi-chevron-up"></i>
 
            </div>
            <p>Total <span>1.2Lakhs</span> Distributors</p>
           
          </div>
 
          <div className="h_rigth">
          <div className="right_top">
     <span>
     <h3>What are you looking for?</h3>
      <p>Explore Opportunities!</p>
     </span>
      <div className="row">
        <div className="col-xs12">
          <div id="tab" className="btn-group  row" data-toggle="buttons">
            <button
              onClick={() => changeTab('distributors')}
              className={`btn rounded-pill ${activeTab === 'distributors' ? 'active' : ''}`}
            >
              <i class="bi bi-check-circle"></i>
              <span>Distributors</span>
            </button>
            <button
              onClick={() => changeTab('franchise')}
              className={`btn rounded-pill ${activeTab === 'franchise' ? 'active' : ''}`}
            >
             <i class="bi bi-check-circle"></i>
             <span>Franchise</span>
            </button>
          </div>
 
          <div className="tab-content">
            <div className={`tab-pane ${activeTab === 'distributors' ? 'active' : ''}`} id="distributors">
              Looking for Distributors
            </div>
            <div className={`tab-pane ${activeTab === 'franchise' ? 'active' : ''}`} id="franchise">
              Looking for Franchise
            </div>
          </div>
        </div>
      </div>
    </div>
 
 
 
 
 
          <div className="right_bottom p-3 py-3">
          <div class="avatars">
                <span class="avatar">
                  <img
                    src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fdummy%2Fsub-head%2Fdistributor-1.png&w=48&q=75"
                    alt=""
                  />
                </span>
                <span class="avatar">
                  <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fdummy%2Fsub-head%2Fdistributor-2.png&w=48&q=75" />
                </span>
                <span class="avatar">
                  <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fdummy%2Fsub-head%2Fdistributor-3.png&w=48&q=75" />
                </span>
                <span class="avatar">
                  <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fdummy%2Fsub-head%2Fdistributor-4.png&w=48&q=75" />
                </span>
                <span class="avatar">
                  <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fdummy%2Fsub-head%2Fdistributor-5.png&w=48&q=75"/>
                </span>
                <span class="avatar">
                  <img src="https://www.getdistributors.com/_next/image/?url=%2Fimages%2Fdummy%2Fsub-head%2Fdistributor-6.png&w=48&q=75" />
                </span>
              </div>
              <div className="right_text">
                <h4>Recently Added Distributorships</h4>
                <p>Discover new avenues and grab exciting <br />
                opportunities.</p>
              </div>
              <div className="view">
                <h5>VIEW ALL</h5>
              </div>
          </div>
 
           
              </div>
        </div>
        </div>
      </div>
    </div>
  );
};
 
export default Hero;