import React, { useEffect, useState } from 'react';
import './Top.css';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { getCategory } from '../../../api/category';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "gray",borderRadius:'10px',marginRight:'30px', }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block",background: "gray",borderRadius:'10px',marginLeft:'20px',}}
      onClick={onClick}
    />
  );
}

const Categories = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    arrows: true,
    slidesToShow: 10, // Default to 8 for XXL screens
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,   
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 1600, // XXL screens
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200, // XL screens
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Laptop screens
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Mobile screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    getCategory()
      .then((res) => {
        setCategories(res.data.result);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="TrendingAll">
      <div className="all_detials_top">
        <div className="heading1">
          <h2>Top Trending Categories</h2>
        </div>
        <div className="Categories">
          <Slider {...settings}>
            {categories.map((category, index) => (
              <div className="TrendingBox" key={index}>
                <div className="CircleImage">
                  <img src={category.categoryImage} alt={category.name} />
                </div>
                <div className="CategoriesText">
                  <Link to={`/categoryDetails/?category=${category.categoryName}`}>
                    <p>{category.categoryName}</p>
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Categories;
