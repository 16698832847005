
import CryptoJS from "crypto-js";
 
 
export const saveToken =  (data) => {
  localStorage.setItem("token", data?.token);
  localStorage.setItem('loginType', data?.loginType);
   if(data?.userId)
    {
      localStorage.setItem('UserId', data?.userId );
    }
   else if (data?.distributorId)
    {
      localStorage.setItem('distributorId', data?.distributorId );
    }
  console.log('Stored distributorId:', data?.distributorId);
};
 
export const getToken = () => {
  return localStorage.getItem("token");
};
export const loginType = () => {
  return localStorage.getItem("loginType");
};
 export const userId=()=>{
    return localStorage.getItem('UserId')
 }
export const distributorId = () => {
return localStorage.getItem('distributorId');             
};
    export const getemailId = () => {
  const emailId = localStorage.getItem('emailId');
  console.log('Encrypted emailId from localStorage:', emailId);
 
  if (emailId) {
    try {
      const decryptedEmailId = CryptoJS.AES.decrypt(emailId, 'pixclickForms').toString(CryptoJS.enc.Utf8);
      console.log('Decrypted emailId:', decryptedEmailId);
      return decryptedEmailId;
    } catch (error) {
      console.error('Error decrypting emailId:', error);
      return null; // or handle the error accordingly
    }
  } else {
    console.log('No emailId found in localStorage.');
    return null; // or any default value if emailId is not found
  }
};
 
 
 
export const clearStorage = () => {
   localStorage.clear()
};
 